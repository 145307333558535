import { Dialog, DialogContent } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';




function IdleHandler() {
    
    const navigate = useNavigate();
    const [warningTime, setWarningTime] = useState(60000);
    const [signoutTime, setSignoutTime] = useState(70000);
    let warnTimeout;
    let logoutTimeout;

    const warn = () => {
        console.log('Show Warning');
        setDialogStatus(true);
    };
    const logout = () => {
        console.log('You have been loged out');
        navigate("/kiosk/dashboard");
    }

    const destroy = () => {
        console.log('Session destroyed');
    }

    const setTimeouts = () => {
        warnTimeout = setTimeout(warn, warningTime);
        logoutTimeout = setTimeout(logout, signoutTime);
    };

    const clearTimeouts = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const resetTimeout = () => {
        clearTimeouts();
        setTimeouts();
    };

    useEffect(() => {
        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for(let i in events){
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        }
    },[]);


    // Dialog Work 
    const [dialogStatus,setDialogStatus] = useState(false)
    const handleClose = () =>{
        setDialogStatus(false)
    }

    return (
        <>
            <Dialog
                open={dialogStatus}
                onClose={handleClose}
            >
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3>Still Watching ? </h3>
                        </div>
                        <div className='col-md-12'>
                            <button className='btn btn-primary' onClick={handleClose} >Yes</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default IdleHandler