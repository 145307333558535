import { Help, Info, NoteAlt, Receipt, Signpost } from '@mui/icons-material'
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import KioskHeader from '../../sections/KioskHeader'
import kioskInfoServices from '../../infoKiosk/kioskInfoServices';
import useBusinessInfo from '../../../../hooks/useBusinessInfo';
import { useKioskConfig } from '../../../../context/KioskConfigContext';

function KioskDashboarPage() {
    const [businessInfo] = useKioskConfig();

    const [moduleCount,setModuleCount] = useState(0);
    const [kioskCaptionList,setKioskCaptionList] = useState(null);
    const getModuleCount = () =>{
        let count = 0;
        businessInfo?.kioskTokenModule == 1 &&  count++;
        businessInfo?.kioskInfoModule == 1 &&  count++;
        businessInfo?.kioskDirectoryModule == 1 &&  count++;
        businessInfo?.kioskFaqModule == 1 &&  count++;
        setModuleCount(count);
        setKioskCaptionList(businessInfo.kioskCaptionList)
    }

    useEffect(()=>{
        if(businessInfo){
            getModuleCount();
        }
        
    },[businessInfo])

    return (
        <>
        <KioskHeader/>
        <div className='container'>
            
            <div className='row mt-2 text-center '>
                {businessInfo?.kioskTokenModule == 1 &&
                    <div className={moduleCount === 3 || moduleCount === 1 ? 'col-md-12' :'col-md-6'}>
                        <Link to={"/kiosk/checkin"} className='card bg-success text-white p-5 my-2 text-decoration-none' >
                            <h3><Receipt sx={{ fontSize: 80 }} /></h3>
                            <h3>{kioskCaptionList ? kioskCaptionList[0].title : "Token"}</h3>
                        </Link>
                    </div>
                }
                
                {businessInfo?.kioskInfoModule == 1 &&
                    <div className={moduleCount === 3 || moduleCount === 1 ? 'col-md-12' :'col-md-6'}>
                        <Link to={"/kiosk/info/categories"} className='card bg-info text-dark p-5 my-2 text-decoration-none'>
                            <h3><Info sx={{ fontSize: 80 }} /></h3>
                            <h3>{kioskCaptionList ? kioskCaptionList[1].title : "INFORMATION"}</h3>
                        </Link>
                    </div>
                }
                
                {businessInfo?.kioskDirectoryModule == 1 &&
                    <div className={moduleCount === 3 || moduleCount === 1 ? 'col-md-12' :'col-md-6'}>
                        <Link to={"/kiosk/info/directory"} className='card bg-warning text-dark p-5 my-2 text-decoration-none'>
                            <h3><Signpost sx={{ fontSize: 80 }} /></h3>
                            <h3>{kioskCaptionList ? kioskCaptionList[2].title : "Directory"}</h3>
                        </Link>
                    </div>
                }
                {businessInfo?.kioskFaqModule == 1 &&
                    <div className={moduleCount === 3 || moduleCount === 1 ? 'col-md-12' :'col-md-6'}>
                        <Link to={"/kiosk/info/faq"} className='card bg-primary text-white p-5 my-2 text-decoration-none'>
                            <h3><Help sx={{ fontSize: 80 }} /></h3>
                            <h3>{kioskCaptionList ? kioskCaptionList[3].title : "FAQ"}</h3>
                        </Link>
                    </div>
                }

                {businessInfo?.kioskFeedbackModule == 1 &&
                    <div className={ 'col-md-12'}>
                        <Link to={"/kiosk/feedback/form"} className='card bg-dark text-white p-2 my-2 text-decoration-none'>
                            <h3><NoteAlt sx={{ fontSize: 80 }} /></h3>
                            <h3>{kioskCaptionList ? kioskCaptionList[4].title : "Feedback"}</h3>
                        </Link>
                    </div>
                }
            </div>
        </div>
        {/*
        <div className='container'>
            <video width="100%" loop={true} autoPlay={true} muted={true}  >
                <source src="http://localhost/video/1.mp4" type="video/mp4" />
            </video>
        </div>
        */
        }
        </>
    )
}

export default KioskDashboarPage