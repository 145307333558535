import React,{useState} from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function RightAdsComponent({displayInfo}) {
    const [intervalz, setIntervalz] = useState(0);
    const onChange = (index, item) => {
        index++;
        if(index == displayInfo.adsList.length){
            index = 0;
        }
        setIntervalz(displayInfo.adsList[index].displayTime*1000);
    };


    return (
    <>
        {displayInfo?.adsList.length > 0 &&
            <div className='row p-2'>
                <div className='col-md-12'>
                    <Carousel
                        //verticalSwipe="natural"
                        axis='horizontal'
                        
                        onChange={onChange}
                        interval={intervalz}
                        dynamicHeight={false}
                        showThumbs={false} showStatus={false} showIndicators={false} autoPlay={true} infiniteLoop={true} >

                            {displayInfo.adsList.map(ads=>
                                <div key={ads.id}>
                                    {ads.img !== null && <img src={ads.image} className="d-block w-100" /> }
                                    {ads.title !== "" && <h4>{ads.title} </h4> }
                                    {ads.details !== "" && <h4>{ads.details}</h4> }
                                </div>
                            )}
                    </Carousel>
                </div>
            </div>
        }
    </>
  )
}

export default RightAdsComponent




/*

{ (ads.video == null  || ads.video == "" ) ?
                                        <>
                                            {ads.img !== null && <img src={ads.image} className="d-block w-100" /> }
                                            {ads.title !== "" && <h4>{ads.title} </h4> }
                                            {ads.details !== "" && <h4>{ads.details}</h4> }
                                            </>
                                            :
                                            <>
                                            <iframe src={"https://www.youtube.com/embed/"+ads.video+"?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"} frameborder="0" allowfullscreen></iframe>
                                            </>
                                        }

*/