import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { useAuth } from '../../../context/AuthContext';

function LogoutPage() {
    const {setIsLoggedIn,setAuthUser} = useAuth();
    const navigate = useNavigate()
    useEffect(()=>{
        localStorage.clear();
        setAuthUser(null);
        setIsLoggedIn(false);
        navigate("/");
    },[navigate])
    return (
        <div className="text-center"><CircularProgress/></div>
    )
}


export default LogoutPage