import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { useParams } from 'react-router-dom'
import userServices from '../userServices';
import { useAlert } from '../../../../context/AlertContext';
import TextInline from '../../../../components/InlineEdit/TextInline';
import UserRoleInput from '../components/UserRoleInput';
import UserForm from '../components/UserForm';
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline';




function UserDetailsPage() {
    const {id} = useParams();
    const {AppAlert} = useAlert();
    const [userDetails,setUserDetails] = useState(null);
    const getUserDetails = () =>{
        userServices.getById(id).then(res=>{
            setUserDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
    }

    /* Prepair User Role DD*/
    const [masters,setMasters] = useState(null);
    const getUserRoleDD = () => {
        userServices.getUserRoleDDList().then(res=>{           
            setMasters({"user-role":res.data})
            getUserDetails();
        })
    }

    useEffect(getUserRoleDD,[]);
    

    return (
        <AppLayout>
            <div className='container-fluid'>
                {userDetails && masters &&
                <div className='row'>
                    <div className='col-md-3'>
                        <AwsImageInline
                            label="Profile Image"
                            name="profileImage"
                            value={userDetails.profileImage}
                            editId={userDetails.id}
                            updateInline={userServices.updateInline}
                            path="user/profile"
                            fileId={userDetails.firstName}
                            onSuccess={getUserDetails}
                        />
                    </div>
                    <div className='col-md-9'>
                        <span className='float-end'><UserForm data={userDetails} masters={masters} refreshList={getUserDetails} /></span>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>
                                        <TextInline name="firstName" editId={id} value={userDetails.firstName} updateInline={userServices.updateInline} />
                                        <TextInline name="lastName" editId={id} value={userDetails.lastName} updateInline={userServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{userDetails.email}</td>
                                </tr>
                                <tr>
                                    <th>Phone </th>
                                    <td><TextInline name="phone" editId={id} value={ userDetails.phone === null ?"-NA-":userDetails.phone} updateInline={userServices.updateInline} /></td>
                                </tr>
                                <tr>
                                    <th>Work Location </th>
                                    <td><TextInline name="workLocation" editId={id} value={ userDetails.workLocation === null ?"-NA-":userDetails.workLocation } updateInline={userServices.updateInline} /></td>
                                </tr>
                                <tr>
                                    <th>Role </th>
                                    <td><UserRoleInput userDetails={userDetails} getUserDetails={getUserDetails} /></td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                }
            </div>
        </AppLayout>
    )
}

export default UserDetailsPage