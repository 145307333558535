import React from 'react'
import AppDoughnutChart from './AppDoughnutChart';

function QueuePieChart({dashboardData}) {

    const dataLabels = [];
    const chartData = [];

    dashboardData.queueList.forEach(queue=>{
        dataLabels.push(queue.title+" ("+queue.report.total+")");
        chartData.push(queue.report.total);
    })


    
    return (
      <>
      <AppDoughnutChart dataLabels={dataLabels} chartData={chartData} />
      </>
    )
}

export default QueuePieChart
