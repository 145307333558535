import React,{useState,useEffect} from 'react'
import KioskHeader from '../../sections/KioskHeader'
import { Link, useParams } from 'react-router-dom'
import kioskInfoServices from '../kioskInfoServices';
import { Zoom } from '@mui/material';

import InfoWithVideo from '../components/InfoWithVideo';
import InfoWithImage from '../components/InfoWithImage';
import KioskNav from '../../sections/KioskNav';

function KioskDeviceInfoDetailsPage() {
    const {infoId} = useParams();

    const [infoDetails,setInfoDetails] = useState(null);
    const getInfoDetails = () =>{
        kioskInfoServices.getInfoDetailsById(infoId).then(res=>{
            setInfoDetails(res.data);
            window.scrollTo(0,0)
        }).catch(error=>{
        })
    }

    useEffect(()=>{
        getInfoDetails();
    },[infoId])


    
    

    return (
        <div className='container-fluid'>
            <KioskHeader/>
            <div className='row mx-2'>
                {infoDetails &&
                <div className='col-md-12'>
                    <Zoom in={true} mountOnEnter unmountOnExit
                        style={{ transitionDelay:  '500ms'  }}
                    >   
                        <div>
                        {infoDetails.video !== "" &&
                            <InfoWithVideo infoDetails={infoDetails}/>
                        }

                        {infoDetails.video === "" &&
                            <InfoWithImage infoDetails={infoDetails} />
                        }

                        </div>  
                    </Zoom>
                </div>
                }
            </div>
            <KioskNav/>
        </div>
    )
}

export default KioskDeviceInfoDetailsPage
