import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { NavLink } from 'react-router-dom'


// not using this please refer to usewithQueuLayout

function QueueLayout({queueDetails, children}) {
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded'>
                            <NavLink className={"list-group-item"} aria-current="page" to={"/queue/config/"+queueDetails.id} >Config</NavLink>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        {children}
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default QueueLayout