import React,{useState} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import moment from 'moment';
import { useAlert } from '../../../../context/AlertContext';
import adminReportServices from '../adminReportServices';
import { AccessTime, Add, CalendarMonth, Search } from '@mui/icons-material';

const Timeline1 = ({remarkList}) =>{
    return (
        <ul className="timeline">
            {remarkList.map(remark=>
            <li className="timeline-item" key={remark.id}>
                <span className='badge bg-dark'><CalendarMonth/> {moment(remark.created_at).format("DD-MMM YYYY") }</span>
                <span className='badge bg-dark ms-2'><AccessTime/> {moment(remark.created_at).format("hh:ss A") }</span>
                <div className='card border-0 mt-1 mb-3'>
                    <div className='card-body'>
                        <div> <span className="fw-bold">Enquery Type : </span>{remark.enquiryTitle}</div>
                        <div> <span className="fw-bold">Action Taken : </span>{remark.responseTitle}</div>
                        {remark.remark !== undefined && remark.remark !== null && <div className="p-2 rounded bg-light"> {remark.remark} </div> }
                        <span className="blockquote-footer">{remark.firstName} </span>
                    </div>
                </div>
            </li>
            )}
        </ul>
    )
}


function RemarkByIdPage() {

    const {AppAlert} = useAlert();
    const [uid,setUid] = useState("")
    const [remarkList,setRemarkList] = useState(null)    
    const [loading,setLoadingStatus] = useState(false);

    const getRemarkList = () =>{
        adminReportServices.getRemarkById(uid).then(res=>{
            setRemarkList(res.data);
        }).catch(()=>{
        })
    }

    return (
        <AppLayout>
            <div className='container'>
                <div className='row' >
                    <div className='col-md-6 px-3'>
                        {/*<button type='button' className='ms-3 btn btn-info' onClick={()=>setDialogStatus(true)}>Add Remark</button> */}
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Uinique Id / Phone Number"
                                onChange={(e)=>setUid(e.target.value)} value={uid}
                                onKeyUp={ (e) =>{
                                    if(e.key === 'Enter') {
                                        e.preventDefault();
                                        getRemarkList();
                                    }
                                }}
                            />
                            <button className="btn btn-outline-secondary" type="button" onClick={getRemarkList}><Search/> Search</button>
                        </div>
                    </div>
                    <div className='col-md-8 px-3'>
                        {remarkList && 
                            <div className="row">
                                <div className='col-md-12 mt-3'>
                                    { remarkList.length > 0 ?
                                        <Timeline1 remarkList={remarkList} />
                                        :
                                        <p>No Remark History Found</p>
                                    }
                                    
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default RemarkByIdPage