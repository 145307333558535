import api from "../../../api/api";

const getQueueList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/queue").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getServiceListByQueueId = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/service/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getFormConfigByQueueId = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/form-config/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const doCheckin = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/appointment/checkin/kiosk-do-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCustomerTokenList = (customerId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/customer-token/"+customerId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getQueueWorkingHoursList = (queueId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/appointment/checkin/queue-working-hours/"+queueId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const kioskCheckinServices = {
    getQueueList,
    getFormConfigByQueueId,
    getServiceListByQueueId,
    doCheckin,
    getCustomerTokenList,
    getQueueWorkingHoursList    
}
export default kioskCheckinServices;
