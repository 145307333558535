import React, { useState,useEffect } from 'react'
import kioskInfoServices from '../infoKiosk/kioskInfoServices';
import IdleHandler from '../infoKiosk/components/IdleHandler';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import KioskNav from './KioskNav';
import useBusinessInfo from '../../../hooks/useBusinessInfo';
import KioskTopNav from './KioskTopNav';
import { Add, ArrowBack, Dashboard, Home } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';
import { Dialog, Fab } from '@mui/material';

function KioskHeader() {
    const businessInfo = useBusinessInfo();
    const [dialogStatus,setDialogStatus] = useState(false);
    const [menuStatus,setMenuStatus] = useState(false);
    const [inputKey,setInputKey] = useState("")
    const navigate = useNavigate();
    const location = useLocation();

    const [clickCount,setClickCount] = useState(0);
    const handleClick = (e) =>{
        setClickCount(e.detail);
        setTimeout(() => {
            setClickCount(0);
        }, 10000);
    }
    
    


    const promptLogout = (e) =>{
        //navigate("/auth/logout")
        setDialogStatus(true);
        setTimeout(() => {
            setDialogStatus(false)
        }, 10000); 
    }

    useEffect(()=>{
        if(clickCount > 1){
            setInputKey("")
            promptLogout();
        }
    },[clickCount])

    useEffect(()=>{
        if(inputKey === "98731"){
            //console.log("do logout")
            navigate("/kiosk/local-config")
        }
    },[inputKey])

    return (
        <>
            <div style={{position:"fixed", left:0, width:"100%", zIndex:1}} className='bg-white shadow'>
                <div className='container'>
                    <div className='row py-2  '  >
                        {businessInfo && 
                            <>
                            <div className='col-4'>
                                {location.pathname?.indexOf("dashboard") === -1 && location.pathname !== "/" &&
                                    <button className='btn btn-primary mt-3' onClick={()=>navigate(-1)}><ArrowBack/> Back</button>
                                }
                            </div>
                            <div className='col-4 text-center'>
                                    {businessInfo.logo !== null ?
                                        <img onClick={handleClick} className='shadow-lg' src={businessInfo.logo} style={{maxHeight:60, width:"auto"}} />
                                        :
                                        <h3 onClick={handleClick}>{businessInfo.title}</h3>
                                    }                        
                            </div>
                            <div className='col-md-4'>
                                { /*location.pathname?.indexOf("dashboard") === -1 && location.pathname !== "/" &&
                                    <Fab size="medium" className='mt-3 float-end'  color="primary"><Add/></Fab>
                                */}
                                { /*
                                location.pathname?.indexOf("dashboard") === -1 && location.pathname !== "/" &&
                                <Link to={"/kiosk/dashboard"} >
                                    <Fab size="medium" className='mt-3 float-end'  color="primary"><Home/></Fab>
                                </Link>
                                */
                                }
                            </div>
                            </>
                        }
                        
                        {
                            location.pathname?.indexOf("dashboard") === -1 &&
                            <IdleHandler/>
                        }
                    </div>
                    <Dialog
                        open={dialogStatus}
                        keepMounted
                        
                        maxWidth="sm"
                    >
                        <div className='card'>
                            <div className='card-body'>
                                <input type="text" class="form-control" onChange={(e)=>setInputKey(e.target.value)}  value={inputKey} placeholder="Enter Key to continue" />
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <div style={{height:100}}></div>
        </>
    )
}

export default KioskHeader


/*

<div className='col-md-3' onClick={handleClick1}>
                {location.pathname?.indexOf("dashboard") === -1 && location.pathname !== "/" && <KioskTopNav/> }
            </div>


<div className='col-md-3' onClick={handleClick2} >
            </div>

const [click1,setClick1] = useState(0);
    const [click2,setClick2] = useState(0);
    const handleClick1 = (e) =>{
        //console.log(e.detail);
        setClick1(e.detail);
        setTimeout(() => {
            setClick1(0);
        }, 5000);
    }
    const handleClick2 = (e) =>{
        //console.log(e.detail);
        setClick2(e.detail);
        setTimeout(() => {
            setClick2(0);
        }, 5000);
    }

*/