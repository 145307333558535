import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import GatepassDashboardPage from '../app/gatepass/dashboard/pages/GatepassDashboardPage'
import GatepassVisitorListPage from '../app/gatepass/visitor/pages/GatepassVisitorListPage'
import GatepassGeneratePage from '../app/gatepass/visitor/pages/GatepassGeneratePage'
import GatepassDetailsPage from '../app/gatepass/visitor/pages/GatepassDetailsPage'
// Gatepass




function ReceptionRoutes() {

    const {authUser} = useAuth()

    return (
        <Routes>
            {/* Gatepass */}
            {/* <Route path="/counter/dashboard" element={<GatepassDashboarPage /> }></Route> */}
            {authUser.role == 7 && <Route path="/" element={<GatepassDashboardPage /> }></Route> }
            <Route path="/gatepass/dashboard" element={<GatepassDashboardPage /> }></Route>
            <Route path="/gatepass/generate" element={<GatepassGeneratePage /> }></Route>
            <Route path="/gatepass/details/:id" element={<GatepassDetailsPage /> }></Route>
            <Route path="/gatepass/visitor" element={<GatepassVisitorListPage /> }></Route>
        </Routes>
        
    )
}

export default ReceptionRoutes