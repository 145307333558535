import React,{useState,useEffect, useRef} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import adminReportServices from '../adminReportServices';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Download } from '@mui/icons-material';
import { Rating } from '@mui/material';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import userServices from '../../user/userServices';
import moment from 'moment';

function RemarkReportPage() {
  const [filter,setFilter] = useState(null);
  const [reportData,setReportData] = useState(null);
  const [masters,getMasterLabel] = useSystemMasterList(['remark-enquiry',"remark-response"]);
  const getReportData = ()=>{
    if(filter!==null){
        adminReportServices.getRemarkReportData(filter).then(res=>{
            setReportData(res.data);
        }).catch(error=>{
      })
    }
  }

  useEffect(()=>{
      getReportData();
  },[filter])


  /* Grid Work */
  const gridRef = useRef();
  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:180,
    filter:true,
    sortable: true,
    cellStyle: { 'textTransform': 'uppercase' },
  });

  const [columnDefs,setColumnDef] = useState();
  const prepairColumnDef = () => {
      let _columnDefs = [
        {field:"uid",headerName: "UID"},
        {field:"attendantUserId",headerName:"Counter Person",valueGetter: params => getUserName(params.data.attendantUserId)},
        {field:"attendantUserId",headerName:"Counter #",valueGetter: params => getWorkLocation(params.data.attendantUserId)},
        {field:"enquiryId",headerName: "Enquiry",valueGetter: (params) => getMasterLabel("remark-enquiry",params.data.enquiryId)},
        {field:"responseId",headerName: "Response",valueGetter: (params) => getMasterLabel("remark-response",params.data.responseId)},
        {field:"remark",headerName: "remark"},
        {field:"created_at",headerName: "Date", valueGetter: (params) => moment(params.data.created_at).format("DD-MMM-YYYY")},
        {field:"created_at",headerName: "Time", valueGetter: (params) => moment(params.data.created_at).format("hh:mm A")},
      ];
      setColumnDef(_columnDefs);
  }

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv("data.csv");
  };

  



/* Prepair User Master start*/
const [userMaster,setUserMaster] = useState(null);
const getUserDD = () => {
    userServices.getUserDDList().then(res=>{
        setUserMaster(res.data)
    })
}

const getUserName = (id) => {
    let label = userMaster.find(m => m.value === id)
    if(label!== undefined){
        return label.firstName+" "+label.lastName;
    } else {
        return "NA";
    }
    
}

const getWorkLocation = (id) => {
    let label = userMaster.find(m => m.value === id)
    if(label!== undefined){
        return label.workLocation;
    } else {
        return "NA";
    }
}


useEffect(()=>{
  if(masters !== null && userMaster !== null){
    prepairColumnDef()
  }
},[masters,userMaster])

useEffect(()=>{
  getUserDD();
},[]);

/* Prepair User Master End */

  

  

  return (
    <AppLayout>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-6'>
                  <h3>Remark Report</h3>
                </div>
                <div className='col-md-6'>
                      <span className='float-end'>
                        <DateSelect filter={filter} setFilter={setFilter} />
                        {reportData && <button type="button" onClick={onBtnExport} className="btn btn-primary ms-3 float-end"><Download/>  Export</button> }
                      </span>
                </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mt-3'>    
                <div className="ag-theme-alpine" style={{height:600}}>
                  {masters && 
                    <AgGridReact
                            ref={gridRef}
                            rowData={reportData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            onGridReady={getReportData}
                    />
                  }
                  </div>
                </div>
              </div>
        </div>
    </AppLayout>
  )
}

export default RemarkReportPage