import { Help, Info, Receipt, Signpost } from '@mui/icons-material'
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import useBusinessInfo from '../../../hooks/useBusinessInfo';
import { useKioskConfig } from '../../../context/KioskConfigContext';

function KioskNav() {

    //const businessInfo = useBusinessInfo();
    const [businessInfo] = useKioskConfig();

    const [moduleCount,setModuleCount] = useState(0);
    const [kioskCaptionList,setKioskCaptionList] = useState(null);

    const getModuleCount = () =>{
        let count = 0;
        businessInfo?.kioskTokenModule == 1 &&  count++;
        businessInfo?.kioskInfoModule == 1 &&  count++;
        businessInfo?.kioskDirectoryModule == 1 &&  count++;
        businessInfo?.kioskFaqModule == 1 &&  count++;
        setModuleCount(count);        
    }

    useEffect(()=>{
        if(businessInfo){
            getModuleCount();
            setKioskCaptionList(businessInfo.kioskCaptionList)
        }
        
    },[businessInfo])

  return (
        <>
        <div style={{height:150}}></div>
        <div style={{width:"100%", zIndex:1}} > {/* position:"fixed", left:0, bottom:0, width:"100%", zIndex:1 */}
            <div className='row mt-5 text-center '>
                <div className='col-md-12' >

                    {businessInfo?.kioskTokenModule == 1 &&
                    
                        <Link to={"/kiosk/checkin"} className='btn btn-success m-2 p-3 rounded-pill' >
                            <Receipt sx={{ fontSize: 50 }} />
                        </Link>
                    
                    }
                    {businessInfo?.kioskInfoModule == 1 &&
                    
                        <Link to={"/kiosk/info/categories"} className='btn btn-info m-2 p-3 rounded-pill'>
                            <Info sx={{ fontSize: 50 }} />
                        </Link>
                    
                    }
                    {businessInfo?.kioskDirectoryModule == 1 &&
                    
                        <Link to={"/kiosk/info/directory"} className='btn btn-warning m-2 p-3 rounded-pill'>
                            <Signpost sx={{ fontSize: 50 }} />
                        </Link>
                    
                    }
                    
                    
                        <Link to={"/kiosk/info/faq"} className='btn btn-primary m-2 p-3 rounded-pill'>
                            <Help sx={{ fontSize: 50 }} />
                        </Link>
                    
                
                </div>
            </div>
        </div>
        </>
    )
}

export default KioskNav



    {/*
            <div className='row mt-5 text-center bg-light'>
                
                {businessInfo?.kioskTokenModule == 1 &&
                <div className='col-md-3'>
                    <Link to={"/kiosk/checkin"} className='card bg-success text-white p-2 my-2 text-decoration-none shadow' >
                        <h3><Receipt sx={{ fontSize: 60 }} />{kioskCaptionList ? kioskCaptionList[0].title : "Token"}</h3>
                    </Link>
                </div>
                }
                {businessInfo?.kioskInfoModule == 1 &&
                <div className='col-md-3'>
                    <Link to={"/kiosk/info/categories"} className='card bg-info text-dark p-2 my-2 text-decoration-none'>
                        <h3><Info sx={{ fontSize: 60 }} />{kioskCaptionList ? kioskCaptionList[1].title : "INFORMATION"}</h3>
                    </Link>
                </div>
                }
                {businessInfo?.kioskDirectoryModule == 1 &&
                <div className='col-md-3'>
                    <Link to={"/kiosk/info/directory"} className='card bg-warning text-dark p-2 my-2 text-decoration-none'>
                        <h3><Signpost sx={{ fontSize: 60 }} />{kioskCaptionList ? kioskCaptionList[2].title : "Directory"}</h3>
                    </Link>
                </div>
                }
                {businessInfo?.kioskFaqModule == 1 &&
                <div className='col-md-3'>
                    <Link to={"/kiosk/info/faq"} className='card bg-primary text-white p-2 my-2 text-decoration-none'>
                        <h3><Help sx={{ fontSize: 60 }} />{kioskCaptionList ? kioskCaptionList[3].title : "FAQ"}</h3>
                    </Link>
                </div>
                }
            </div>
            */}