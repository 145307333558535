import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { PersonAdd } from '@mui/icons-material'
import { Link } from 'react-router-dom'

function GatepassDashboardPage() {
  return (
    <AppLayout>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    {/* <Link className="btn btn-outline-primary float-end" to="/gatepass/generate" ><PersonAdd/> Generate</Link> */}
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-12 text-center'>
                    <Link className="btn btn-lg me-5 btn-outline-primary" to="/gatepass/visitor" >Gatepass Visitor List</Link>
                    <Link className="btn btn-lg me-5 btn-outline-primary" to="/gatepass/generate" ><PersonAdd/> Generate Gatepass</Link>
                </div>
            </div>
        </div>
    </AppLayout>
  )
}

export default GatepassDashboardPage