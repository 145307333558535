import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material'
import React,{useState} from 'react'
import userServices from '../userServices';
import { useAlert } from '../../../../context/AlertContext';

function UserRoleInput({userDetails,getUserDetails}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [userRoleDDList,setUserRoleDDList] =  useState(null);
    const {AppAlert} =  useAlert();

    const getUserRoleDD = () => {
        userServices.getUserRoleDDList().then(res=>{
            setUserRoleDDList(res.data);
        })
    }
    const updateUserRole = (role)=>{
        let payload = {
            id:userDetails.id,
            role:role,
            changePassword:false
        }
        userServices.save(payload).then(res=>{
            AppAlert(res.msg);
            getUserDetails();
            setDialogStatus(false);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <>
            <button className='btn btn-outline-primary' onClick={()=>{setDialogStatus(true); getUserRoleDD()}}>{userDetails.roleTitle}</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="sm"
            >
                <DialogTitle>Select Role</DialogTitle>
                <DialogContent>
                    <ul className="list-group">
                        {userRoleDDList == null && <li className="list-group-item"><CircularProgress/></li> }
                        {userRoleDDList && userRoleDDList.map(userRole=>
                            <button key={userRole.value} 
                                type='button' className="list-group-item list-group-item-action"
                                onClick={()=>updateUserRole(userRole.value)}
                            >
                                {userRole.label}
                            </button>
                        )}
                    </ul>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default UserRoleInput
