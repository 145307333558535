import React from 'react'
import { Route, Routes } from 'react-router'

import AdminDashboardPage from '../app/admin/dashboard/pages/AdminDashboardPage'
import LogoutPage from '../app/auth/pages/LogoutPage'
import SystemMasterListPage from '../app/master/pages/SystemMasterListPage'


// Queue 
import QueueDashboardPage from '../app/admin/queues/pages/QueueDashboardPage'
import QueueConfigPage from '../app/admin/queues/pages/QueueConfigPage'
import QueueFormConfigPage from '../app/admin/queues/pages/QueueFormConfigPage'
import BusinessHoursPage from '../app/admin/queues/pages/BusinessHoursPage'
import BusinessBreaksPage from '../app/admin/queues/pages/BusinessBreaksPage'
import BusinessHolidayPage from '../app/admin/queues/pages/BusinessHolidayPage'
import BusinessServicesPage from '../app/admin/queues/pages/BusinessServicesPage'
import BusinessStaffPage from '../app/admin/queues/pages/BusinessStaffPage'




import DisplayRoutes from './DisplayRoutes'
import KioskRoutes from './KioskRoutes'
import CounterRoutes from './CounterRoutes'

import TokenReportPage from '../app/admin/report/pages/TokenReportPage'
import AdminLandingPage from '../app/admin/dashboard/pages/AdminLandingPage'
import ReceptionRoutes from './ReceptionRoutes'
import FeedbackReportPage from '../app/admin/report/pages/FeedbackReportPage'
import RemarkByIdPage from '../app/admin/report/pages/RemarkByIdPage'
import RemarkDashboardPage from '../app/admin/dashboard/pages/RemarkDashboardPage'
import RemarkReportPage from '../app/admin/report/pages/RemarkReportPage'





function ManagerRoutes() {
  return (
    <>
      <Routes>
        {/* Admin */}
        <Route path="/" element={ <AdminLandingPage /> }></Route>
        <Route path="/admin/dashboard" element={<AdminDashboardPage/>}></Route>
        <Route path="/remark/dashboard" element={<RemarkDashboardPage/>}></Route>
        {/* Queues */}
        <Route path="/queue/dashboard" element={<QueueDashboardPage /> }></Route>
        <Route path="/queue/config/:id" element={<QueueConfigPage /> }></Route>
        <Route path="/queue/form-config/:id" element={<QueueFormConfigPage /> }></Route>
        <Route path="/queue/business-hours/:id" element={<BusinessHoursPage /> }></Route>
        <Route path="/queue/business-breaks/:id" element={<BusinessBreaksPage /> }></Route>
        <Route path="/queue/business-holiday/:id" element={<BusinessHolidayPage /> }></Route>
        <Route path="/queue/services/:id" element={<BusinessServicesPage /> }></Route>
        <Route path="/queue/staff/:id" element={<BusinessStaffPage /> }></Route>
        

        <Route path="/report/token-report" element={<TokenReportPage />}></Route>
        <Route path="/report/feedback-report" element={<FeedbackReportPage />}></Route>
        <Route path="/report/remark-report" element={<RemarkReportPage />}></Route>
        <Route path="/report/remark/search" element={<RemarkByIdPage />}></Route>
      </Routes>

      
      <ReceptionRoutes/>
      <CounterRoutes/>
      <DisplayRoutes/>
      <KioskRoutes/>
    </>
  )
}

export default ManagerRoutes