import { Switch } from '@mui/material'
import React from 'react'
import { useKioskConfig } from '../../../../context/KioskConfigContext';
import { Link } from 'react-router-dom';
import useBusinessInfo from '../../../../hooks/useBusinessInfo';

    

    function KioskLocalConfigPage() {
    const [businessInfo,updateKioskConfig,resetConfig] = useKioskConfig();
    const serverInfo = useBusinessInfo();

    const handleStatusChange = (e,module) =>{
        let _businessInfo = {...businessInfo};
        _businessInfo[module] = e.target.checked ? 1 : 0;
        updateKioskConfig(_businessInfo);
    }



    return (
        <>
        <div className='container-fluid'>
            <div className='row shadow'>
                <div className='col-md-12'>
                    <h4 className='text-center p-2'>Kiosk Local Configuration</h4>
                </div>
            </div>
        </div>
        {businessInfo && serverInfo &&
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                    </div>
                    <div className='col-md-12'>
                        <ul class="list-group mt-3">
                            <li class="list-group-item bg-primary text-white">Module Setting</li>
                                {serverInfo.kioskTokenModule == 1 &&
                                    <li class="list-group-item">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>Token Module</div>
                                            <div>
                                                <Switch 
                                                    checked={businessInfo.kioskTokenModule === 1}
                                                    onChange={(e)=>handleStatusChange(e,"kioskTokenModule")}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                }
                            {serverInfo.kioskInfoModule == 1 &&
                                <li class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>Information Module</div>
                                        <div>
                                            <Switch 
                                                checked={businessInfo.kioskInfoModule === 1}
                                                onChange={(e)=>handleStatusChange(e,"kioskInfoModule")}
                                            />
                                        </div>
                                    </div>
                                </li>
                            }
                            {serverInfo.kioskFaqModule == 1 &&
                                <li class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>FAQ Module</div>
                                        <div>
                                            <Switch 
                                                checked={businessInfo.kioskFaqModule === 1}
                                                onChange={(e)=>handleStatusChange(e,"kioskFaqModule")}
                                            />
                                        </div>
                                    </div>
                                </li>
                            }
                            {serverInfo.kioskDirectoryModule == 1 &&
                                <li class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>Directory Module</div>
                                        <div>
                                            <Switch 
                                                checked={businessInfo.kioskDirectoryModule === 1}
                                                onChange={(e)=>handleStatusChange(e,"kioskDirectoryModule")}
                                            />
                                        </div>
                                    </div>
                                </li>
                            }
                            <li class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>Feedback Module</div>
                                    <div>
                                        <Switch 
                                            checked={businessInfo.kioskFeedbackModule === 1}
                                            onChange={(e)=>handleStatusChange(e,"kioskFeedbackModule")}
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>


                    <div className='col-md-12'>
                        <ul class="list-group mt-3">
                            <li class="list-group-item bg-primary text-white">Kiosk Configuration</li>
                            {businessInfo.kioskTokenModuleEnabled &&
                                <li class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>Quick Checkout</div>
                                        <div>
                                            <Switch 
                                                checked={businessInfo.quickCheckin === 1}
                                                onChange={(e)=>handleStatusChange(e,"quickCheckin")}
                                            />
                                        </div>
                                    </div>
                                </li>
                            }
                            {businessInfo.kioskTokenModuleEnabled &&
                                <li class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>Quick Print</div>
                                        <div>
                                            <Switch 
                                                checked={businessInfo.quickPrint === 1}
                                                onChange={(e)=>handleStatusChange(e,"quickPrint")}
                                            />
                                        </div>
                                    </div>
                                </li>
                            }
                        </ul>
                        <div class="list-group mt-3">
                            <button type="button" class="list-group-item bg-light list-group-item-action" onClick={resetConfig}>Reset Configuration</button>
                        </div>
                        <Link to={"/kiosk/dashboard"} className='btn btn-dark w-100 mt-3' > Back to Dashboard  </Link>
                        <Link to={"/auth/logout"} className='btn btn-danger w-100 my-3' > Logout  </Link>
                    </div>
                </div>
                
            </div>
        }
        </>
    )
    }

    export default KioskLocalConfigPage