import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Error401Page() {
  const navigate = useNavigate()
  useEffect(()=>{
    setTimeout(()=>{
      navigate("/auth/logout");
    },3000)
  })
  return (
    <div className='container'>
      <div className='row mt-5'>
        <div className='col-md-12 text-center'>
          <img src='/images/gtoken-logo.jpg' style={{width:"30%"}} />
        </div>
        <div className='col-md-12 text-center'>
          <h3 className='text-danger'>401 Unauthorized</h3>
          <h3 className='text-danger'>You need to Login Again</h3>
        </div>
      </div>
    </div>
  )
}

export default Error401Page