import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'
import { Dashboard, DoorSliding, Dvr, FollowTheSigns, ListAlt, Newspaper, TransferWithinAStation, Tv } from '@mui/icons-material'

function AdminLandingPage() {
  return (
    <AppLayout>
      <div className='container'>
        <div className='row mt-5 text-center'>
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/admin/dashboard"} className='btn text-white'   >
                  <h3><Dashboard style={{fontSize:60}} /></h3>
                  <p>Token Dashboard</p>
                </Link>
              </div>
          </div>

          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/queue/dashboard"} className='btn text-white'   >
                  <h3><TransferWithinAStation style={{fontSize:60}} /></h3>
                  <p>Manage Queue</p>
                </Link>
              </div>
          </div>

          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/kiosk/dashboard"} className='btn text-white'   >
                  <h3><Dvr style={{fontSize:60}} /></h3>
                  <p>Kiosk View</p>
                </Link>
              </div>
          </div>

          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/display/dashboard"} className='btn text-white'   >
                  <h3><Tv style={{fontSize:60}} /></h3>
                  <p>Display Dashboard</p>
                </Link>
              </div>
          </div>

          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/counter/dashboard"} className='btn text-white'   >
                  <h3><FollowTheSigns style={{fontSize:60}} /></h3>
                  <p>Counter Dashboard</p>
                </Link>
              </div>
          </div>

          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/gatepass/dashboard"} className='btn text-white'   >
                  <h3><DoorSliding style={{fontSize:60}} /></h3>
                  <p>Manage Gatepass</p>
                </Link>
              </div>
          </div>

          {/*
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/cms/ads"} className='btn text-white'   >
                  <h3><Newspaper style={{fontSize:60}} /></h3>
                  <p>Ads / News / Kiosk Info</p>
                </Link>
              </div>
          </div>
          
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/report/token-report"} className='btn text-white'   >
                  <h3><ListAlt style={{fontSize:60}} /></h3>
                  <p>Token Report</p>
                </Link>
              </div>
          </div>
          */}

        </div>
      </div>
    </AppLayout>  
  )
}

export default AdminLandingPage