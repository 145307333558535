import api from "../../../api/api";

const getCheckinQueueList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/counter/checkin/queue-list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCheckinPendingList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/counter/checkin/pending-list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const callCheckinVisitor = () =>{
    return new Promise((resolve, reject)=>{
        api.get("counter/checkin/call-visitor").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const callOnHoldVisitor = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("counter/checkin/call-onhold-visitor",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateCheckinStatus = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("counter/checkin/update-status",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const transferToken = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("counter/checkin/transfer-token",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const addRemark = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("counter/checkin/add-remark",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}

const getRemarkList = (uid) =>{
    return new Promise((resolve, reject)=>{
        api.get("counter/checkin/remark/"+uid).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}





const counterCheckinServices = {
    getCheckinQueueList,
    getCheckinPendingList,
    callCheckinVisitor,
    updateCheckinStatus,
    callOnHoldVisitor,
    transferToken,
    addRemark,
    getRemarkList   
}
export default counterCheckinServices;
