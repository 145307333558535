import React, { useState,useEffect } from 'react'
import { useAlert } from '../../../../context/AlertContext';
import kioskCheckinServices from '../kioskCheckinServices';

function ServiceSelectComponent( {selectedQueueId, selectedService,setSelectedService, setActiveStep}) {

    const [queueServiceList,setQueueServiceList] = useState(null);
    const {AppAlert} = useAlert();


    const getQueueServiceList = () =>{
        if(selectedQueueId !== ""){
            kioskCheckinServices.getServiceListByQueueId(selectedQueueId).then(res=>{
                setQueueServiceList(res.data);
                if(res.data.length === 1){
                    selectQueueService(res.data[0]);
                }
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    }
    
    useEffect(()=>{
        getQueueServiceList()
    },[selectedQueueId])


    const selectQueueService = (queueService) =>{
        setSelectedService(queueService);
        setActiveStep(2);
    }

    return (
        <>
            {queueServiceList &&
                <div className='list-group'>
                    {queueServiceList.map(queueService=>
                        <span key={queueService.id}>
                            <button type="button" onClick={()=>selectQueueService(queueService)} class="list-group-item list-group-item-action">{queueService.title}</button>
                        </span>
                    )}
                </div>
            }

        </>
    )
}

export default ServiceSelectComponent