import React, { useEffect, useState } from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import { useParams } from 'react-router-dom'
import kioskInfoServices from '../kioskInfoServices';
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline';
import KioskInfoForm from '../components/KioskInfoForm';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import { Switch } from '@mui/material';

function KioskInfoDetailsPage() {
    const {id} = useParams();
    const [masters,getMasterLabel] = useSystemMasterList(['kiosk-info-category']);

    // selected kiosk info Details
    const [kioskInfoDetails,setKioskInfoDetails] = useState(null);
    const getKioskInfoDetails = () =>{
      kioskInfoServices.getById(id).then(res=>{
        setKioskInfoDetails(res.data);
      })
    }
    useEffect(()=>{
      getKioskInfoDetails();
    },[])

    const handleStatusChange = (e,id) =>{
      let payload = {
        id:id,
        status:e.target.checked ? 1 : 0,
      }
      console.log(payload);
      kioskInfoServices.save(payload).then(res=>{
        getKioskInfoDetails();
      })
    }

    return (
      <div className='row'>
        {kioskInfoDetails &&
          <>
            <div className='col-md-9'>
              <h3>{kioskInfoDetails.title }</h3>
            </div>
            <div className='col-md-3'>
              <span className='float-end'>
                <KioskInfoForm data={kioskInfoDetails} refreshList={getKioskInfoDetails} masters={masters} />
                <Switch 
                  color="success"
                  checked={kioskInfoDetails.status === 1}
                  onChange={(e)=>handleStatusChange(e,kioskInfoDetails.id)}
                />
              </span>
            </div>
          </>
        }
        

        {kioskInfoDetails &&
          <div className='col-md-4 mt-3'>
            <div className='mt-2'>
              <AwsImageInline
                label="Image"
                name="image"
                value={kioskInfoDetails.image}
                editId={kioskInfoDetails.id}
                updateInline={kioskInfoServices.updateInline}
                path="cms/kiosk/info"
                fileId={kioskInfoDetails.id}
                onSuccess={getKioskInfoDetails}
              />
            </div>
            <div>
              {kioskInfoDetails.video == "" ? 
                  <div className='my-5 text-center'><h5>No Video Selected</h5></div>
                :
                  <div className='text-center mt-5'>
                    <iframe src={"https://www.youtube.com/embed/"+kioskInfoDetails.video+"?controls=1&showinfo=0&rel=0&autoplay=0&loop=1&mute=1"} frameborder="0" allowfullscreen></iframe>
                  </div>
              }
            </div>
          </div>
        }
        {kioskInfoDetails &&
          <div className='col-md-8'>
            <table className='table'>
              <tbody>
                <tr className='table-dark'>
                  <th>Category</th>
                  <td>{getMasterLabel("kiosk-info-category",kioskInfoDetails.categoryId) }</td>
                </tr>
                <tr className='table-primary'>
                  <th>Title</th>
                  <td>{kioskInfoDetails.title }</td>
                </tr>
                <tr className='table-info'>
                  <th>Sub Title</th>
                  <td>{kioskInfoDetails.subTitle }</td>
                </tr>
                <tr>
                  <th>Details</th>
                  <td>{kioskInfoDetails.details }</td>
                </tr>
                <tr>
                  <th>Link</th>
                  <td><a href={kioskInfoDetails.link} target='_blank'>Link</a></td>
                </tr>

              </tbody>
            </table>
          </div>
        }
      </div>
    )
}

export default withCMSLayout(KioskInfoDetailsPage)