import React, { useState } from 'react'
import withQueueLayout from '../components/withQueueLayout'
import { useAlert } from '../../../../context/AlertContext'
import { Delete } from '@mui/icons-material';
import queueUpdateServices from '../queueUpdateServices';
import moment from 'moment';

function BusinessHolidayPage({queueConfig,getQueueConfig}) {

    const {AppAlert} = useAlert();
    const [occasionInput,setOccasionInput] = useState("");
    const [holidayDateInput,setHolidayDateInput] = useState("");

    const handleDelete = (id) =>{
        queueUpdateServices.removeHoliday(id).then(res=>{
            getQueueConfig();
            AppAlert(res.msg);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    const addHoliday = () =>{
        let payload = {
            queueId:queueConfig.queueDetails.id,
            occasion:occasionInput,
            holidayDate:holidayDateInput,
        }        
        queueUpdateServices.updateHolidays(payload).then(res=>{
            getQueueConfig();
            AppAlert(res.msg);
            setHolidayDateInput("");
            setOccasionInput("");
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }


    return (
        <div className='card'>
            <div className='card-header'>Business Holidays</div>
            <div className='card-body'>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th>Date</th>
                                    <th>Occasion</th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {queueConfig.businessHolidays.map(businessHoliday=>
                                    <tr key={businessHoliday.id}>
                                        <td>{moment(businessHoliday.holidayDate).format("LL")}</td>
                                        <td>{businessHoliday.occasion}</td>
                                        <td><button type='button' className='btn btn-danger' onClick={()=>handleDelete(businessHoliday.id)} ><Delete/></button></td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>  
                        {queueConfig.businessHolidays.length === 0 &&
                                <h3>No Holiday Available</h3>
                        } 
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <input type="text" className="form-control" value={occasionInput} onChange={(e)=>setOccasionInput(e.target.value)} placeholder='Enter Occasion Name' />
                    </div>
                    <div className='col-md-4'>
                        <input type="date" className="form-control" value={holidayDateInput} onChange={(e)=>setHolidayDateInput(e.target.value)} placeholder='Enter Date' />
                    </div>
                    <div className='col-md-4'>
                        { occasionInput !== "" && holidayDateInput !== "" &&
                            <button type="button" onClick={addHoliday} className='btn btn-outline-primary'>Add</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withQueueLayout(BusinessHolidayPage)