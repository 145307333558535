import React, { useEffect, useState } from 'react'
import { Mic } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material';
import AppKeyboardInput from './AppKeyboardInput';


function SearchInputWithMic({searchInput,setSearchInput,doSearch}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [listenValue,setListenValue] = useState("");

    useEffect(()=>{
        if(listenValue!==""){
            doSearch();
        }
    },[listenValue])

    const listen = () =>{
        let SR = window.SpeechRecognition || window.webkitSpeechRecognition;
        let r = new SR;
        //console.log(r);
        r.start();
        r.onstart = (e) =>{
            setListenValue("");
            setDialogStatus(true);
            //console.log("Started")
        }
        r.onend = (e) =>{
            setDialogStatus(false);
            //console.log("ended")
        }
        r.onresult = (e) =>{
            console.log(e);
            console.log(e.results[0][0].transcript)
            setSearchInput(e.results[0][0].transcript)
            setListenValue(e.results[0][0].transcript);
        }
    }

    return (
        <>
            {/*
            <div className="input-group my-3">
                <input
                    value={searchInput}
                    onChange={(e)=>
                    setSearchInput(e.target.value)
                    }
                    onKeyUp={handleKeyUp}
                    type="text" 
                    className="form-control" 
                    placeholder="Search" aria-label="Search" aria-describedby="buttonSearch"     
                />
                <button className='btn btn-dark' type='button' onClick={listen}><Mic/></button>
                <button className="btn btn-outline-primary" type="button" id="buttonSearch" onClick={doSearch}>Search</button>
            </div>
            */}
            <div className='row'>
                <div className='col-md-8 col-6'>
                    <AppKeyboardInput label={""} inputValue={searchInput} setInputValue={setSearchInput} type="text" onSuccess={doSearch} />
                </div>
                <div className='col-md-4 col-6'>
                    <button className='btn btn-dark' type='button' onClick={listen}><Mic/></button>
                    <button className="btn btn-outline-primary ms-3" type="button" id="buttonSearch" onClick={doSearch}>Search</button>
                </div>
            </div>


            <Dialog
                open={dialogStatus}
                maxWidth="md"
            >
                <DialogContent>
                    <h4>Listning...</h4>
                    <div className='gtoken-mic'></div>
                </DialogContent>

            </Dialog>
        </>
    )
}

export default SearchInputWithMic