import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ChartBackGroundColors } from '../ChartColors';

ChartJS.register(ArcElement, Tooltip, Legend);
const  AppPieChart = ({dataLabels,chartData}) => {
        
    const data = {
        labels: dataLabels,
        datasets: [
          {
            data: chartData,
            backgroundColor: ChartBackGroundColors,
            borderColor: ["#FFF"],
            borderWidth: 1,
          },
        ],
    };
    const options = {
      legend: {
        display: true,
      },
      plugins: {
        legend: {
        position: "bottom",
        display: true
        },
    }
    };
    return (
        <>
            <Pie data={data}  options={options}/>
        </>
    )
}
export default AppPieChart