import React from 'react'

function CounterData({dashboardData}) {
    return (
        <>
        <div className="row mt-4">
            <div className="col-md-2 col-sm-6 col-xs-6">
                <div className="card bg-primary text-white shadow  p-1">
                    <p>Waiting</p>
                    <span className="text-center"><h1>{dashboardData.totalReport.waiting}</h1></span>
                </div>
            </div>
            <div className="col-md-2 col-sm-6 col-xs-6">
                <div className="card bg-warning shadow p-1">
                    <p>In Progress</p>
                    <span className="text-center"><h1>{dashboardData.totalReport.inProgress}</h1></span>
                </div>
            </div>
            <div className="col-md-2 ">
                <div className="card bg-success text-white shadow p-1">
                    <p>Closed</p>
                    <span className="text-center"><h1>{dashboardData.totalReport.closed}</h1></span>
                </div>
            </div>
            <div className="col-md-2 ">
                <div className="card bg-danger text-white shadow p-1">
                    <p>Self Cancelled</p>
                    <span className="text-center"><h1>{dashboardData.totalReport.selfCancelled}</h1></span>
                </div>
            </div>
            <div className="col-md-2 ">
                <div className="card bg-info shadow p-1">
                    <p>Not Appeared</p>
                    <span className="text-center"><h1>{dashboardData.totalReport.notAppeared}</h1></span>
                </div>
            </div>
            <div className="col-md-2">
                <div className="card bg-dark text-white shadow p-1">
                    <p>Total</p>
                    <span className="text-center"><h1>{dashboardData.totalReport.total}</h1></span>
                </div>
            </div>
        </div>
        <div className="row mt-4 mb-4">
            <div className="col-md-8">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th width="50%"></th>
                            <th width="10%" className="text-center text-primary">Waiting</th>
                            <th width="10%" className="text-center bg-warning ">In Progress</th>
                            <th width="10%" className="text-center text-success">Closed</th>
                            <th width="10%" className="text-center  text-danger">Self Cancelled</th>
                            <th width="10%" className="text-center  text-info">Not Appeared</th>
                            <th width="10%" className="text-center ">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dashboardData.queueList && dashboardData.queueList.map(queue=>
                            <tr key={queue.id}>
                            <td>{queue.title}</td>
                            <th className="text-center bg-primary text-white">{queue.report.waiting}</th>
                            <th className="text-center bg-warning ">{queue.report.inProgress}</th>
                            <th className="text-center bg-success text-white">{queue.report.closed}</th>
                            <th className="text-center bg-danger text-white">{queue.report.selfCancelled}</th>
                            <th className="text-center bg-info ">{queue.report.notAppeared}</th>
                            <th className="text-center bg-dark text-white">{queue.report.total}</th>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='col-md-4'>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            
                            <th width="80%" className="text-dark">Staff / Counter No</th>
                            <th width="20%" className="text-center bg-success ">Completed</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {dashboardData.counterReport && dashboardData.counterReport.map(counter=>
                            <tr key={counter.counterUserId}>
                                <td>{counter.firstName} {counter.lastName} / {counter.workLocation}</td>
                                <th className="text-center">{counter.count}</th>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}

export default CounterData