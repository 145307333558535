import React, { useState } from 'react'
import withQueueLayout from '../components/withQueueLayout'
import { useAlert } from '../../../../context/AlertContext'
import { Chip } from '@mui/material';
import { Done } from '@mui/icons-material';
import queueUpdateServices from '../queueUpdateServices';

function BusinessServicesPage({queueConfig,getQueueConfig}) {

    const {AppAlert} = useAlert();
    const [serviceInput,setServiceInput] = useState("");

    const handleDelete = (id) =>{
        queueUpdateServices.removeService(id).then(res=>{
            getQueueConfig();
            AppAlert(res.msg);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    const addService = () =>{
        let payload = {
            queueId:queueConfig.queueDetails.id,
            title:serviceInput
        }        
        queueUpdateServices.updateBusinessService(payload).then(res=>{
            getQueueConfig();
            AppAlert(res.msg);
            setServiceInput("");
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }


    return (
        <div className='card'>
            <div className='card-header'>Available Services</div>
            <div className='card-body'>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        {queueConfig.businessServices.map(businessServices=>
                            <Chip key={businessServices.id} variant="outlined" color="success" className='mx-2' onDelete={()=>handleDelete(businessServices.id)} icon={ <Done/> } label={businessServices.title} />
                        )}
                        {queueConfig.businessServices.length === 0 &&
                            <h3>No Service Available</h3>
                        } 
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-9'>
                        <input type="text" className="form-control" value={serviceInput} onChange={(e)=>setServiceInput(e.target.value)} placeholder='Enter Service Name' />
                    </div>
                    <div className='col-md-3'>
                        { serviceInput !== "" &&
                            <button type="button" onClick={addService} className='btn btn-outline-primary'>Add</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withQueueLayout(BusinessServicesPage)