import React from 'react'
import AdminFooter from '../sections/AdminFooter'
import AdminHeader from '../sections/AdminHeader'


function AppLayout({children}) {
    return (
        
        <div className="main" >
            <AdminHeader />
                {children}
            <AdminFooter />
        </div>
    )
}

export default AppLayout