import React,{useState,useEffect} from 'react'
import CounterDisplay from './CounterDisplay'
import displayPageServices from '../displayPageServices';
import DisplayHeader from './DisplayHeader';
import RightAdsComponent from './RightAdsComponent';
import BottomNewsComponent from './BottomNewsComponent';

function DualCounterDisplay({displayInfo,announceToken}) {
    const [userDDList,setUserDDList] = useState(null);
    const getUserDDList = () => {
        displayPageServices.getUserDDList().then(res=>{
            setUserDDList(res.data);
        }).catch(error=>{

        })
    }
    useEffect(()=>{
        getUserDDList();
    },[])


    return (
        <div className='row'>
            <div className='col-md-12'>
                <DisplayHeader/>
            </div>
            <div className='col-md-4'>
                <CounterDisplay userDDList={userDDList} announceToken={announceToken} />
            </div>
            <div className='col-md-4'>
                {displayInfo && <RightAdsComponent displayInfo={displayInfo} /> }
            </div>
            <div className='col-md-4'>
                <CounterDisplay userDDList={userDDList} announceToken={announceToken}/>
            </div>
            <BottomNewsComponent displayInfo={displayInfo} />
        </div>
    )
}

export default DualCounterDisplay