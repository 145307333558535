import React, { useState, useEffect } from 'react'
import { AlertProvider } from './gtoken-k/context/AlertContext';
import { useAuth } from './gtoken-k/context/AuthContext'
import { Route, Routes } from 'react-router'

import AdminRoutes from './gtoken-k/routes/AdminRoutes'
import AuthRoutes from './gtoken-k/routes/AuthRoutes'
import DisplayRoutes from './gtoken-k/routes/DisplayRoutes';
import LogoutPage from './gtoken-k/app/auth/pages/LogoutPage';
import KioskRoutes from './gtoken-k/routes/KioskRoutes';
import CounterRoutes from './gtoken-k/routes/CounterRoutes';
import RechargePage from './gtoken-k/app/admin/business/pages/RechargePage';
import SuspendPage from './gtoken-k/app/admin/business/pages/SuspendPage';
import { SocketProvider } from './gtoken-k/context/SocketContext';
import ReceptionRoutes from './gtoken-k/routes/ReceptionRoutes';
import ManagerRoutes from './gtoken-k/routes/ManagerRoutes';
import Error404Page from './gtoken-k/app/errorPages/Error404Page';
import Error401Page from './gtoken-k/app/errorPages/Error401Page';
import ChangePasswordPage from './gtoken-k/app/auth/pages/ChangePasswordPage';


function App() {
    const {isLoggedIn,authUser} = useAuth();
    //console.log("authUSerrole", authUser)
    sessionStorage.removeItem("businessInfo");

    // Disable Right Click Start
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])
    // Disable Right Click End
    
    return (
        <>
        <AlertProvider>
            {isLoggedIn ? 
                <SocketProvider>
                    { authUser.role == 1 &&
                        <AdminRoutes/>
                    }
                    { authUser.role == 2 &&
                        <ManagerRoutes />
                    }
                    { authUser.role == 3 &&
                        <CounterRoutes/>
                    }
                    { authUser.role == 4 &&
                        <DisplayRoutes />
                    }
                    { authUser.role == 5 &&
                        <KioskRoutes />
                    }
                    { authUser.role == 7 &&
                        <ReceptionRoutes />
                    }
                    <Routes>
                        {/*Logout */}
                        <Route path="/admin/recharge-page" element={<RechargePage />}></Route>
                        <Route path="/admin/suspend-page" element={<SuspendPage />}></Route>
                        <Route path="/auth/logout" element={<LogoutPage/>}></Route>
                        <Route path="/auth/change-password" element={<ChangePasswordPage />}></Route>
                        <Route path="/404" element={<Error404Page/>}></Route>
                        <Route path="/401" element={<Error401Page/>}></Route>
                    </Routes> 
                </SocketProvider>
                :
                <AuthRoutes/>
            }
        </AlertProvider>
        </>
    )
}
export default App