import { Add } from '@mui/icons-material'
import React,{useState} from 'react'
import { useAlert } from '../../../../context/AlertContext';

import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';

import queueServices from '../queueServices';


function QueueForm({getQueueList}) {
    const {AppAlert} = useAlert();
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        title:"",
        prefix:"",
        avgProcessTime:"15",
        queueId:"1"
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        queueServices.clone(values).then(res =>{
            AppAlert(res.msg);
            setDialogStatus(false);
            onSubmitProps.resetForm();
            getQueueList();
        }).catch(error => {
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        title:Yup.string().required("Required"),
        prefix:Yup.string().required("Required"),
        avgProcessTime:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (

        <>
            <button type='btn' className='btn btn-outline-primary btn-lg' onClick={addNew} ><Add/> Add New Queue</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Contact"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">
                                                    
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Queue Title" name="title" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Queue Prefix" name="prefix" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="number" 
                                                        label="Avg. Process Time" name="avgProcessTime" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                        {/* JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>

        </>
    )
}

export default QueueForm