import { Autocomplete, CircularProgress, Dialog, DialogContent, DialogTitle, LinearProgress, TextField } from '@mui/material';
import React,{useState} from 'react'
import counterCheckinServices from '../counterCkeckinServices';
import { useAlert } from '../../../../context/AlertContext';
import { AccessTime, Add, CalendarMonth, Search } from '@mui/icons-material';
import moment from 'moment';

const Timeline1 = ({remarkList}) =>{
    return (
        <ul className="timeline">
            {remarkList.map(remark=>
            <li className="timeline-item" key={remark.id}>
                <span className='badge bg-dark'><CalendarMonth/> {moment(remark.created_at).format("DD-MMM YYYY") }</span>
                <span className='badge bg-dark ms-2'><AccessTime/> {moment(remark.created_at).format("hh:ss A") }</span>
                <div className='card border-0 mt-1 mb-3'>
                    <div className='card-body'>
                        <div> <span className="fw-bold">Enquery Type : </span>{remark.enquiryTitle}</div>
                        <div> <span className="fw-bold">Action Taken : </span>{remark.responseTitle}</div>
                        {remark.remark !== undefined && remark.remark !== null && <div className="p-2 rounded bg-light"> {remark.remark} </div> }
                        <span className="blockquote-footer">{remark.firstName} </span>
                    </div>
                </div>
            </li>
            )}
        </ul>
    )
}

const Timeline2 = ({remarkList}) =>{
    return (
        <div className="main-timeline-2">
            {remarkList.map((remark,i)=>
                <div className={i%2==0?"timeline-2 left-2":"timeline-2 right-2"}>
                    <div className="card">
                        <div className="card-body">
                            <span className={i%2==1?"float-start":"float-end"}>{moment(remark.created_at).format("DD-MMM") }</span>
                        <div className="card-body ">
                            <span className={i%2==1?"float-start":"float-end"}>{moment(remark.created_at).format("hh:ss:A") }</span>
                        </div>
                        </div>
                        <div className="card-body p-2">
                            <h4 className="fw-bold text-primary mb-0">{remark.enquiryTitle}</h4>
                            <h4 className="fw-bold text-primary mb-0">{remark.responseTitle}</h4>
                            <p ><span className="text-muted"> {moment(remark.created_at).fromNow()} </span> {moment(remark.created_at).format("ddd, DD-MMM-YYYY hh:ss:A") } </p>
                            <p className="mb-0"><span className="blockquote-footer">{remark.firstName} </span></p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

function TokenRemark({visitor,masters}) {
    const [dialogStatus, setDialogStatus] = useState(false); 
    const [uid,setUid] = useState("")
    const [enquiryType,setEnquiryType] = useState(null)
    const [responseType,setResponseType] = useState(null)
    const [remarkList,setRemarkList] = useState(null)
    const {AppAlert} = useAlert()
    const [loading,setLoadingStatus] = useState(false);
    const [savingInProgres,setSavingStatus] = useState(false);

    const [remark,setRemark] = useState("");
    const handleRemarkInput = (e) => {
        let val = e.target.value;
        if(val.length <= 30) {
            setRemark(val)
        }
    }

    const submitRemark = () =>{
        let payload  = {
            tokenId: visitor.id,
            uid:uid,
            enquiryId:enquiryType.value,
            responseId:responseType.value
        }
        if(remark.length > 0 ){
            payload.remark = remark;
        }
        console.log(payload);
        setSavingStatus(true);
        counterCheckinServices.addRemark(payload).then(res=>{
            AppAlert("New Remark Saved Successfully");            
            setDialogStatus(false);
            setSavingStatus(false);
            getRemarkList();
        }).catch(error=>{
            AppAlert(error.msg,"error");
        })
        
    }

    const getRemarkList = () =>{
        setLoadingStatus(true);
        setRemarkList(null);
        counterCheckinServices.getRemarkList(uid).then(res=>{
            setRemarkList(res.data);
        }).catch(()=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }
    return (
        masters && 
        masters["remark-enquiry"] && masters["remark-enquiry"].length > 0 &&
        masters["remark-response"] && masters["remark-response"].length > 0 &&
        <>
        
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle> Select Remark  For UID : "{uid}"</DialogTitle>
                <DialogContent>
                    <div className='row my-2'>
                        <div className='col-md-12 mb-3'>
                            <Autocomplete
                                value={enquiryType}
                                onChange={(event, newValue) => {
                                    setEnquiryType(newValue);
                                }}
                                options={ masters['remark-enquiry']}
                                renderInput={(params) => <TextField {...params} label="Enquiry Type" />}
                            />
                        </div>

                        <div className='col-md-12 mb-3'>
                            <Autocomplete
                                value={responseType}
                                onChange={(event, newValue) => {
                                    setResponseType(newValue);
                                }}
                                options={ masters['remark-response']}
                                renderInput={(params) => <TextField {...params} label="Action Taken" />}
                            />
                        </div>

                        <div className='col-md-12 mb-3'>
                            <div className='form-floating'>
                                <input type="text"
                                    value={remark}
                                    className='form-control'
                                    onChange={handleRemarkInput}
                                />
                                <label>
                                    <span>Remark</span>
                                    {remark.length > 0 && <span className='bg-success text-white ms-1 p-1 rounded fw-bold'>{30-remark.length} / 30</span> }
                                </label>
                            </div>
                        </div>

                        
                        <div className='col-md-12 mt-3'>
                            {savingInProgres ?
                                <LinearProgress/>
                                :
                                <>
                                    <button className='btn btn-primary float-end' disabled={
                                        uid == "" || enquiryType == null || responseType == null
                                    } 
                                    onClick={submitRemark}>Save Remark</button>
                                    
                                    <button className='btn btn-outline-primary float-end me-3' onClick={()=>setDialogStatus(false)} >Cancel</button>
                                </>   
                            }
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

                <div className='row' >
                    <div className='col-md-12 px-3'>
                        {/*<button type='button' className='ms-3 btn btn-info' onClick={()=>setDialogStatus(true)}>Add Remark</button> */}
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Unique Id / Phone Number"
                                onChange={(e)=>setUid(e.target.value)} value={uid}
                                onKeyUp={ (e) =>{
                                    if(e.key === 'Enter') {
                                        e.preventDefault();
                                        getRemarkList();
                                    }
                                }}
                            />
                            <button className="btn btn-outline-secondary" type="button" onClick={getRemarkList}><Search/> Search</button>
                        </div>
                    </div>
                    <div className='col-md-12 px-3'>
                        {!loading && remarkList && 
                            <div className="row">
                                <div className='col-md-12 text-center'>
                                    <button type='button' className='ms-3 btn btn-outline-primary btn-lg' onClick={()=>setDialogStatus(true)}><Add/> Add New Remark</button>
                                </div>
                                <div className='col-md-12 mt-3'>
                                    { remarkList.length > 0 ?
                                        <Timeline1 remarkList={remarkList} />
                                        :
                                        <p>No Remark History Found</p>
                                    }
                                    
                                </div>
                            </div>
                        }
                        {loading &&
                            <div className="row">
                                <div className='col-md-12 text-center mt-3'>
                                    <CircularProgress/>
                                </div>
                            </div>
                        }
                    </div>
                </div>          
                


                
            
        </>
    )
}

export default TokenRemark