import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import adminDashboardServices from '../adminDashboardServices';
import CounterData from '../components/CounterData';
import QueueBarChart from '../components/QueueBarChart';
import QueuePieChart from '../components/QueuePieChart';
import StaffPieChart from '../components/StaffPieChart';

import DateSelect from '../../../../components/AppInput/DateSelect'

import withModuleSetting from '../../../../section/withModuleSetting'


function AdminDashboardPage() {

    const [filter,setFilter] = useState(null);
    const [dashboardData,setDashboardData] = useState(null);
    const getDashBoardData = ()=>{
        if(filter!==null ){
            adminDashboardServices.getDashBoardData(filter).then(res=>{
                setDashboardData(res.data);
            }).catch(error=>{
            })
        }
    }
    useEffect(()=>{
        getDashBoardData();
    },[filter])

    return (
        <AppLayout>
            <div className="container-fluid">
            <div className='row'>
                <div className='col-md-6'>
                </div>
                <div className='col-md-6'>
                    <span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
                </div>
            </div>
            {dashboardData && 
                <>
                    <CounterData dashboardData={dashboardData} />
                    <div className='row'>
                        <div className='col-md-4'>
                            <QueueBarChart dashboardData={dashboardData} />
                        </div>
                        <div className='col-md-4'>
                            <QueuePieChart dashboardData={dashboardData} />
                        </div>
                        <div className="col-md-4" >
                            <StaffPieChart dashboardData={dashboardData} />
                        </div>
                    </div>
                </>
            }
            </div>
        </AppLayout>
    )
}

export default withModuleSetting(AdminDashboardPage,"kioskTokenModuleEnabled",true)