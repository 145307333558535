import React,{useState,useEffect} from 'react'
import KioskHeader from '../../sections/KioskHeader'
import kioskInfoServices from '../kioskInfoServices';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';
import { Slide, Zoom } from '@mui/material';

import YouTube from 'react-youtube';
import CategoryListSideNav from '../components/CategoryListSideNav';
import KioskNav from '../../sections/KioskNav';

function KioskDeviceInfoListByCategoryPage() {

  const {categoryId} = useParams();
  const [infoList,setInfoList] = useState(null);
  const getInfoList = () =>{
    kioskInfoServices.getInfoListByCategoryId(categoryId).then(res=>{
      setInfoList(res.data);
    }).catch(error=>{

    })
  }

  useEffect(()=>{
    getInfoList();
  },[categoryId])
  
  const navigate = useNavigate();
    const handleClick = (id) => {
        navigate("/kiosk/info/details/"+id)
  }
  return (
    <div className='container'>
        <KioskHeader/>
        <div className='row'>
          <div className='col-md-8'>
              {infoList && infoList.map((infoDetails,i)=> 
                <Zoom in={true} mountOnEnter unmountOnExit
                  style={{ transitionDelay:  i+'00ms'  }}
                  key={infoDetails.id}
                >  
                  <div className='row shadow my-3 p-2' key={infoDetails.id}>
                    <div className='col-md-3 '>
                        {infoDetails.video !== "" &&
                            <div className='video-wrapper'>
                                <div className=''>
                                    {/*
                                    <iframe 
                                        title={infoDetails.title}
                                        src={"https://www.youtube.com/embed/"+infoDetails.video+"?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=1&modestbranding=0"} 
                                        frameborder="0" allowfullscreen>
                                    </iframe>
                                    */}
                                    <YouTube
                                        videoId={infoDetails.video}
                                        opts={{
                                            playerVars: {
                                                // https://developers.google.com/youtube/player_parameters
                                                autoplay: 0,
                                                mute:0,
                                                controls:0,
                                                rel:0,
                                                loop:1,
                                                showinfo:0,
                                            },
                                        }}
                                    />    
                                </div>
                                <div className='sub-content' onClick={()=>handleClick(infoDetails.id)}>
                                    <div className='d-flex justify-content-center'>
                                        <div style={{position:'absolute',top:"47%"}}>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {infoDetails.video === "" && infoDetails.image !== null && 
                            <img 
                                src={infoDetails.image} 
                                onClick={()=>handleClick(infoDetails.id)} 
                                className='img-thumbnail'
                                style={{cursor:"pointer"}} 
                            />
                        }

                    </div>
                    <div className='col-md-9' style={{cursor:"pointer"}} onClick={()=>handleClick(infoDetails.id)} >
                        {infoDetails.title !== "" && <strong>{infoDetails.title}</strong> }
                        {infoDetails.subTitle !== "" && <p>{infoDetails.subTitle}</p> }
                    </div>
                  </div>
                </Zoom>
              )}
          </div>
          <div className='col-md-4'>
            <CategoryListSideNav/>
          </div>
        </div>
            
      <KioskNav/>  
        
    </div>
  )
}

export default KioskDeviceInfoListByCategoryPage