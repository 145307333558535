import React, { useEffect, useState } from 'react'
import KioskHeader from '../../sections/KioskHeader'
import kioskInfoServices from '../kioskInfoServices';
import { Link } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';
import { Slide } from '@mui/material';
import KioskNav from '../../sections/KioskNav';

function KioskDeviceInfoCategoryListPage() {
  const [categoryList,setCategoryList] = useState(null);
  const getCategoryList = () =>{
    kioskInfoServices.getCategoryList().then(res=>{
      setCategoryList(res.data);
    }).catch(error=>{

    })
  }
  useEffect(()=>{
    getCategoryList();
  },[])
  return (
    <div className='container'>
        <KioskHeader/>
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className='row mt-3'>
              {categoryList?.map(category=>
                <div key={category.id} className='col-md-12 rounded p-3 mb-4  shadow'>
                  <Link
                    key={category.id} 
                    
                    aria-current="page" 
                    to={"/kiosk/info/category/"+category.id} 
                    style={{textDecoration:"none"}}
                    >
                      <div className="d-flex  justify-content-between text-dark">
                        <h3 className='align-self-center'>{category.title}</h3>
                        <ArrowRight sx={{fontSize:40}}/>
                      </div>
                  </Link>
                </div>
              )}  
          </div>
        </Slide>
        <KioskNav/>
    </div>
  )
}

export default KioskDeviceInfoCategoryListPage