import api from "../../api/api";

const getCheckinDisplayList = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/display/tv").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCheckinDisplayInfo = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/display/info").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getUserDDList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/gatepass/user/dd").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const displayPageServices = {
    getCheckinDisplayList,
    getCheckinDisplayInfo,
    getUserDDList,
}
export default displayPageServices;