import React, { useEffect, useState } from 'react'
import withQueueLayout from '../components/withQueueLayout'
import { useAlert } from '../../../../context/AlertContext'
import userServices from '../../user/userServices'
import { Autocomplete, Avatar, Chip, TextField } from '@mui/material';
import queueUpdateServices from '../queueUpdateServices';
import { Done, Person } from '@mui/icons-material';

function BusinessStaffPage({queueConfig,getQueueConfig}) {

    const {AppAlert} = useAlert();
    const [userDDList,setUserDDList] = useState(null);

    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');

    const getUserDDList = () => {
        userServices.getUserDDList().then(res=>{
            setUserDDList(res.data);
        })
    }
    useEffect(()=>{
        getUserDDList();
    },[])

    //  Update and remove staff Work 
    const assignStaff = () =>{
        let payload = {
            userId:value.value,
            queueId:queueConfig.queueDetails.id,
        }   
        queueUpdateServices.updateBusinessStaff(payload).then(res=>{
            getQueueConfig();
            AppAlert(res.msg);
            setValue("");
            setInputValue("")
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }


    const handleDelete = (id) =>{
        queueUpdateServices.removeStaff(id).then(res=>{
            getQueueConfig();
            AppAlert(res.msg);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    return (
        <div className='card'>
            <div className='card-header'>Staff</div>
            <div className='card-body'>
                
                <div className='row'>
                    <div className='col-md-12'>
                        {queueConfig.businessStaff.map(businessStaff=>
                            <Chip key={businessStaff.id} color="primary" className='mx-2' 
                                onDelete={()=>handleDelete(businessStaff.id)}  
                                label={businessStaff.firstName+" "+businessStaff.lastName+" - "+businessStaff.workLocation}
                                icon={<Person/>}
                             />
                        )}
                        {queueConfig.businessStaff.length == 0 && 
                            <h3>No Staff Assigned to this Queue</h3>
                        }
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-9'>
                        {userDDList &&
                            <Autocomplete
                                disablePortal
                                options={userDDList}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="Assign Staff"  />}
                            />
                        }
                    </div>
                    <div className='col-md-3'>
                        {value !== "" && <button type="button" className='btn btn-lg btn-outline-primary' onClick={assignStaff} >Add</button> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withQueueLayout(BusinessStaffPage)