import React,{useState,useEffect, useRef} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import adminReportServices from '../adminReportServices';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Download } from '@mui/icons-material';
import { Rating } from '@mui/material';

const CellRating = (params) =>{
  return (
    <Rating name="read-only" value={params.data.rating} readOnly />
  )
}
function FeedbackReportPage() {
  const [filter,setFilter] = useState(null);
  const [reportData,setReportData] = useState(null);
  const getReportData = ()=>{
    if(filter!==null){
        adminReportServices.getFeedbackReportData(filter).then(res=>{
            setReportData(res.data);
        }).catch(error=>{
      })
    }
  }

  useEffect(()=>{
      getReportData();
  },[filter])


  /* Grid Work */
  const gridRef = useRef();
  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:180,
    filter:true,
    sortable: true,
    cellStyle: { 'textTransform': 'uppercase' },
  });

  const [columnDefs,setColumnDef] = useState();
  const prepairColumnDef = () => {
      let _columnDefs = [
          {field:"rating",headerName: "Rating", cellRenderer: CellRating,width:180},
          {field:"feedback",headerName: "Feedback", width:300, editable: true,},
          {field:"visitorName",headerName: "Visitor Name"},
          {field:"visitorPhone",headerName: "Contact No", editable: true},
          {field:"created_at",headerName: "Date"},
      ];
      setColumnDef(_columnDefs);
  }

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv("data.csv");
  };

  useEffect(()=>{
      prepairColumnDef()
  },[])

  return (
    <AppLayout>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-6'>
                  <h3>Feedback Report</h3>
                </div>
                <div className='col-md-6'>
                      <span className='float-end'>
                        <DateSelect filter={filter} setFilter={setFilter} />
                        {reportData && <button type="button" onClick={onBtnExport} className="btn btn-primary ms-3 float-end"><Download/>  Export</button> }
                      </span>
                </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mt-3'>    
                <div className="ag-theme-alpine" style={{height:600}}>
                  <AgGridReact
                          ref={gridRef}
                          rowData={reportData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          animateRows={true}
                          onGridReady={getReportData}
                    >
                    </AgGridReact>
                  </div>
                </div>
              </div>
        </div>
    </AppLayout>
  )
}

export default FeedbackReportPage