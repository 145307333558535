
import React,{useEffect,useState} from 'react'
import AppLayout from '../layout/components/AppLayout'
import useBusinessInfo from '../hooks/useBusinessInfo'
import { Alert,AlertTitle } from '@mui/material'

const DisabledInfo = ({businessInfo,module}) =>{
    return(
        <div className='row'>
            <div className='col-md-12'>
                {businessInfo?.[module+"Enabled"] ? 
                    <Alert severity="error">
                        <AlertTitle>Disabled</AlertTitle>
                        This section is disabled by Admin
                    </Alert>
                    :
                    <Alert severity="error">
                        <AlertTitle>Disabled</AlertTitle>
                        You did not subscribed for this section
                    </Alert>
                }
                
            </div>
        </div>
    )
}

const withModuleSetting = (OriginalComponent,module,layout) =>(props)  =>{
    const businessInfo = useBusinessInfo();
    return (
        businessInfo?.[module] == 1 ? 
        <OriginalComponent/>
        :
        <>
        {layout ? 
            <AppLayout>
                <DisabledInfo businessInfo={businessInfo} module={module}/>
            </AppLayout>
            :
            <DisabledInfo businessInfo={businessInfo} module={module}/>

        }

        
        </>
    )
}

export default withModuleSetting