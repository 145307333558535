import React,{useState} from 'react'
import {CircularProgress, Switch } from '@mui/material';
import withQueueLayout from '../components/withQueueLayout'
import { useAlert } from '../../../../context/AlertContext';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import queueUpdateServices from '../queueUpdateServices';



const BusinessBreak = ({businessBreak,AppAlert}) =>{
    
  const [breakStart,setBreakStart] = useState(dayjs('2022-11-22T'+businessBreak.breakStart));
  const [breakEnd,setBreakEnd] = useState(dayjs('2022-11-22T'+businessBreak.breakEnd));
  const [breakStatus,setBreakStatus] = useState(businessBreak.breakStatus?true:false);

  const [touched,setTouched] = useState(false);
  const [savingInProgress, setSavingStatus] = useState(false);

  /*
      let yourDate = new Date();
      yourDate = yourDate.toISOString().split('T')[0];
      const [breakStart,setBreakStart] = useState(dayjs(yourDate+businessBreaks.breakStart));
  */
  const getWeekDayName = (dayNo) =>{
      let weekDays = ["","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
      return weekDays[dayNo];
  }

  const handleBreakStartChange = (newValue) =>{
      console.log(newValue.format("HH:mm:ss"));
      setBreakStart(newValue);
      setTouched(true)
  }

  const handleBreakEndChange = (newValue) =>{
      console.log(newValue.format("HH:mm:ss"));
      setBreakEnd(newValue);
      setTouched(true)
  }

  const handleBreakStatusChange = (event) =>{
      setBreakStatus(event.target.checked);
      setTouched(true)
  }    

  const update = ()=>{
      setSavingStatus(true);
      let payload = {
          id:businessBreak.id,
          breakStart:breakStart.format("HH:mm:ss"),
          breakEnd:breakEnd.format("HH:mm:ss"),
          breakStatus
      }
      queueUpdateServices.updateBusinessBreaks(payload).then(res=>{
          AppAlert(res.msg);
          setTouched(false);
      }).catch(error=>{
          AppAlert(error.msg,"error");
      }).finally(()=>{
          setSavingStatus(false);
      })
  }

  return (
      <tr>
          <th>{getWeekDayName(businessBreak.dayNo)}</th>
          <th><Switch checked={breakStatus} onChange={handleBreakStatusChange} name="Day Status" /></th>
          <td>
              <TimePicker
                  label="Time"
                  value={breakStart}
                  onChange={handleBreakStartChange}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
              />
          </td>
          <td>
              <TimePicker
                  label="Time"
                  value={breakEnd}
                  onChange={handleBreakEndChange}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
              />
          </td>
          <td>
              {touched && !savingInProgress && <button type="button" className="btn btn-success btn-sm" onClick={update}>Save</button>}
              {savingInProgress &&  <CircularProgress/> }
          </td>
      </tr>
  )
}



function BusinessHoursPage({queueConfig}) {
  const {AppAlert} =  useAlert();
  return (
    <div className="card">
      <div className='card-header'>Business Hours</div>
      <div className='card-body'>
        <div className="col-md-12 table-responsive">
          <p className="text-primary small">TimeZone: Asia/Calcutta</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <table className="table">
              <thead>
                  <tr>
                      <th></th>
                      <th></th>
                      <th>Start</th>
                      <th>End</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                  {queueConfig.businessBreaks.map(businessBreak=>
                    <BusinessBreak businessBreak={businessBreak} key={businessBreak.id} AppAlert={AppAlert}/>
                  )}
              </tbody>
          </table>
          </LocalizationProvider>
        </div>
      </div>
    </div>
    
  )
}

export default withQueueLayout(BusinessHoursPage)