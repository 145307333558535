import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import userServices from '../userServices';

function UserRoleListPage() {

  const [userRoleList,setUserRoleList] = useState();

  const getUserRoleList = () =>{
    userServices.getUserRoleDDList().then(res=>{
      setUserRoleList(res.data);
    })
  }
  
  useEffect(getUserRoleList,[])

  return (
    <AppLayout>
      <div className='container-fluid'>
        <table className='table'>
          <thead>
            <tr>
            <td>ID</td>
            <td>Role</td>
            </tr>
          </thead>
          <tbody>
            {userRoleList && userRoleList.map(userRole=>
              <tr>
                <td>{userRole.value}</td>
                <td>{userRole.label}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </AppLayout>
  )
}

export default UserRoleListPage