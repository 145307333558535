import React,{useState,useContext, useEffect} from 'react'
import io from 'socket.io-client'
const SocketContext = React.createContext();

export const useSocket = () => {
    return useContext(SocketContext);
}

/* for n2 server testing work start*/
/* these need to be updated in database notification url */
const portalIds = [
    "global-demo1","global-live", 
    "epf-tbtam",
    "epf-ldjal","epf-rjjod",
    "epf-knmys","epf-mragr", "epf-pupuna",
    "epf-dlw","epf-gnggn1","epf-tbvlr" ,"epf-tnamb","epf-bgbngm","epf-pybomk","epf-bgbngc"
]
/* for n2 server testing work end s*/



export const SocketProvider = (props) =>{

    const [appName,setAppName] = useState(null);
    const [socket,setSocket] =  useState(null)

    const getAppNameOld = () =>{
        let _appName =  "local"//process.env.REACT_APP_SOCKET_NAME;
        const api_url = localStorage.getItem("portalUrl");
        let a = document.createElement('a');
        a.href = api_url;
        _appName = a.hostname.replace("-api.ghospital.in","");
        _appName = "gtoken_"+_appName;
        setAppName(_appName);
        let _socket = io.connect("https://tms-notification.gtoken.in?app="+_appName);
        setSocket(_socket);
    }

    const getAppName = () =>{
        let _appName = "k-"+localStorage.getItem("portalId");
        /* for n2 server testing work start*/
        /* this section wil not required after updated in database and one time run */
        if(portalIds.includes(localStorage.getItem("portalId"))){
            console.log("need to update notification url")
            localStorage.setItem("notificationUrl","https://n2.gtoken.in/")
        }
        /* for n2 server testing work end */
        let notificationUrl = localStorage.getItem("notificationUrl");

        setAppName(_appName);
            //let _socket = io.connect("http://206.189.138.129:3001?app="+_appName,{
            let _socket = io.connect(notificationUrl+"?app="+_appName,{
            //   path: '/socket.io', transports: ['websocket'] 
            //transports: ['websocket'],
            //pingInterval: 1000 * 60 * 5,
            //pingTimeout: 1000 * 60 * 3
        });
        setSocket(_socket);
    }

    useEffect(()=>{
        getAppName()
    },[]);
    
    const value= {
        appName,
        socket,
    }

    return (
        <SocketContext.Provider value= {value}>
            {socket!==null && props.children}
        </SocketContext.Provider>
    )
}