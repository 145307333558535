import React, { useEffect, useState } from 'react'
import useBusinessInfo from '../../../../hooks/useBusinessInfo'
import { useNavigate } from 'react-router-dom';

function SuspendPage() {
    const businessInfo = useBusinessInfo();
    const navigate = useNavigate();
  
    useEffect(()=>{
      if(businessInfo?.accountStatus){
        navigate("/");
      }
    },[businessInfo])
  
    return (
        <div className='container'>
            <div className='row mt-5'>
            <div className='col-md-12 '>
                <div className='text-center text-danger'>
                <h3> Your account has been expired </h3>
                </div>
            </div>
            </div>
        </div>
    )
}

export default SuspendPage