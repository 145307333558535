import React, { useState,useEffect } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { useAlert } from '../../../../context/AlertContext';
import { useSocket } from '../../../../context/SocketContext';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { LinearProgress } from '@mui/material';
import GatepassImageCapture from '../components/GatepassImageCapture';
import gatepassVisitorServices from '../gatepassVisitorServices';


function GatepassGeneratePage() {
    const {AppAlert} = useAlert()
    const {socket} = useSocket();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState(null)
    const [formValidationRule, setFormValidationRule] = useState(null);
    const [profileImage,setProfileImage] = useState(null);
    const [savingInProgress,setSavingStatus] = useState(false);
    const [userDDList,setUserDDList] = useState(null);

    const getUserDDList = () => {
        gatepassVisitorServices.getUserDDList().then(res=>{
            setUserDDList(res.data);
        }).catch(error=>{

        })
    }
    useEffect(()=>{
        getUserDDList();
    },[])
    

    const idTypeList = [
        {label:"NA", value:"0"},
        {label:"Driving L", value:"1"},
        {label:"Aadhar card", value:"2"},
        {label:"Pan card", value:"3"},
        {label:"Employee Id", value:"4"},
        {label:"Voter Id", value:"5"},
        {label:"Passport Id", value:"6"},
    ]

    const hoursList = [
        {label:"1 Hour", value:"+1 hours"},
        {label:"2 Hours", value:"+2 hours"},
        {label:"3 Hours", value:"+3 hours"},
        {label:"4 Hours", value:"+4 hours"},
        {label:"5 Hours", value:"+5 hours"},
        {label:"6 Hours", value:"+6 hours"},
        {label:"1 Day", value:"+24 hours"},
        {label:"2 Days", value:"+2 Days"},
    ]


    const initialValues = {
        visitorName:"",
        gender:"",
        careOf:"",
        visitorAddress:"",
        email:"",
        phone:"",
        idType:"0",
        idProff:"",
        purpose:"",
        remark:"",
        attendantId:"",
        validateTill:"+2 hours"

    }

    const validationRule = Yup.object({
        visitorName:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        careOf:Yup.string().required("Required"),
        visitorAddress:Yup.string().required("Required"),
        //email:Yup.string().required("Required"),
        phone:Yup.string().required("Required").matches(/^[0-9]+$/, "Invalid Phone Number").min(10, 'Invalid Phone Number').max(10, 'Invalid Phone Number'),
        idType:Yup.string().required("Required"),
        idProff:Yup.string().when(['idType'],(idType,schema,val)=>{
            //console.log("idType is",idType[0]);
            if(idType[0] != 0){
                return Yup.string().required("Required");
            }
        }),
                        
        purpose:Yup.string().required("Required"),
        //remark:Yup.string().required("Required"),
        attendantId:Yup.string().required("Required"),
        validateTill:Yup.string().required("Required"),
    });


    
    const onSubmit = (values, onSubmitProps) => {
        if(profileImage !== null ){
            values.photo = profileImage;
        }
        setSavingStatus(true);
        gatepassVisitorServices.save(values).then(res=>{
          //console.log(res);
          navigate("/gatepass/details/"+res.id);
        }).catch(error=>{
          AppAlert(error.msg)
        }).finally(()=>{
            setSavingStatus(false)
        })
    }

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                    </div>
                </div>
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            console.log(formik);
                            return (
                                <Form>
                                    <div className="row p-3">
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                {userDDList &&
                                                    <FormikControl control="autoselect" formik={formik} 
                                                        label="To Meet" name="attendantId"  
                                                        options={userDDList} multiple={false} 
                                                        className="col-md-8 p-2"
                                                    />
                                                }
                                            </div>
                                        </div>
                                        
                                        {formik.values.attendantId !== "" &&
                                            <div className='col-md-3 text-center'>
                                                <div className='m-2 p-2 border'>
                                                    <GatepassImageCapture setProfileImage={setProfileImage}/>
                                                </div>
                                            </div>
                                        }
                                        
                                        {formik.values.attendantId !== "" &&
                                        <div className='col-md-9'>
                                            <div className='row mt-3'>
                                                
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Purpose of Meeting" name="purpose" 
                                                    className="col-md-12 mb-2 p-2" 
                                                />
                                                <div className='col-md-12'><h4>Visitor Details</h4></div>
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Visitor Name" name="visitorName" 
                                                    className="col-md-4 mb-2 p-2" 
                                                />
                                                <FormikControl control="autoselect" formik={formik} 
                                                    label="Gender" name="gender"  
                                                    options={[{value:1,label:"Male"},{value:2,label:"Female"}]} multiple={false} 
                                                    className="col-md-4 col-xs-6 p-2"
                                                />
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Care Of" name="careOf" 
                                                    className="col-md-4 mb-2 p-2" 
                                                />
                                                <div className='col-md-12 mt-3'><h4>Contact Details</h4></div>
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Phone" name="phone" 
                                                    className="col-md-3 p-2" 
                                                />
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Email" name="email" 
                                                    className="col-md-3 p-2" 
                                                />
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Address" name="visitorAddress" 
                                                    className="col-md-6 p-2" 
                                                />
                                                <div className='col-md-12 mt-3'><h4>ID Details</h4></div>
                                                <div className="col-md-12">
                                                    <div className="row mt-4">
                                                        <div className="col-md-6">
                                                            <FormikControl control="autoselect" formik={formik} 
                                                                label="ID Type" name="idType"  
                                                                options={idTypeList} multiple={false} 
                                                            />
                                                        </div>
                                                        {formik.values.idType != 0 &&
                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                label="Id Proff Details" name="idProff"
                                                                className="col-md-6"
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-3'><h4>Remarks  and Time </h4></div>
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Remark" name="remark" 
                                                    className="col-md-6 p-2" 
                                                />
                                                <div className="col-md-6">
                                                    <FormikControl control="autoselect" formik={formik} 
                                                        label="Gatepass will beValid For" name="validateTill"  
                                                        options={hoursList} multiple={false} 
                                                    />
                                                </div>

                                                
                                            </div>
                                        </div>
                                        }
                                        

                                        
                                        
                                    </div>
                                           
                                    <div className="row mt-3">
                                        <div className='col-md-12'>
                                            {savingInProgress ?
                                                <LinearProgress/>
                                                :
                                                <>
                                                    <button disabled={profileImage === null}  className="btn btn-success float-end" type="submit">Generate</button>
                                                    <button className="btn btn-outline-primary" type="button">Cancel</button>
                                                </>
                                            }
                                        </div>
                                        <div className='col-md-12'>
                                            { /* JSON.stringify(formik.values)  */}
                                        </div>
                                    </div>
                                    
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </AppLayout>
    )
}

export default GatepassGeneratePage