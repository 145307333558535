import api from "../../api/api";

const getBusinessInfo = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/display/business-info").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}





const displayDashboardServices = {
    getBusinessInfo
    
}
export default displayDashboardServices;