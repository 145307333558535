import React,{useState,useEffect} from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import faqServices from '../faqServices';
import FaqForm from '../components/FaqForm';
import { Switch } from '@mui/material';
import QRCode from 'react-qr-code';
import withModuleSetting from '../../../../section/withModuleSetting';

function ManageFaqPage() {
  const [faqList,setFaqList] = useState(null);
  const [searchResultList,setSearchResultList] = useState(null);
  const getFaqList = () =>{
    faqServices.getList().then(res=>{
      setFaqList(res.data)
      setSearchResultList(res.data)
    }).catch(error=>{
      console.log(error);
    })
  }
  useEffect(()=>{
    getFaqList()
  },[])

  const handleStatusChange = (e,id) =>{
    let payload = {
      id:id,
      status:e.target.checked ? 1 : 0,
    }
    console.log(payload);
    faqServices.save(payload).then(res=>{
      getFaqList();
    })
  }


  const [searchInput,setSearchInput] = useState("");

  const doSearch = ()=>{
    let _searchResultList = faqList.filter(faq => faq.question.toLowerCase().search(searchInput.toLowerCase()) !== -1 )
    setSearchResultList(_searchResultList)
  }

  const handleKeyUp = (e) =>{
    if(e.key === 'Enter') {
        e.preventDefault();
        doSearch();
    }
  }


  return (
    <>
      <div className='row'>
        <div className='col-md-9'>
          <h3>Faq List</h3>
        </div>
        <div className='col-md-3'>
          <span className='float-end'><FaqForm refreshList={getFaqList} /></span>
        </div>
      </div>
      {searchResultList &&
        <div className='row'>
          <div className='col-md-12'>
          
            <div className="input-group my-3">
              <input
                value={searchInput}
                onChange={(e)=>
                  setSearchInput(e.target.value)
                }
                onKeyUp={handleKeyUp}
                type="text" 
                className="form-control" 
                placeholder="Search" aria-label="Search" aria-describedby="buttonSearch" />
              <button className="btn btn-outline-primary" type="button" id="buttonSearch" onClick={doSearch}>Search</button>
            </div>
          </div>
          {searchResultList.map(faq=>
            <div className='col-md-12 mt-2'>
              <div className='card'>
                <div className='card-header'>
                  <div className="d-flex justify-content-between">
                    <div><strong>Question. </strong>{faq.question}</div>
                    <div>
                      <FaqForm refreshList={getFaqList} data={faq} />
                      <Switch 
                        color="success"
                        checked={faq.status === 1}
                        onChange={(e)=>handleStatusChange(e,faq.id)}
                      />
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <strong>Answer. </strong>{faq.answer}
                </div>
                {faq.link !== "" && 
                  <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-3'>
                          <strong>Link : </strong>
                        </div>
                        <div className='col-md-3'>
                          <a href={faq.link}>{faq.link}</a>
                        </div>
                        <div className='col-md-3'>
                          <h3>Scan QR code for more details : </h3>
                        
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={faq.link}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </div>
                  </div>
                }
              </div>
            </div>      
          )}
        </div>
      }
    </>
  )
}

export default withCMSLayout( withModuleSetting( ManageFaqPage,"kioskFaqModuleEnabled"))