import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import { useAlert } from '../../../../context/AlertContext';
import kioskInfoCategoryServices from '../kioskInfoCategoryServices';


function KioskInfoCategoryForm({data,refreshList }) {

    const {AppAlert} = useAlert();
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        title:"",
        details:"",
        link:"",
        video:"",
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            title:data.title,
            details:data.details,
            link:data.link,
            video:data.video,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const validationRule = Yup.object({
        title:Yup.string().required("Required"),
        details:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const onSubmit = (values, onSubmitProps) => {
        kioskInfoCategoryServices.save(values).then(res=>{
            AppAlert(res.msg);
            setDialogStatus(false);
            refreshList()
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }



    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Add New</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Add New"}</DialogTitle>
                                    <DialogContent>
                                            <div className="row p-3">
                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Title" name="title" 
                                                    className="col-md-12 col-xs-12 mb-2" 
                                                />

                                                <FormikControl control="input" formik={formik} type="text" multiline={true}
                                                    label="Details" name="details" 
                                                    className="col-md-12 col-xs-12 mb-2" 
                                                />

                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Link" name="link" 
                                                    className="col-md-6 col-xs-12 mb-2" 
                                                />

                                                <FormikControl control="input" formik={formik} type="text" 
                                                    label="Video Url" name="video" 
                                                    className="col-md-6 col-xs-12 mb-2" 
                                                />

                                            </div>
                                           
                                        <div className="row mt-3">
                                            <div className='col-md-12'>
                                                {savingInProgress ?
                                                    <LinearProgress/>
                                                    :
                                                    <>
                                                        <button className="btn btn-primary float-end" type="submit">Save</button>
                                                        <button className="btn btn-outline-primary" type="button" onClick={handleClose}>Cancel</button>
                                                    </>
                                                }
                                            </div>
                                            <div className='col-md-12'>
                                                { /* JSON.stringify(formik.values) */ }
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>

        </>
    )
}

export default KioskInfoCategoryForm