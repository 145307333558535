import React,{useEffect, useState} from 'react'
import AppSelect from '../../../components/AppInput/AppSelect'


function CounterDisplay({userDDList,announceToken}) {

    const [selectedCounter,setSelectedCounter] = useState(null)
    const [counterToken, setCounterToken] = useState(null);

    useEffect(()=>{
        if(selectedCounter && selectedCounter.workLocation == announceToken.workLocation){
            setCounterToken(announceToken);
        }
    },[announceToken])

  return (
    <div className='row'>
            {userDDList && selectedCounter == null && 
            <div className='col-md-12'>
                <AppSelect
                    options={userDDList}
                    value={selectedCounter}
                    setValue={setSelectedCounter}
                    label="Select Counter" 
                    optionLabel = "label"
                />
            </div>
            }
        
            {selectedCounter !== null && 
                <div className='col-md-12 bg-dark text-white p-3 text-center'>
                    <h2>{selectedCounter.firstName}</h2>
                </div>
            }
            {selectedCounter !== null && counterToken &&
                <>
                    <div className='col-md-12 bg-danger text-white p-3 text-center'>
                        <h2>{counterToken.tokenPrefix}-{counterToken.tokenNo}</h2>
                    </div>
                    <div className='col-md-12  p-3 text-center'>
                        <h2>{counterToken.firstName === "" ? "-" : counterToken.firstName}</h2>
                    </div>
                </>
            }
        

    </div>
    
  )
}

export default CounterDisplay