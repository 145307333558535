import React from 'react'
import RightAdsComponent from './RightAdsComponent';
import BottomNewsComponent from './BottomNewsComponent';
import FullPageVideoComponent from './FullPageVideoComponent';
import DisplayHeader from './DisplayHeader';

function DisplayVideoAdsOnly({displayInfo}) {
    return (
        
            
            
            
                <>
                    {displayInfo?.videoAdsList.length > 0 ? 
                        <FullPageVideoComponent displayInfo={displayInfo} />
                        :
                        <div className= {'col-md-4 offset-md-4'} >
                            {displayInfo && <RightAdsComponent displayInfo={displayInfo} /> }
                        </div>
                    }
                </>
                
            
            
        
    )
}

export default DisplayVideoAdsOnly