import api from "../../../api/api";

const getBusinessInfo = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/kiosk-device/info/business-info").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCategoryList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/kiosk-device/info/category").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getInfoListByCategoryId = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/kiosk-device/info/category/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getInfoDetailsById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/kiosk-device/info/details/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const kioskInfoServices = {
    getBusinessInfo,
    getCategoryList,
    getInfoListByCategoryId,
    getInfoDetailsById
    
}
export default kioskInfoServices;