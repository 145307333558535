import React from 'react'
import RightAdsComponent from './RightAdsComponent';
import BottomNewsComponent from './BottomNewsComponent';
import FullPageVideoComponent from './FullPageVideoComponent';
import DisplayHeader from './DisplayHeader';

function DisplayBannersAdsAndNews({displayList,displayInfo}) {
    return (
        <div className='row'>
            
            <DisplayHeader />
                
            
            
            
                <div className= {'offset-md-4 col-md-4'} >
                    {displayInfo && <RightAdsComponent displayInfo={displayInfo} /> }
                </div>
               
            
            
                <BottomNewsComponent displayInfo={displayInfo} />
            
        </div>
    )
}

export default DisplayBannersAdsAndNews