import React,{useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import displayPageServices from '../displayPageServices';
import DisplayWithWaitingList from '../components/DisplayWithWaitingList';
import { useSocket } from '../../../context/SocketContext';
import AnnounceToken from '../components/AnnounceToken';
import DisplayCurrentToken from '../components/DisplayCurrentToken';
import DisplayVideoAdsOnly from '../components/DisplayVideoAdsOnly';
import DisplayBannersAdsAndNews from '../components/DisplayBannersAdsAndNews';
import MiniDisplay from '../components/MiniDisplay';
import DualCounterDisplay from '../components/DualCounterDisplay';


function DisplayPage() {
    const {displayType} = useParams();
    const {socket} = useSocket();

    const [displayList,setDisplayList] = useState(null);
    const [displayInfo,setDisplayInfo] = useState(null);

    const getCheckinDisplayList = () => {
        if(displayType ==='waiting-list' || displayType ==='current-token' ){
            displayPageServices.getCheckinDisplayList().then(res=>{
                setDisplayList(res.data);
            }).catch(error=>{
            })
        }
        
    }

    const getCheckinDisplayInfo = () => {
        displayPageServices.getCheckinDisplayInfo().then(res=>{
            setDisplayInfo(res.data);
        }).catch(error=>{
        })
    }
    useEffect(()=>{
        getCheckinDisplayList();
        getCheckinDisplayInfo();
    },[])

    // socket events ----->
    const [announceTokenId,setAnnounceTokenId] = useState(0);
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_announcement",(data)=>{
            getCheckinDisplayList();
            //console.log("announcement Received",data);
            setAnnounceTokenId(data.token)
        })
        return () => socket.off('receive_announcement');
    },[socket])

    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_new_token",(data)=>{
            getCheckinDisplayList();
            //console.log("new token Received",data);
            setAnnounceTokenId(0)
        })
        return () => socket.off('receive_new_token');
    },[socket])

    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_close_token",(data)=>{
            getCheckinDisplayList();
            //console.log("close received",data);
            setAnnounceTokenId(0)
        })
        return () => socket.off('receive_close_token');
    },[socket])
    // <----- socket events 

    const [midSpeech,setMidSpeech] = useState("");
    const [postSpeech,setPostSpeech] = useState("");
    const getSpeechConfig = () =>{
        console.log("getting Speach config")
        let _midSpeech = localStorage.getItem("midSpeech");
        let _postSpeech = localStorage.getItem("postSpeech");
        localStorage.setItem("midSpeech",_midSpeech||"")
        localStorage.setItem("postSpeech",_postSpeech||"")
        setMidSpeech(_midSpeech||"")
        setPostSpeech(_postSpeech||" से संपर्क करे")
    }
    useEffect(()=>{
        getSpeechConfig();
    },[])

    return (
        <>
            { /* sessionStorage.getItem('voiceIndex') !== null && <AnnounceToken announceToken={announceTokenId} />  */}
            { (displayType ==='waiting-list' || displayType ==='current-token') &&
                
                <AnnounceToken announceToken={announceTokenId} midSpeech={midSpeech} postSpeech={postSpeech} /> 
            }
            {displayType==='waiting-list' && displayInfo && displayList && <DisplayWithWaitingList displayList={displayList}  displayInfo={displayInfo} /> }
            {displayType==='current-token' && displayInfo && displayList && <DisplayCurrentToken displayList={displayList}  displayInfo={displayInfo} /> }
            {displayType==='mini-display' && displayInfo  && <MiniDisplay announceToken={announceTokenId}  displayInfo={displayInfo} /> }
            {displayType==='dual-display' && displayInfo  && <DualCounterDisplay announceToken={announceTokenId}  displayInfo={displayInfo} /> }
            {displayType==='video-ads' && displayInfo && <DisplayVideoAdsOnly  displayInfo={displayInfo} /> }
            {displayType==='banner-ads' && displayInfo && <DisplayBannersAdsAndNews  displayInfo={displayInfo} /> }
        </>
        
    )
}

export default DisplayPage