import React, { useState, useEffect } from "react";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartBackGroundColors } from "../ChartColors";
ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

const options = {
    responsive: true,
    //indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
        legend: {
        position: "left",
        display: false
        },
    },
    /*
    scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    */
};




function RemarkBarChart({remarkData}) {

    
    const [dataLabels,setDataLables] = useState(null)
    const [dataSets,setDataSets] = useState(null)
    const data = {
        labels:dataLabels,
        indexAxis: 'y',
        datasets: [
            {
                /*label: 'waiting',*/
                data: dataSets,
                backgroundColor: ChartBackGroundColors,
            },
             
        ],
    };


    const prepareChartData = () =>{
        if(remarkData!== null && remarkData.length > 0){
            let _dataLabels = [];
            let _dataSets = []
            remarkData.forEach((enquiry,i) => {
                _dataLabels.push(enquiry.title);
                _dataSets.push(enquiry.count);
            });
            setDataLables(_dataLabels)
            setDataSets(_dataSets)
        }
    }
    
    useEffect(()=>{ 
       prepareChartData()
    },[remarkData])

    return (
      <>
          {remarkData.length > 0 && <Bar  options={options} data={data} /> }
      </>
    )
}

export default RemarkBarChart
