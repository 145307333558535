import React, { useEffect, useRef, useState } from 'react'
import gatepassVisitorServices from '../gatepassVisitorServices'
import { Link, useParams } from 'react-router-dom'
import { PersonAdd, Print } from '@mui/icons-material';
import AppLayout from '../../../../layout/components/AppLayout';
import QRCode from 'react-qr-code';
import moment from 'moment';
import { useAuth } from '../../../../context/AuthContext';
import useBusinessInfo from '../../../../hooks/useBusinessInfo';
import { useReactToPrint } from 'react-to-print';


const LabelValue = ({label, value}) =>{
    return(
        <div className='row'>
            <div className='col-4'><span className='fw-bold'>{label}</span></div>
            <div className='col-8'>{value}</div>
        </div>
    )
}

function GatepassDetailsPage() {

    const {id} = useParams();
    const [gatepass,setGatepass] = useState(null)
    const {authUser } = useAuth();
    const businessInfo = useBusinessInfo();
    const getGatepass = () =>{
        gatepassVisitorServices.getById(id).then(res=>{
            setGatepass(res.data);
        }).catch(error=>{

        })
    }

    useEffect(()=>{
        getGatepass()
    },[]);

    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        pageStyle:"@page { size: 8.26in 5.82in; margin: 0; padding:0 }, @media print { @page {  size:8.26in 5.82in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }",
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
        //onAfterPrint:()=>{navigate("/kiosk/dashboard")}
    });

    return (
        <AppLayout>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <button className='btn btn-outline-primary float-end' onClick={handlePrint}><Print/> Print</button>
                        <Link to={"/gatepass/generate"} className='btn btn-primary float-end me-4'><PersonAdd/> Generate New Pass</Link>
                    </div>
                </div>
                {gatepass && businessInfo &&
                    <div className='row mt-3' ref={printComponentRef}>
                        <div className='col-4 text-center my-2'>
                            {businessInfo.logo !== null ?
                                <img src={businessInfo.logo} style={{maxHeight:30, width:"auto"}} />
                                :
                                <h3>{businessInfo.title}</h3>
                            }
                            <div>Visitor Pass</div>
                        </div>
                        <div className='col-8 '>
                            <div className='float-end me-2'>Valid Duration : 
                                <strong> {moment(gatepass.inTime).format("lll")}</strong> to <strong>{moment(gatepass.validateTill).format("lll")} </strong>
                            </div>
                            <div className='float-end me-2'>
                                Printed on {moment().format("lll")}
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='text-center'>
                                <img className='img-fluid img-thumbnail' src={gatepass.photo === null ? "/images/blank-profile.png"  :gatepass.photo } style={{width:150,height:150}} />
                            </div>
                            <div className='text-center mt-3'>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100px", width: "100%" }}
                                    value={JSON.stringify({
                                        visitorName:gatepass.visitorName,
                                        id:gatepass.id
                                    })}
                                    fgColor="#800080"
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className='text-center mt-3'>
                                
                            </div>
                        </div>
                        <div className='col-9'>
                            <div className='row'>
                                <div className='col-12'><h5>Visitor Details</h5></div>
                                <div className='col-6'>
                                    <LabelValue label={"Reg No : "} value={gatepass.id} />
                                    <LabelValue label={"Name"} value={gatepass.visitorName} />
                                    <LabelValue label={"Care Of"} value={gatepass.careOf} />
                                    <LabelValue label={"Gender"} value={gatepass.gender === 1 ? "Male" : "Female" } />
                                </div>
                                <div className='col-6'>
                                    <LabelValue label={"Phone No"} value={gatepass.phone} />
                                    <LabelValue label={"Email"} value={gatepass.email} />
                                    <LabelValue label={"Address"} value={gatepass.visitorAddress} />
                                </div>
                                <div className='col-12'><h5 className='mt-3'>To Meet</h5></div>
                                <div className='col-8'>
                                    <LabelValue label={"Officer Name"} value={gatepass.firstName} />
                                    <LabelValue label={"Purpose"} value={gatepass.purpose} />
                                </div>
                                <div className='col-4'>
                                    <LabelValue label={"Location"} value={gatepass.workLocation} />
                                </div>
                                <div className='col-12'><h5 className='mt-3'>Remark</h5></div>
                                <div className='col-8'>
                                    <div className='col-12'>{gatepass.remark}</div>
                                    <LabelValue label={"Signature, Officer Visited"} value={""} />
                                </div>
                                <div className='col-4 text-center'>
                                        <h5>Generated By</h5>
                                        <div>{authUser.firstName} {authUser.lastName}</div>
                                        <div>{businessInfo.title}</div>
                                        {moment(gatepass.inTime).format("LL")}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default GatepassDetailsPage