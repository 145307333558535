import React, { useState,useEffect } from 'react'
import displayDashboardServices from '../displayDashboardServices';


function DisplayHeader() {
    const [businessInfo,setBusineeInfo] = useState(null);

    const getBusinessInfo = () =>{
        let _businessInfo  =  JSON.parse(sessionStorage.getItem("businessInfo"));
        
        if(_businessInfo == null){
            console.log("get business info")
            displayDashboardServices.getBusinessInfo().then(res=>{
                setBusineeInfo(res.data);
                sessionStorage.setItem("businessInfo",JSON.stringify(res.data))
            })
        } else {
            setBusineeInfo(_businessInfo);
        }
        
    }

    useEffect(()=>{
        getBusinessInfo();
    },[])

    return (
        <div className='row'>
            {businessInfo && 
                <div className='col-md-12 m-2 text-center'>
                    {businessInfo.logo !== null ?
                        <img src={businessInfo.logo} style={{maxHeight:80, width:"auto"}} />
                        :
                        <h3>{businessInfo.title}</h3>
                    }
                    
                </div>
            }
        </div>
    )
}

export default DisplayHeader