import React from 'react'
import RelatedInfo from './RelatedInfo'
import QRCode from 'react-qr-code'

function InfoWithImage({infoDetails}) {
  return (
    <div className='row'>
        <div className='col-md-8'>
            {infoDetails.image !== null && <img src={infoDetails.image} className='img-thumbnail' />}
            {infoDetails.title !== "" && <h4>{infoDetails.title}</h4> }
            {infoDetails.details !== "" && <p style={{whiteSpace: 'pre-line'}}>{infoDetails.details}</p> }
        </div>
        <div className='col-md-4'>
            {infoDetails.subTitle !=="" && <h3>{infoDetails.subTitle}</h3> }
            {infoDetails.link !== null && infoDetails.link !== "" &&
              <div className='row'>
                <div className='col-md-12 p-5'>
                  <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={infoDetails.link}
                      viewBox={`0 0 256 256`}
                  />
                </div>
                <div className='col-md-12 text-center'>
                  Scan For More Details
                </div>
              </div>
            }
            <RelatedInfo categoryId={infoDetails.categoryId} />
        </div>
    </div>
  )
}

export default InfoWithImage


