import React,{useState,useEffect} from 'react'
import KioskHeader from '../../sections/KioskHeader'
import KioskNav from '../../sections/KioskNav'
import kioskDirectoryServices from '../kioskDirectoryServices';

function KioskDeviceDriectoryPage() {


  const [directoryList,setDirectoryList] = useState(null);
  const [floorList,setFloorList] = useState(null);
  
    const getDirectoryList = () =>{
        kioskDirectoryServices.getList().then(res=>{
        setDirectoryList(res.data.directoryList)
        setFloorList(res.data.floorList)
        }).catch(error=>{
        console.log(error);
        })
    }
    useEffect(()=>{
        getDirectoryList()
    },[])

    return (
      <div className='container'>
          <KioskHeader/>
              {floorList && directoryList &&
                <>
                    {floorList.map(floor =>
                      <div className='card mb-2' key={floor.floor}>
                        <div className='card-header bg-primary text-white'>
                          {floor.floor == 0 ? "Ground Floor " : "Floor No : "+floor.floor}
                        </div>
                        <div className='card-body'>
                          {directoryList.map(directory => directory.floor == floor.floor && 
                            <div className='row'>
                              <div className='col-md-6'>
                                {directory.location}
                              </div>
                              <div className='col-md-6'>
                                {directory.department}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  
                </>
              }
          <KioskNav/>
      </div>
    )
}

export default KioskDeviceDriectoryPage