import React,{useState} from 'react'
import { Dialog} from '@mui/material';

import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css";
import '../FormikControl/keyboard.css'
import { KeyboardBackspace } from '@mui/icons-material';

// Alpha Numaric Keyboard
const alphaNumaric = {
    default: [
        "1 2 3 4 5 6 7 8 9 0 - {backspace}",
        "q w e r t y u i o p",
        "a s d f g h j k l",
        '{shift} z x c v b n m . "',
        "{space} {enter}"
    ],
    shift: [
        "! @ # $ % ^ & * ( ) _ {backspace}",
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "{shift} Z X C V B N M , '",
        "{space} {enter}"
    ], 
}
// Numpad Keyboard
const numpad = {
    default: [
        "1 2 3",
        "4 5 6",
        "7 8 9",
        "{backspace} 0 {enter}"
    ],    
}

const keyDisplay = {
    "{space}" : " ",
    "{numbers}": "123",
    "{enter}": "➡", //✓ ↵ ✔ ➡
    "{escape}": "esc ⎋",
    "{tab}": "tab ⇥",
    "{backspace}": "←", //⌫
    "{capslock}": "caps lock ⇪",
    "{shift}": "⇧",
    "{controlleft}": "ctrl ⌃",
    "{controlright}": "ctrl ⌃",
    "{altleft}": "alt ⌥",
    "{altright}": "alt ⌥",
    "{metaleft}": "cmd ⌘",
    "{metaright}": "cmd ⌘",
    "{abc}": "ABC"
}


function AppKeyboardInput({label,inputValue,setInputValue,type,onSuccess}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [layoutName, setLayoutName] = useState('shift')

    const openKeyboard = () =>{
        setInputValue(inputValue);
        setDialogStatus(true);
    }

    const handleShift = () =>{
        layoutName == "default" ? setLayoutName("shift") : setLayoutName("default")
    }

    const handleInput = (button) =>{
        let val  = inputValue === undefined ?"":inputValue ;
        if(button === "{backspace}"){
            val = val.substring(0, val.length - 1);
        } else if(button === "{space}"){
            val = val + " ";
        }else{
            val = val+button;
        }
        setInputValue(val);
        setLayoutName("default")
    }

    return (
        <>
            <div  className={'border text-black rounded'} onClick={openKeyboard}>
                <div className='card-body p-2' >
                    <div className={'text-muted'}><small>{label}</small></div>
                    <div style={{minHeight:20}}>{inputValue}</div>
                </div>
            </div>
            <Dialog
                open={dialogStatus}
                keepMounted
                maxWidth="lg" 
                fullWidth={true}
                style={{backgroundColor:'rgba(255,255,255,0.5)'}}
            >
                <div className='card'>
                    <div className='card-body'>
                        <div className='text-dark fw-bold'>{label}</div>
                        
                        <div class="d-flex">
                            <h3 style={{minHeight:50}} className={'border text-black p-2 rounded flex-grow-1'}> {inputValue}<span className='mycur'>_</span> </h3>
                            <div><button className='btn m-2 btn-primary' onClick={()=>setInputValue("")}><KeyboardBackspace/> Clear All</button></div>
                        </div>
                    </div>
                    <div className=''>
                        <Keyboard
                            layoutName={layoutName}
                            layout={type=="number"?numpad:alphaNumaric}
                            display={keyDisplay}
                            onKeyPress={(button)=>{
                                switch(button){
                                    case "{enter}":
                                        onSuccess !== undefined && onSuccess() ;
                                        setDialogStatus(false)
                                        break;
                                    case "{shift}":
                                    case "{lock}":
                                        handleShift()
                                        break
                                    default:
                                        handleInput(button)
                                }
                            }}
                            buttonTheme={[
                                {
                                class: "hg-green",
                                buttons: "{enter}"
                                },
                                {
                                class: "hg-red",
                                buttons: "{backspace}"
                                },
                            ]}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AppKeyboardInput