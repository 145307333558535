import api from "../../../api/api";

const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/queue").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getQueueConfig = (queueId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/queue/config/"+queueId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const clone = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/queue/duplicate",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const queueServices = {
    getList,
    getQueueConfig,
    clone
}
export default queueServices;