import React, { useEffect } from 'react'

function AnnounceToken({announceToken,midSpeech,postSpeech}) {
    
    const prefixLang =(prefix) =>{
        return prefix.split('').join(', ');   
    }
    const speak = (text) => {
        console.log("here", text);
        try{
            var audio = new Audio("/notification.mp3")
            audio.play();
        } catch(error){
            console.log("")
        }

        
        let voiceIndex = sessionStorage.getItem('voiceIndex')
        if(voiceIndex !== null){
            var utterance = new SpeechSynthesisUtterance();
            utterance.text = text;
            utterance.voice = speechSynthesis.getVoices()[voiceIndex];
            setTimeout(()=>{
                speechSynthesis.speak(utterance);
            },1500)
        }
        
    }
    
    useEffect(()=>{
        if(announceToken !== 0){
            let newAnnouncement = "";
            //newAnnouncement = "Token Number "+prefixLang(announceToken.tokenPrefix)+", "+announceToken.tokenNo+" , "+announceToken.workLocation+ ", से संपर्क करे";
            newAnnouncement = "Token Number "+prefixLang(announceToken.tokenPrefix)+", "+announceToken.tokenNo+" , "+midSpeech+" "+announceToken.workLocation+ ", "+postSpeech;
            speak(newAnnouncement);
        }
        
    },[announceToken]);
    return (
        <div></div>
    )
}

export default AnnounceToken