import api from "../../../api/api"


const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}



const getRemarkDashBoardData = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/remark/dashboard?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}




const remarkDashboardServices = {
    getRemarkDashBoardData,
}

export default remarkDashboardServices;
