import React from 'react'
import { Route, Routes } from 'react-router-dom'

// Display
import DisplayPage from '../app/display/pages/DisplayPage'
import DisplayDashboardPage from '../app/display/pages/DisplayDashboardPage'
import { useAuth } from '../context/AuthContext'

function DisplayRoutes() {

    const {authUser} = useAuth();

    return (
        <Routes>
            {/* Display */}
            {authUser.role == 4  && <Route path="/" element={<DisplayDashboardPage /> }></Route> }
            <Route path="/display/dashboard" element={<DisplayDashboardPage /> }></Route>
            <Route path="/display/tv/:displayType" element={<DisplayPage /> }></Route>
        </Routes>
        
    )
}

export default DisplayRoutes