import React from 'react'
import { Circle, FiberManualRecord } from '@mui/icons-material'

function BottomNewsComponent({displayInfo}) {
  return (

    displayInfo?.newsList.length > 0 &&
        <div style={{position:"fixed", bottom:0,left:0, backgroundColor:"#300", width:"100%", height:"80px"}} >
            <div className="row" style={{height:"80px"}}>
                <div className="col-3 bg-info shadow text-center pt-4 shadow ">
                    <h4>{displayInfo?.portalDetails.title} </h4>
                </div>

                <div  className="col-9 bg-dark text-white shadow  pt-4 ps-0 shadow ">
                    <marquee> 
                        {displayInfo?.newsList.map(news=>
                            <span key={news.id} className="me-2" style={{fontSize:30,fontWeight:"bold"}}>{news.details} <FiberManualRecord  fontSize="small"/></span>
                        )}
                    </marquee>
                </div>
            </div>
        </div>
    

  )
}

export default BottomNewsComponent