import api from "../../../api/api"


const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}



const getReportData = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/admin/token-report?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getFeedbackReportData = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/admin/feedback-report?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getRemarkReportData = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/admin/remark-report?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const getRemarkById = (uid) =>{
    return new Promise((resolve, reject)=>{
        api.get("report/admin/remark/"+uid).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}



const adminReportServices = {
    getReportData,
    getFeedbackReportData,
    getRemarkReportData,
    getRemarkById,
}

export default adminReportServices;
