import React,{useState} from 'react'
import KioskHeader from '../../sections/KioskHeader'
import { useAlert } from '../../../../context/AlertContext'
import { Step, StepLabel, Stepper, StepContent } from '@mui/material';
import QueueSelectComponent from '../components/QueueSelectComponent';
import { useEffect } from 'react';
import ServiceSelectComponent from '../components/ServiceSelectComponent';
import CustomerFormComponent from '../components/CustomerFormComponent';
import KioskNav from '../../sections/KioskNav';
import { ArrowBack } from '@mui/icons-material';

function KioskCheckinPage() {

    const {AppAlert}  = useAlert()
    const [activeStep,setActiveStep] = useState(0);
    const [selectedQueue,setSelectedQueue] = useState(null);
    const [selectedService,setSelectedService] = useState(null);

    return (
        <div className='container'>
            <KioskHeader/>
            <div className='row'>
                <div className='col-md-12'>
                    <Stepper activeStep={activeStep} orientation='vertical'>
                        <Step>
                            <StepLabel onClick={()=>{setActiveStep(0)}}>{selectedQueue? selectedQueue.title : "Select Queue"}</StepLabel>
                            <StepContent>
                                <QueueSelectComponent 
                                    selectedQueue={selectedQueue} 
                                    setSelectedQueue={setSelectedQueue} 
                                    setActiveStep={setActiveStep} 
                                />
                            </StepContent>
                        </Step>

                        <Step>
                            <StepLabel>{selectedService? selectedService.title : "Select Service"}</StepLabel>
                            <StepContent>
                                {selectedQueue &&
                                    <ServiceSelectComponent 
                                        selectedQueueId={selectedQueue.id} 
                                        selectedService={selectedService} 
                                        setSelectedService={setSelectedService}
                                        setActiveStep={setActiveStep}
                                    />
                                }
                                <button className='btn btn-lg btn-outline-primary mt-2'
                                    onClick={()=>{
                                        setActiveStep(0);
                                    }}
                                >
                                    <ArrowBack/> Back 
                                </button>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Visitor Details</StepLabel>
                            <StepContent>
                                {selectedQueue && activeStep===2 && 
                                    <CustomerFormComponent 
                                        queueId={selectedQueue.id} 
                                        serviceId={selectedService.id} 
                                    />
                                }
                                <button className='btn btn-lg btn-outline-primary mt-2'
                                    onClick={()=>{
                                        setActiveStep(1);
                                    }}
                                >
                                    <ArrowBack/> Back 
                                </button>
                            </StepContent>
                        </Step>
                    </Stepper>
                </div>
            </div>
            <KioskNav/>                
        </div>
        
    )
}

export default KioskCheckinPage