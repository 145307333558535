import React,{useState,useEffect} from 'react'
import YouTube from 'react-youtube';
import { PlayArrow } from '@mui/icons-material';
import { Dialog, DialogContent, Slider } from '@mui/material';
import RelatedInfo from './RelatedInfo';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';

function InfoWithVideo({infoDetails}) {

    const navigate = useNavigate();
    const [dialogStatus,setDialogStatus] = useState(false);

    const [myVideo,setMyVideo] = useState(null);
    const [seekValue,setSeekValue] = useState(0);
    const [maxValue,setMaxValue] = useState(0);
    const [playerState,setPlayerState] = useState(-1)
    function onReady(event) {
        //event.target.playVideo();  
        setMaxValue(event.target.getDuration());
        console.log(event.target);
        setMyVideo(event.target);
    }

    const toggleVideo  = ()=>{
        if(myVideo.getPlayerState() == 1){
            myVideo.pauseVideo();
        } else {
            myVideo.playVideo()
        }   
    }

    const onStateChange =(event) =>{
        //setSeekValue(myVideo.getCurrentTime() )
        setPlayerState(myVideo.getPlayerState())
    }

    useEffect(()=>{
        let timer = setInterval(()=>{
            console.log("running ")
            setSeekValue(myVideo?.getCurrentTime())
        },1000)
        return () => {
            clearInterval(timer);
        }        
    },[myVideo])

    const handleChange = (e) =>{
        //console.log(e.target.value)
        //setSeekValue(e.target.value);
        myVideo.seekTo(e.target.value);
    }

    const handleClose = () =>{
        setDialogStatus(false);
        navigate("/kiosk/dashboard");
    } 

    const handleOnEnd = () =>{
        setDialogStatus(true)
    }

    const handleYes = () =>{
        setDialogStatus(false);
        myVideo.seekTo(0);
        myVideo.playVideo();
    }

    return (
        <div className='row'>
            <div className='col-md-12 bg-light py-2 rounded'>
                {infoDetails.title !== "" && <h4>{infoDetails.title}</h4> }
                    <div className='video-wrapper'>
                        <div className=''>
                            {/*
                            <iframe 
                                title={infoDetails.title}
                                src={"https://www.youtube.com/embed/"+infoDetails.video+"?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=1&modestbranding=0"} 
                                frameborder="0" allowfullscreen>
                            </iframe>
                            */}
                            <YouTube
                                videoId={infoDetails.video}
                                opts={{
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 1,
                                        mute:0,
                                        controls:0,
                                        rel:0,
                                        loop:1,
                                        showinfo:0,
                                    },
                                }}
                                onReady={onReady}
                                onStateChange={onStateChange}
                                onEnd={handleOnEnd}
                            />    
                        </div>
                        <div className='sub-content' onClick={toggleVideo}>
                                <div className='d-flex justify-content-center'>
                                    <div style={{position:'absolute',top:"47%"}}>
                                        
                                    </div>
                                </div>
                        </div>
                    </div>
                
                <Slider 
                    defaultValue={0}
                    value={seekValue}
                    min={0}
                    max={maxValue}
                    onChange={handleChange}
                    size={"medium"}
                    color="error"
                />
                {/*
                <div className='d-flex justify-content-center'>
                    <h3>Max Value {maxValue}   </h3>
                    <h3>Current Value : {seekValue}</h3>
                </div>
                */}
                {infoDetails.details !== "" && <p style={{whiteSpace: 'pre-line'}}>{infoDetails.details}</p> }
                
            </div>
            <div className='col-md-12'>
                <div className='row'>
                    {infoDetails.image !== null && infoDetails.image !== "" &&
                        <div className='col-md-5'>
                            <img src={infoDetails.image} className='img-thumbnail' />
                        </div>
                    }
                    {infoDetails.link !== null && infoDetails.link !== "" &&
                        <div className='col-md-3'>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={infoDetails.link}
                                viewBox={`0 0 256 256`}
                            />
                            <h5 className='mt-2'>Scan For More Details</h5>
                        </div>
                    }
                </div>
                <h3>{infoDetails.subTitle}</h3>    
                
                <RelatedInfo categoryId={infoDetails.categoryId} />
            </div>

            <Dialog
                open={dialogStatus}
                onClose={handleClose}
            >
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3>Watch Again</h3>
                        </div>
                        <div className='col-md-12'>
                            <div className="d-flex justify-content-around">
                                <button className='btn btn-outline-danger' type='button' onClick={handleYes} >Yes</button>
                                <button className='btn btn-outline-primary' type='button' onClick={handleClose}>No</button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default InfoWithVideo