import React, {useEffect, useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { LinearProgress } from '@mui/material'
import AppLayout from '../../../layout/components/AppLayout'

function ChangePasswordPage() {

  const [saving,setSavingStatus] = useState(false);

  const initialValues = {
    oldPassword :'',
    newPassword:'',
    confirmPassword:'',
  }
  const validationRule = Yup.object({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required"),
    confirmPassword: Yup.string().required("Required").oneOf([Yup.ref('newPassword'), null], 'Confirm Passwords must match')
  });

  const onSubmit = (values) => {
    setSavingStatus(true);
    console.log(values);
  }



  return (
    <AppLayout>
        <div className='container'>
          <div className='col-md-4 offset-md-4'>
            <div className='card'>
              <div className='card-header bg-dark text-white'>Change Password</div>
              <div className='card-body'>
                              <Formik
                                initialValues={initialValues}
                                validationSchema={validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                                >
                                {
                                    (formik) => {
                                    //console.log(formik);
                                        return (
                                            <Form>
                                                <div className="row px-3">
                                                    <FormikControl control="input" formik={formik} type="text"
                                                        label="Current Password" name="oldPassword"
                                                        className="col-sm-12 col-xs-12"
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text"
                                                        label="New Password" name="newPassword"
                                                        className="col-sm-12 col-xs-12 mt-4"
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text"
                                                        label="Confirm Password" name="confirmPassword"
                                                        className="col-sm-12 col-xs-12 mt-4"
                                                    />
                                                    
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        {saving ?
                                                            <LinearProgress/>
                                                            :
                                                            <button className="btn btn-primary float-end" type="submit">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }
                              </Formik>

              </div>
            </div>
          </div>
        </div>
    </AppLayout>
  )
}

export default ChangePasswordPage