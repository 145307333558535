import React, { useState } from 'react'
import Webcam from "react-webcam";
import { Dialog, DialogContent } from '@mui/material';
import api from "../../../../api/api";

function GatepassImageCapture({setProfileImage}) {

    const [facingMode,setFacingMode] = useState("user")

    const swicthCamera = () =>{
        if(facingMode == "user"){
            setFacingMode("environment")
        }
        if(facingMode == "environment"){
            setFacingMode("user")
        }
    }

    const videoConstraints = {
        width: 800,
        height: 800,
        facingMode: facingMode,
    };

    const [customerImage,setCustomerImage]  = useState(null);
    const [dialogStatus,setDialogStatus] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false);
    }

    const [uploadInProgress, setUploadProgress] = useState(null);
    const [uploaded ,setUploadStatus] =  useState(false);
    
    const uploadCustomerImage1 = (image) => {
        let data = new FormData();
        
        data.append('tmpFile',{uri:image.path,type:image.mime,name:"abcd.jpg"});

        console.log({uri:image,name:"abcd.jpg"});
    }


    const  DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }


    const uploadCustomerImage = (image) => {
        let data = new FormData();
        
        //data.append('tmpFile',{uri:image.path,type:image.mime,name:"abcd.jpg"});
        data.append('tmpFile',DataURIToBlob(image));

        api.post("upload/aws/gatepass-image",data,  
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => { 
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    //console.log(percent);
                    setUploadProgress(percent)
                }
            }
        ).then( (res)=>{
            //console.log("here in success");
            //console.log(res.data);
            setUploadProgress(null)
            setUploadStatus(true);
            setCustomerImage(res.data.filePath)
            setProfileImage(res.data.filePath)

            setDialogStatus(false);
        }).catch( (error) => {
            //console.log("here in error");
            setUploadProgress(null)
            //console.log(error);
        })
    }

    return (
    <>
        <div className="row">
            <div className="col-md-12 text-center">
                {customerImage ?
                    <img src={customerImage} className="" style={{width:200,height:200}} />
                    :
                    <img src="/images/blank-profile.png" className="" style={{width:200,height:200}} />
                }
            </div>
            <div className="col-md-12">
                <button type="button" className="btn btn-primary mt-2" onClick={()=>setDialogStatus(true)}>Open Camera</button>
            </div>
        </div>
        
        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullScreen={true}
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent style={{padding:0}}>
            <div className="row">
                <div className="col-md-12 text-center">
                    {dialogStatus == true &&
                        <Webcam
                            audio={false}
                            height={350}
                            screenshotFormat="image/jpeg"
                            width={350}
                            videoConstraints={videoConstraints}
                        >
                            {({ getScreenshot }) => (
                                <><br/>
                                <button
                                    className="btn btn-primary m-2"
                                    onClick={() => {
                                        let imageSrc = getScreenshot();
                                        uploadCustomerImage(imageSrc);
                                    }}
                                
                                >
                                    Capture photo
                                </button>
                                <button type="button" onClick={swicthCamera} className="btn btn-dark m-2">Switch Camera</button>
                                <button type="button" onClick={handleClose} className="btn btn-outline-primary m-2">Cancel</button>
                                </>
                            )}
                        </Webcam>
                    }
                </div>
            </div>
            </DialogContent>
        </Dialog>

        
    

    </>
  )
}

export default GatepassImageCapture
