import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QueueLayout from '../components/QueueLayout';
import queueServices from '../queueServices';
import withQueueLayout from '../components/withQueueLayout';
import queueUpdateServices from '../queueUpdateServices';
import { useAlert } from '../../../../context/AlertContext';

import { LinearProgress } from "@mui/material";
import { Formik, Form, isEmptyArray } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';

function QueueFormConfigPage({queueConfig,getQueueConfig}) {

    const {AppAlert} =  useAlert();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [masters,getMasterLabel] = useSystemMasterList(['queue-type'])  

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        queueUpdateServices.updateConfig(values).then(res => {
            getQueueConfig();
            AppAlert(res.msg);
        }).catch(error => {
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        //addressRequired:Yup.string().required("Required"),
        //photoRequired:Yup.string().required("Required"),
        //ageRequired:Yup.string().required("Required"),
        //idProofRequired:Yup.string().required("Required"),
        //remarkRequired:Yup.string().required("Required"),
    });

    const updateData = (data)=>{
        let autoFill = {
            id:data.id,
            emailRequired:data.emailRequired,
            phoneRequired:data.phoneRequired,
            genderRequired:data.genderRequired,
            addressRequired:data.addressRequired,
            photoRequired:data.photoRequired,
            ageRequired:data.ageRequired,
            idProofRequired:data.idProofRequired,
        }
        setFormValues(autoFill);
    }



    useEffect(()=>{
        updateData(queueConfig.queueDetails);
    },[])

    return (
        <div className='card'>
            <div className='card-header'>Visitor Form Configuration</div>
            <div className="card-body mx-2">
                {formValues &&
                <Formik
                        initialValues={formValues}
                        validationSchema={validationRule}
                        onSubmit={onSubmit}
                        enableReinitialize validateOnMount
                    >
                    {
                        (formik) => {
                            return (
                                <Form>
                                    
                                    <div className="row">

                                        <div className="col-md-12 mt-3">
                                            <h3>Form Field Configuration</h3>
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Email" name="emailRequired" 
                                            />
                                        </div>
                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Phone Number" name="phoneRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Customer Photo" name="photoRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Age" name="ageRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Gender" name="genderRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Address" name="addressRequired" 
                                            />
                                        </div>

                                        <div className="col-md-6 col-xs-12 p-2" >
                                            <FormikControl control="switch" formik={formik} 
                                                label="Id proof" name="idProofRequired" 
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className='col-md-12 mt-2'>
                                            {savingInProgress ? 
                                                <LinearProgress/>
                                                :
                                                <button className="btn btn-outline-primary btn-lg float-end" type="submit">Save</button>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
                }
            </div>
        </div>
    )
}

export default withQueueLayout(QueueFormConfigPage)