import React, { useEffect, useState } from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import { useParams } from 'react-router-dom'
import kioskInfoCategoryServices from '../kioskInfoCategoryServices';
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline';
import KioskInfoCategoryForm from '../components/KioskInfoCategoryForm';
import { Switch } from '@mui/material';

function KioskInfoCategoryDetailsPage() {
    const {id} = useParams();

    // selected kiosk info Details
    const [kioskInfoCategoryDetails,setKioskInfoCategoryDetails] = useState(null);
    const getKioskInfoCategoryDetails = () =>{
      kioskInfoCategoryServices.getById(id).then(res=>{
        setKioskInfoCategoryDetails(res.data);
      })
    }
    useEffect(()=>{
      getKioskInfoCategoryDetails();
    },[])


    const handleStatusChange = (e,id) =>{
      let payload = {
        id:id,
        status:e.target.checked ? 1 : 0,
      }
      console.log(payload);
      kioskInfoCategoryServices.save(payload).then(res=>{
        getKioskInfoCategoryDetails();
      })
    }
    

    return (
      <div className='row'>
        {kioskInfoCategoryDetails &&
          <>
            <div className='col-md-9'>
              <h3>{kioskInfoCategoryDetails.title }</h3>
            </div>
            <div className='col-md-3'>
              <span className='float-end'>
                <KioskInfoCategoryForm data={kioskInfoCategoryDetails} refreshList={getKioskInfoCategoryDetails} />

                <Switch 
                  color="success"
                  checked={kioskInfoCategoryDetails.status === 1}
                  onChange={(e)=>handleStatusChange(e,kioskInfoCategoryDetails.id)}
                />
              </span>
            </div>
          </>
        }
        

        {kioskInfoCategoryDetails &&
          <div className='col-md-4 mt-3'>
            <div>
              <AwsImageInline
                 label="Image"
                 name="image"
                 value={kioskInfoCategoryDetails.image}
                 editId={kioskInfoCategoryDetails.id}
                 updateInline={kioskInfoCategoryServices.updateInline}
                 path="cms/kiosk/info"
                 fileId={kioskInfoCategoryDetails.id}
                 onSuccess={getKioskInfoCategoryDetails}
              />
            </div>
            <div>
              {kioskInfoCategoryDetails.video == "" ? 
                  <div className='my-5 text-center'><h5>No Video Selected</h5></div>
                :
                  <div className='text-center mt-5'>
                    <iframe src={"https://www.youtube.com/embed/"+kioskInfoCategoryDetails.video+"?controls=1&showinfo=0&rel=0&autoplay=0&loop=1&mute=1"} frameborder="0" allowfullscreen></iframe>
                  </div>
              }
            </div>
          </div>
        }
        {kioskInfoCategoryDetails &&
          <div className='col-md-8'>
            <table className='table'>
              <tbody>
                <tr className='table-primary'>
                  <th>Title</th>
                  <td>{kioskInfoCategoryDetails.title }</td>
                </tr>
                <tr>
                  <th>Details</th>
                  <td>{kioskInfoCategoryDetails.details }</td>
                </tr>
                <tr>
                  <th>Link</th>
                  <td><a href={kioskInfoCategoryDetails.link} target='_blank'>Link</a></td>
                </tr>

              </tbody>
            </table>
          </div>
        }
        
      </div>
    )
}

export default withCMSLayout(KioskInfoCategoryDetailsPage)