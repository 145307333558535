import React,{useState,useEffect} from 'react'
import CounterDisplay from './CounterDisplay'
import DisplayHeader from './DisplayHeader'
import displayPageServices from '../displayPageServices';

function MiniDisplay({displayInfo,announceToken}) {

    const [userDDList,setUserDDList] = useState(null);
    const getUserDDList = () => {
        displayPageServices.getUserDDList().then(res=>{
            setUserDDList(res.data);
        }).catch(error=>{

        })
    }
    useEffect(()=>{
        getUserDDList();
    },[])
    
    return (
        <div className='row'>
            
            <div className='col-md-12'>
                    <DisplayHeader />
            </div>
            
            <div className='col-md-12'>
                <CounterDisplay userDDList={userDDList} announceToken={announceToken}/>
            </div>
            
        </div>
    )
}

export default MiniDisplay