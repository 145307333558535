import React,{useState,useEffect} from 'react'
import KioskHeader from '../../sections/KioskHeader'
import KioskNav from '../../sections/KioskNav'
import { LinearProgress, Rating } from '@mui/material'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import kioskFeedbackServices from '../kioskFeedbackServices';
import { useAlert } from '../../../../context/AlertContext';
import { useNavigate } from 'react-router-dom';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';


const starIcon = {
    1: <SentimentVeryDissatisfiedIcon style={{fontSize:40}}/>,
    2: <SentimentDissatisfiedIcon style={{fontSize:40}}/>,
    3: <SentimentSatisfiedIcon style={{fontSize:40}}/>,
    4: <SentimentSatisfiedAltIcon style={{fontSize:40}}/>,
    5: <SentimentVerySatisfiedIcon style={{fontSize:40}}/>,
};

const starLabels = {
    1: 'Bad',
    2: 'Poor',
    3: 'OK',
    4: 'Good',
    5: 'Excellent',
};

const labelColor = {
    1: 'text-danger',
    2: 'text-danger',
    3: 'text-warning',
    4: 'text-success',
    5: 'text-success',
};


function KioskDeviceFeedbackFormPage() {

    
    const [hover, setHover] = useState(-1);
    const [rating,setRating] = useState(0);
    const {AppAlert} = useAlert();
    const [saving,setSavingStatus] = useState(false);
    const navigate = useNavigate();


    const initialValues = {
        feedback:"",
        visitorName:"",
        visitorPhone:"",
    }

    const validationRule = Yup.object({
        feedback:Yup.string().required("Required"),
        visitorName:Yup.string().required("Required"),
        //visitorPhone:Yup.string().required("Required"),
    });


    const onSubmit = (values) =>{
        let payload = values;
        payload.rating = rating
        console.log(payload);
        
        setSavingStatus(true);
        kioskFeedbackServices.saveFeedback(payload).then(res=>{
            AppAlert("Thanks For your feedback")
            setRating(0);
            navigate("/kiosk/dashboard");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })        
    }

    
    

    return (
        <div className='container'>
            <KioskHeader/>
                

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>

                                    <div className='row mt-5'>
                                        <div className='col-md-12'>
                                            <h3>How was our Service ?</h3>
                                            <hr/>
                                        </div>
                                        
                                        <div className='col-md-12'>
                                            <div className="d-flex justify-content-start mb-3">
                                                <Rating style={{fontSize:50}}
                                                    defaultValue={rating}
                                                    onChange={(event, newValue) => {
                                                        setRating(newValue);
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                />
                                                {hover != -1 &&
                                                <h3 className={'mt-2 ms-3 '+labelColor[hover]}>
                                                    {starIcon[hover]} {starLabels[hover]}
                                                </h3>
                                                }
                                                {rating != 0 && hover == -1 &&
                                                <h3 className={'mt-2 ms-3 '+labelColor[rating]}>
                                                    {starIcon[rating]} {starLabels[rating]}
                                                </h3>
                                                }
                                            </div>
                                        </div>
                                        
                                                <FormikControl control="keyboard-text-input" formik={formik} type="text" 
                                                    label="Share Your Experience" name="feedback" 
                                                    className="col-md-12 col-xs-12 mb-2" 
                                                />

                                                <FormikControl control="keyboard-text-input" formik={formik} type="text" multiline={true}
                                                    label="Your Name" name="visitorName" 
                                                    className="col-md-12 col-xs-12 mb-2" 
                                                />

                                                <FormikControl control="keyboard-text-input" formik={formik} type="number" multiline={true}
                                                    label="Your Contact No (optional)" name="visitorPhone" 
                                                    className="col-md-12 col-xs-12 mb-2" 
                                                />

                                        

                                        <div className='col-md-12'>
                                            {saving ? 
                                                <LinearProgress/>
                                                :
                                                <button 
                                                    className='btn btn-primary btn-lg float-end' 
                                                    disabled={rating==0}
                                                >
                                                    Submit
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            <KioskNav/>
        </div>
    )
}

export default KioskDeviceFeedbackFormPage