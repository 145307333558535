import React,{useState,useEffect,useRef} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import gatepassVisitorServices from '../gatepassVisitorServices';
import DateSelect from '../../../../components/AppInput/DateSelect'
import { Download } from '@mui/icons-material';
import moment from 'moment';
import { Link } from 'react-router-dom';

const RenderLink = (params) =>{
    return (
        <Link to={`/gatepass/details/${params.data.id}`}>{params.data.visitorName}</Link>
    )
}

function GatepassVisitorListPage() {

    const [filter,setFilter] = useState(null);
    const [reportData,setReportData] = useState(null);
    const getReportData = ()=>{
        if(filter!==null){
            gatepassVisitorServices.getReportData(filter).then(res=>{
                setReportData(res.data);
            }).catch(error=>{
            })
        }
    }
    
    useEffect(()=>{
        getReportData();
    },[filter])




    /* Grid Work */

  const gridRef = useRef();
  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:150,
    filter:true,
    sortable: true,
    cellStyle: { 'textTransform': 'uppercase' },
  });

  


  const [columnDefs,setColumnDef] = useState();
  const prepairColumnDef = () => {
      let _columnDefs = [
          {field:"id",headerName: "Reg No", width:90},
          {field:"attendantId",headerName:"To Meet",valueGetter: params => getUserName("users",params.data.attendantId)},
          {headerName:"Visitor Name", width:"200", cellRenderer: RenderLink},
          {field:"phone"},
          {field:"email"},
          {field:"inTime",headerName:"In Time ", valueGetter: params => moment(params.data.inTime).format("lll")},
          
          {field:"validateTill",headerName:"Valid Till ", valueGetter: params => moment(params.data.validateTill).format("lll")},
          {field:"created_at",headerName:"Generated At ", valueGetter: params => moment(params.data.created_at).format("lll")},
          {field:"createdBy",headerName:"Generated By",valueGetter: params => getUserName("users",params.data.createdBy)},
          
          
      ];
      setColumnDef(_columnDefs);
  }

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv("data.csv");
  };

  /* Prepair User Master start*/
  const [masters,setMasters] = useState(null);
  const getUserDD = () => {
      gatepassVisitorServices.getUserDDList().then(res=>{
          setMasters({"users":res.data})
      })
  }

  const getUserName = (type, id) => {
      let label = masters[type].find(m => m.value === id)
      if(label!== undefined){
          return label.firstName+" "+label.lastName;
      } else {
          return "NA";
      }
      
  }

  const getWorkLocation = (type, id) => {
      let label = masters[type].find(m => m.value === id)
      if(label!== undefined){
          return label.workLocation;
      } else {
          return "NA";
      }
  }

  useEffect(()=>{
      getUserDD();
  },[]);

  /* Prepair User Master end*/


  useEffect(()=>{
    if(masters) {
      prepairColumnDef()
    }
  },[masters])

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6'>
                    </div>
                    <div className='col-md-6'>
                        <span className='float-end'>
                            <DateSelect filter={filter} setFilter={setFilter} />
                            {reportData && <button type="button" onClick={onBtnExport} className="btn btn-primary ms-3 float-end"><Download/>  Export</button> }
                        </span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                    <div className="ag-theme-alpine" style={{height:600}}>
                        <AgGridReact
                          ref={gridRef}
                          rowData={reportData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          animateRows={true}
                          onGridReady={getReportData}
                        >
                        </AgGridReact>
                    </div>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default GatepassVisitorListPage