import api from "../../../api/api";

const saveFeedback = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/kiosk-device/feedback",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const kioskFeedbackServices = {
    saveFeedback
    
}
export default kioskFeedbackServices;