import React,{useState,useEffect} from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import newsServices from '../newsServices';
import NewsForm from '../components/NewsForm';
import { Switch } from '@mui/material';
import withModuleSetting from '../../../../section/withModuleSetting';

function ManageNewsPage() {
  const [newsList,setNewsList] = useState(null);
  const getNewsList = () =>{
    newsServices.getList().then(res=>{
      setNewsList(res.data)
    }).catch(error=>{
      console.log(error);
    })
  }
  useEffect(()=>{
    getNewsList()
  },[])

  const handleStatusChange = (e,id) =>{
    let payload = {
      id:id,
      status:e.target.checked ? 1 : 0,
    }
    console.log(payload);
    newsServices.save(payload).then(res=>{
      getNewsList();
    })
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-9'>
          <h3>News List</h3>
        </div>
        <div className='col-md-3'>
          <span className='float-end'><NewsForm refreshList={getNewsList} /></span>
        </div>
      </div>
      {newsList &&
        <div className='row'>
          {newsList.map(news=>
            <div className='col-md-4'>
              <div className='card'>
                <div className='card-header'>
                  <div class="d-flex justify-content-between">
                    <div>{news.title}</div>
                    <div>
                      <NewsForm refreshList={getNewsList} data={news} />
                      <Switch 
                        color="success"
                        checked={news.status === 1}
                        onChange={(e)=>handleStatusChange(e,news.id)}
                      />
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  {news.details}
                </div>
              </div>
            </div>      
          )}
        </div>
      }
    </>
  )
}

export default withCMSLayout( withModuleSetting( ManageNewsPage, "kioskNewsModuleEnabled"))