import React,{useState,useEffect,useContext} from 'react'
import kioskInfoServices from '../app/kiosk/infoKiosk/kioskInfoServices';
const KioskConfigContext = React.createContext();

export const useKioskConfig = () => {
    return useContext(KioskConfigContext);
}

export const KioskProvider = (props) =>{
    const [kioskConfig,setKioskConfig] = useState(null);
    const resetConfig = () =>{
        console.log("reset Business config")
        kioskInfoServices.getBusinessInfo().then(res=>{
            res.data.quickCheckin = 0;
            res.data.quickPrint = 0;
            res.data.listType = 0;
            setKioskConfig(res.data);
            localStorage.setItem("kioskConfig",JSON.stringify(res.data))
        })
    }

    const updateConfig = (payload) =>{
        console.log("update Business config")
        setKioskConfig(payload);
        localStorage.setItem("kioskConfig",JSON.stringify(payload))
    
    }


    useEffect(()=>{
        let _kioskConfig  =  JSON.parse(localStorage.getItem("kioskConfig"));
        if(_kioskConfig == null){
            resetConfig();
        } else {
            setKioskConfig(_kioskConfig);
        }
    },[])


    const value= [
        kioskConfig,
        updateConfig,
        resetConfig
    ]
    return <KioskConfigContext.Provider value={value}>{props.children}</KioskConfigContext.Provider>
}