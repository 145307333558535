import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
// Counter
import CounterDashboarPage from '../app/counter/dashboard/pages/CounterDashboarPage'
import CounterCheckinPage from '../app/counter/appointments/pages/CounterCheckinPage'




function CounterRoutes() {

    const {authUser} = useAuth()

    return (
        <Routes>
            {/* Counter */}
            {/* <Route path="/counter/dashboard" element={<CounterDashboarPage /> }></Route> */}
            {authUser.role == 3  && <Route path="/" element={<CounterCheckinPage /> }></Route> }
            <Route path="/counter/dashboard" element={<CounterCheckinPage /> }></Route>
            <Route path="/counter/queue" element={<CounterCheckinPage /> }></Route>
        </Routes>
        
    )
}

export default CounterRoutes