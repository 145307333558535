import React from 'react'
import AppDoughnutChart from './AppDoughnutChart';

function RemarkPieChart({remarkData}) {
    const dataLabels = [];
    const chartData = [];
    remarkData.forEach(remark=>{
        dataLabels.push(remark.title+" ("+remark.count+")");
        chartData.push(remark.count);
    })
  return (
    <AppDoughnutChart dataLabels={dataLabels} chartData={chartData} />
    
  )
}

export default RemarkPieChart