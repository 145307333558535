import React,{useState} from 'react'
import {CircularProgress, Switch } from '@mui/material';
import withQueueLayout from '../components/withQueueLayout'
import { useAlert } from '../../../../context/AlertContext';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import queueUpdateServices from '../queueUpdateServices';



const BusinessDay = ({businessDay,AppAlert}) =>{
    
  const [dayStart,setDayStart] = useState(dayjs('2022-11-22T'+businessDay.dayStart));
  const [dayEnd,setDayEnd] = useState(dayjs('2022-11-22T'+businessDay.dayEnd));
  const [dayStatus,setDayStatus] = useState(businessDay.dayStatus?true:false);
  const [touched,setTouched] = useState(false);
  const [savingInProgress, setSavingStatus] = useState(false);


  /*
      let yourDate = new Date();
      yourDate = yourDate.toISOString().split('T')[0];
      const [dayStart,setDayStart] = useState(dayjs(yourDate+businessDay.dayStart));
  */
  const getWeekDayName = (dayNo) =>{
      let weekDays = ["","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
      return weekDays[dayNo];
  }

  const handleDayStartChange = (newValue) =>{
      console.log(newValue.format("HH:mm:ss"));
      setDayStart(newValue);
      setTouched(true)
  }

  const handleDayEndChange = (newValue) =>{
      console.log(newValue.format("HH:mm:ss"));
      setDayEnd(newValue);
      setTouched(true)
  }

  const handleDayStatusChange = (event) =>{
      setDayStatus(event.target.checked);
      setTouched(true)
  }

  
  
  const update = ()=>{
      setSavingStatus(true);
      let payload = {
          id:businessDay.id,
          dayStart:dayStart.format("HH:mm:ss"),
          dayEnd:dayEnd.format("HH:mm:ss"),
          dayStatus
      }
      queueUpdateServices.updateBusinessHours(payload).then(res=>{
          AppAlert(res.msg);
          setTouched(false);
      }).catch(error=>{
          AppAlert(error.msg,"error");
      }).finally(()=>{
          setSavingStatus(false);
      })
  }

  return (
      <tr>
          <th>{getWeekDayName(businessDay.dayNo)}</th>
          <th><Switch checked={dayStatus} onChange={handleDayStatusChange} name="Day Status" /></th>
          <td>
              <TimePicker
                  label="Time"
                  value={dayStart}
                  onChange={handleDayStartChange}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
              />
          </td>
          <td>
              <TimePicker
                  label="Time"
                  value={dayEnd}
                  onChange={handleDayEndChange}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
              />
          </td>
          <td>
              {touched && !savingInProgress && <button type="button" className="btn btn-success btn-sm" onClick={update}>Save</button>}
              {savingInProgress &&  <CircularProgress/> }
          </td>
          
      </tr>
  )
}



function BusinessHoursPage({queueConfig}) {
  const {AppAlert} =  useAlert();
  return (
    <div className="card">
      <div className='card-header'>Business Hours</div>
      <div className='card-body'>
        <div className="col-md-12 table-responsive">
          <p className="text-primary small">TimeZone: Asia/Calcutta</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <table className="table">
              <thead>
                  <tr>
                      <th></th>
                      <th></th>
                      <th>Open</th>
                      <th>Close</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                  {queueConfig.businessHours.map(businessDay=>
                    <BusinessDay businessDay={businessDay} key={businessDay.id} AppAlert={AppAlert}/>
                  )}
              </tbody>
          </table>
          </LocalizationProvider>
        </div>
      </div>
    </div>
    
  )
}

export default withQueueLayout(BusinessHoursPage)