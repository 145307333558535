import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import queueServices from '../queueServices'
import { Link } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import { Switch } from '@mui/material';
import queueUpdateServices from '../queueUpdateServices';
import QueueForm from '../components/QueueForm';
import withModuleSetting from '../../../../section/withModuleSetting';

function QueueDashboardPage() {
    const [queueList,setQueueList] =  useState(null);

    const getQueueList = () =>{
        
        queueServices.getList().then(res=>{
            setQueueList(res.data)
        }).catch(error=>{
            console.log(error);
        }) 
        
    }

    useEffect(()=>{
        getQueueList();
    },[])

    const handleStatusChange = (e,id) =>{
        let payload = {
            id:id,
            status:e.target.checked ? 1 : 0,
        }
       console.log(payload);
       queueUpdateServices.updateConfig(payload).then(res=>{
            getQueueList();
       })
    }

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    
                        <div className='col-md-3  mb-2'>
                            <div className='p-5 text-center card'>
                                <QueueForm getQueueList={getQueueList} />
                            </div>
                        </div>
                    
                    { queueList && queueList.length > 0 && queueList.map(queue=>
                    <div className='col-md-3 mb-2' key={queue.id}>
                        <div className='card shadow' >
                            <div className={queue.status === 1 ? 'card-header p-1 ps-3 bg-success text-white' :'card-header bg-dark text-white p-1 ps-3'} >
                                <div className="d-flex justify-content-between">
                                    <span className='mt-2'>{queue.title}</span>
                                    <span><Link to={"/queue/config/"+queue.id} className='btn btn-success shadow' ><Settings/> Setting</Link></span>
                                </div>
                            </div>
                            <div className='card-body p-0'>
                                <table className={queue.status === 1 ? 'table table-success m-0' : "table m-0"}>
                                    <tbody>
                                        <tr><td>Prefix</td><td>{queue.prefix}</td></tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>
                                                <Switch 
                                                    color="success"
                                                    checked={queue.status === 1}
                                                    onChange={(e)=>handleStatusChange(e,queue.id)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </AppLayout>
    )
}

export default withModuleSetting(QueueDashboardPage,"kioskTokenModuleEnabled",true)