import React, { useState, useEffect } from 'react'
import YouTube from 'react-youtube';

function FullPageVideoComponent({displayInfo}) {
  const [videoId,setVideoId] = useState(displayInfo.videoAdsList[0].video);
  const [index,setIndex] = useState(0);

  function onEnd(event) {
    console.log("here playing again");
    let _index = index+1;
    if(_index == displayInfo.videoAdsList.length){
      _index = 0;
    }
    setIndex(_index);
    setVideoId(displayInfo.videoAdsList[_index].video)
    setTimeout(()=>{
      //event.target.playVideo();  
    },100)
  }

  return (
    <section className="video-wrapper">      
      <YouTube
        videoId={videoId}
        opts={{
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                mute:1,
                controls:0,
                rel:0,
                loop:1,
            },
        }}
        onEnd={onEnd}
      />
      {/*<iframe src="https://www.youtube.com/embed/hJ8kusDEFKI?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" frameborder="0" allowfullscreen></iframe> */}
    </section>
  )
}

export default FullPageVideoComponent

/*
import YouTube from 'react-youtube';

function onPlayerReady(event) {
        //event.target.playVideo();    
    }

    function onEnd(event) {
        console.log("here playing again");
        event.target.playVideo();    
    }

<YouTube
                    videoId={"2g811Eo7K8U"}                  // defaults -> ''
                    //id={string}                       // defaults -> ''
                    //className={string}                // defaults -> ''
                    //iframeClassName={string}          // defaults -> ''
                    //style={object}                    // defaults -> {}
                    //title={string}                    // defaults -> ''
                    //loading={string}                  // defaults -> undefined
                    opts={{
                        playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 1,
                            mute:1,
                            controls:0,
                            rel:0,
                            loop:1,
                        },
                    }}                        // defaults -> {}
                    //onReady={onPlayerReady}                    // defaults -> noop
                    //onPlay={func}                     // defaults -> noop
                    //onPause={func}                    // defaults -> noop
                    onEnd={onEnd}                      // defaults -> noop
                    //onError={func}                    // defaults -> noop
                    //onStateChange={func}              // defaults -> noop
                    //onPlaybackRateChange={func}       // defaults -> noop
                    //onPlaybackQualityChange={func}    // defaults -> noop
                />

*/

/*
https://codepen.io/pgurav/pen/GRgRpZP
*/