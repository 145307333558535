import React from 'react'
import RightAdsComponent from './RightAdsComponent';
import BottomNewsComponent from './BottomNewsComponent';
import FullPageVideoComponent from './FullPageVideoComponent';
import DisplayHeader from './DisplayHeader';

function DisplayCurrentToken({displayList,displayInfo}) {
    return (
        <div className='row'>
            {displayList.length > 0 &&
                <div className='col-md-12'>
                    <DisplayHeader />
                </div>
            }
            {displayList.length > 0 &&

                <div className={displayInfo.adsList?.length == 0 ? 'col-md-12' : 'col-md-8' }>
                    <div className="row ms-2">
                        <div className="col-12">
                            <table className="table fw-bold" style={{fontSize:50}}>
                                <thead>
                                    <tr className="table-dark">
                                        <th width="20%">Token #</th>
                                        <th width="35%">Visitor Name</th>
                                        {/*<th width="20%">Service</th> */}
                                        <th width="45%">Counter #</th>
                                        {/*<th width="20%">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayList && 
                                        displayList.map(token =>
                                            token.status === 2 &&
                                                <tr key={token.id} className={token.status === 2 ? "bg-danger text-white":"" } style={{fontSize:50}}>
                                                    <td className="p-1"><span className="blinking">{token.tokenPrefix}-{token.tokenNo}</span></td>
                                                    <td className="p-1"><span className="blinking">{token.firstName}-{token.lastName}</span></td>
                                                    {/*<td>{token.queueTitle}<ArrowRight/>{token.serviceTitle}</td> */}
                                                    {
                                                    <td className="p-1">
                                                        {token.attendantUserId != null  ? 
                                                            <span className="blinking">{token.attendent.workLocation} </span>
                                                                : 
                                                            <span >Waiting</span>
                                                        }
                                                    </td>
                                                    }
                                                    {/* <td>{token.statusTitle}</td> */}
                                                </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            }
            
            {displayList.length > 0 ?
                <div className= {'col-md-4'} >
                    {displayInfo && <RightAdsComponent displayInfo={displayInfo} /> }
                </div>
                :
                <>
                    {displayInfo?.videoAdsList.length > 0 ? 
                        <FullPageVideoComponent displayInfo={displayInfo} />
                        :
                        <div className= {'col-md-4 offset-md-4'} >
                            {displayInfo && <RightAdsComponent displayInfo={displayInfo} /> }
                        </div>
                    }
                </>
                
            }
            
            {displayList.length > 0 &&
                <BottomNewsComponent displayInfo={displayInfo} />
            }
        </div>
    )
}

export default DisplayCurrentToken