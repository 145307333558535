import React,{useState,useEffect,useContext} from 'react'
const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = (props) =>{
    const [authUser,setAuthUser] = useState(null);
    const [isLoggedIn,setIsLoggedIn] = useState(false); 
    const [loading,setLoadingStatus] = useState(true);
    const value= {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    }

    useEffect(()=>{
        let user = JSON.parse(localStorage.getItem("authUser"));
        if(user !== null){
            setAuthUser(user);
            setIsLoggedIn(true);
        }
        setTimeout(()=>{
            setLoadingStatus(false);
        },5000)
    },[])
    /*return(
        <>
        {loading ?
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <img src='/images/g-token-logo.gif' style={{width:"50%"}} />
                    </div>
                </div>
            </div>
            :
            <AuthContext.Provider value= {value}>{props.children}</AuthContext.Provider>
        }
        </>
    )
    */
    return <AuthContext.Provider value= {value}>{props.children}</AuthContext.Provider>
        
    
    
}