import React,{useState,useEffect} from 'react'
import kioskInfoServices from '../kioskInfoServices';
import YouTube from 'react-youtube';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryListSideNav from './CategoryListSideNav';

function RelatedInfo({categoryId}) {
    const {infoId} = useParams();
    const [infoList,setInfoList] = useState(null);
    const getInfoList = () =>{
        kioskInfoServices.getInfoListByCategoryId(categoryId).then(res=>{
            let _infoList = res.data.filter(info => info.id != infoId)
            setInfoList(_infoList);
        }).catch(error=>{

        })
    }

    useEffect(()=>{
        getInfoList();
    },[infoId])

    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate("/kiosk/info/details/"+id)
    }


    return (
        <>
            {infoList && infoList.map(infoDetails=> 
                <div className='row shadow my-3 p-2 mx-1' key={infoDetails.id}>
                    <div className='col-md-6 '>
                        {infoDetails.video !== "" &&
                            <div className='video-wrapper'>
                                <div className=''>
                                    {/*
                                    <iframe 
                                        title={infoDetails.title}
                                        src={"https://www.youtube.com/embed/"+infoDetails.video+"?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=1&modestbranding=0"} 
                                        frameborder="0" allowfullscreen>
                                    </iframe>
                                    */}
                                    <YouTube
                                        videoId={infoDetails.video}
                                        opts={{
                                            playerVars: {
                                                // https://developers.google.com/youtube/player_parameters
                                                autoplay: 0,
                                                mute:0,
                                                controls:0,
                                                rel:0,
                                                loop:1,
                                                showinfo:0,
                                            },
                                        }}
                                    />    
                                </div>
                                <div className='sub-content' onClick={()=>handleClick(infoDetails.id)}>
                                    <div className='d-flex justify-content-center'>
                                        <div style={{position:'absolute',top:"47%"}}>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {infoDetails.video === "" && infoDetails.image !== null && 
                            <img 
                                src={infoDetails.image} 
                                onClick={()=>handleClick(infoDetails.id)} 
                                className='img-thumbnail'
                                style={{cursor:"pointer"}} 
                            />
                        }

                    </div>
                    <div className='col-md-6' style={{cursor:"pointer"}} onClick={()=>handleClick(infoDetails.id)} >
                        {infoDetails.title !== "" && <strong>{infoDetails.title}</strong> }
                        {infoDetails.subTitle !== "" && <p>{infoDetails.subTitle}</p> }
                    </div>
                </div>
            )}
            <CategoryListSideNav/>
        </>
    )
}

export default RelatedInfo