import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import businessServices from '../businessServices'
import { useAlert } from '../../../../context/AlertContext'
import TextInline from '../../../../components/InlineEdit/TextInline'
import useSystemMasterList from '../../../../hooks/useSystemMasterList'
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline'
import { Switch } from '@mui/material'
import moment from 'moment'

function BusinessDetails() {
    const {AppAlert} = useAlert();
    const [masters,getMasterLabel] = useSystemMasterList(['business-type'])
    const [businessDetails, setBusinessDetails] =  useState(null)
    const [kioskCaptionList, setKioskCaptionList] =  useState(null)
    
    const getBusinessDetails = () =>{
        businessServices.getDetails().then(res=>{
            setBusinessDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    const getKioskCaptionList = () =>{
        businessServices.getKioskCaptionList().then(res=>{
            setKioskCaptionList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getBusinessDetails();
        getKioskCaptionList();
    },[])

    const handleStatusChange = (e,module) =>{
            let payload ={};
            payload[module] = e.target.checked ? 1 : 0;
            console.log(payload);
            businessServices.save(payload).then(res=>{
                getBusinessDetails()
            })
    }

    
    

    return (
        <AppLayout>
            <div className='container-fluid'>
                {businessDetails && masters &&
                <div className='row'>
                    <div className='col-md-3'>
                        <AwsImageInline
                            label="Business Logo"
                            name="logo"
                            value={businessDetails.logo}
                            editId={businessDetails.id}
                            updateInline={businessServices.updateInline}
                            path="business/logo"
                            fileId={"logo"}
                            onSuccess={getBusinessDetails}
                        />
                    </div>
                    <div className='col-md-9'>
                        
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th>Business Name</th>
                                    <td>
                                        <TextInline name="title" value={businessDetails.title} editId={businessDetails.id} updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Busines Owner</th>
                                    <td>
                                        <TextInline name="businessOwner" value={businessDetails.businessOwner} editId={businessDetails.id} updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{businessDetails.businessEmail}</td>
                                </tr>
                                <tr>
                                    <th>Phone </th>
                                    <td>{businessDetails.businessPhone}</td>
                                </tr>
                                <tr>
                                    <th>Business Type </th>
                                    <td>{ getMasterLabel("business-type",businessDetails.businessType) }</td>
                                </tr>
                                <tr>
                                    <th>GST</th>
                                    <td>
                                        <TextInline name="gst" value={businessDetails.gst} editId={businessDetails.id}  updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>PAN</th>
                                    <td>
                                        <TextInline name="pan" value={businessDetails.pan} editId={businessDetails.id} updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2} className='text-center bg-dark text-white'>Modules</th>
                                </tr>
                                <tr>
                                    <th>Kiosk Queue</th>
                                    <td>
                                        {businessDetails.kioskTokenModuleEnabled ? 
                                            <div className='d-flex justify-content-start'>
                                                <Switch 
                                                    color="success"
                                                    checked={businessDetails.kioskTokenModule === 1}
                                                    onChange={(e)=>handleStatusChange(e,"kioskTokenModule")}
                                                />
                                                {businessDetails.kioskTokenModule === 1 && kioskCaptionList &&
                                                    <div className='ms-3 mt-2'>
                                                        <span className='fw-bold'> Caption  : </span>
                                                        <TextInline name="title" value={kioskCaptionList[0].title} editId={kioskCaptionList[0].id} updateInline={businessServices.updateKioskCaption} />
                                                    </div>
                                                      
                                                }
                                            </div>
                                            :
                                            <h4>Disabled</h4>
                                        }
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <th>Kiosk Info</th>
                                    <td>
                                        {businessDetails.kioskInfoModuleEnabled ?
                                            <div className='d-flex justify-content-start'>
                                                <Switch 
                                                    color="success"
                                                    checked={businessDetails.kioskInfoModule === 1}
                                                    onChange={(e)=>handleStatusChange(e,"kioskInfoModule")}
                                                />
                                                {businessDetails.kioskInfoModule === 1 && kioskCaptionList &&
                                                    <div className='ms-3 mt-2'>
                                                        <span className='fw-bold'> Caption  : </span>
                                                        <TextInline name="title" value={kioskCaptionList[1].title} editId={kioskCaptionList[1].id} updateInline={businessServices.updateKioskCaption} />
                                                    </div>
                                                      
                                                }
                                            </div>
                                            :
                                            <h4>Disabled</h4>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Kiosk Directory</th>
                                    <td>
                                        {businessDetails.kioskDirectoryModuleEnabled ? 
                                            <div className='d-flex justify-content-start'>
                                                <Switch 
                                                    color="success"
                                                    checked={businessDetails.kioskDirectoryModule === 1}
                                                    onChange={(e)=>handleStatusChange(e,"kioskDirectoryModule")}
                                                />
                                                {businessDetails.kioskDirectoryModule === 1 && kioskCaptionList &&
                                                    <div className='ms-3 mt-2'>
                                                        <span className='fw-bold'> Caption  : </span>
                                                        <TextInline name="title" value={kioskCaptionList[2].title} editId={kioskCaptionList[2].id} updateInline={businessServices.updateKioskCaption} />
                                                    </div>
                                                      
                                                }
                                            </div>
                                            
                                            :
                                            <h4>Disabled</h4>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Kiosk Faq</th>
                                    <td>
                                        {businessDetails.kioskFaqModuleEnabled ? 
                                            <div className='d-flex justify-content-start'>
                                                <Switch 
                                                    color="success"
                                                    checked={businessDetails.kioskFaqModule === 1}
                                                    onChange={(e)=>handleStatusChange(e,"kioskFaqModule")}
                                                />
                                                {businessDetails.kioskFaqModule === 1 && kioskCaptionList &&
                                                    <div className='ms-3 mt-2'>
                                                        <span className='fw-bold'> Caption  : </span>
                                                        <TextInline name="title" value={kioskCaptionList[3].title} editId={kioskCaptionList[3].id} updateInline={businessServices.updateKioskCaption} />
                                                    </div>
                                                      
                                                }
                                            </div>
                                            :
                                            <h4>Disabled</h4>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <th>Kiosk Feedback</th>
                                    <td>
                                        {businessDetails.kioskFeedbackModuleEnabled ? 
                                            <div className='d-flex justify-content-start'>
                                                <Switch 
                                                    color="success"
                                                    checked={businessDetails.kioskFeedbackModule === 1}
                                                    onChange={(e)=>handleStatusChange(e,"kioskFeedbackModule")}
                                                />
                                                {businessDetails.kioskFeedbackModule === 1 && kioskCaptionList &&
                                                    <div className='ms-3 mt-2'>
                                                        <span className='fw-bold'> Caption  : </span>
                                                        <TextInline name="title" value={kioskCaptionList[4].title} editId={kioskCaptionList[4].id} updateInline={businessServices.updateKioskCaption} />
                                                    </div>
                                                      
                                                }
                                            </div>
                                            :
                                            <h4>Disabled</h4>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <th>Valid Till</th>
                                    <td>
                                        { moment(businessDetails.validTill).format("ll") }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                }
            </div>
        </AppLayout>
        
    )
}

export default BusinessDetails