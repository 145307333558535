import api from "../../../api/api";

const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/cms/directory").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/cms/directory/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/cms/directory",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/cms/directory",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("/cms/directory/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const directoryServices = {
    getList,
    getById,
    updateInline,
    save,
    remove,
}
export default directoryServices;