import React, { useEffect, useState } from 'react'
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Dialog } from '@mui/material';
import './keyboard.css'

// Error 
const hasError = (formik,name) =>{
    if(formik.errors[name] !== undefined && formik.touched[name] !== undefined){
        return { error:true, errorMsg : formik.errors[name] };
    } else {
        return { error:false, errorMsg : "" };
    } 
}
// Alpha Numaric Keyboard
const alphaNumaric = {
    default: [
        "1 2 3 4 5 6 7 8 9 0 - {backspace}",
        "q w e r t y u i o p",
        "a s d f g h j k l",
        '{shift} z x c v b n m . "',
        "@ .com {space} {enter}"
    ],
    shift: [
        "! @ # $ % ^ & * ( ) _ {backspace}",
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "{shift} Z X C V B N M , '",
        "@ .com {space} {enter}"
    ], 
}
// Numpad Keyboard
const numpad = {
    default: [
        "1 2 3",
        "4 5 6",
        "7 8 9",
        "{backspace} 0 {enter}"
    ],
    shift: [
        "1 2 3",
        "4 5 6",
        "7 8 9",
        "{backspace} 0 {enter}"
    ],    
}

const keyDisplay = {
    "{space}" : " ",
    "{numbers}": "123",
    "{enter}": "Enter ↵", //✓ ↵ ✔ ➡
    "{escape}": "esc ⎋",
    "{tab}": "tab ⇥",
    "{backspace}": "⌫", //⌫ ←
    "{capslock}": "caps lock ⇪",
    "{shift}": "⇧",
    "{controlleft}": "ctrl ⌃",
    "{controlright}": "ctrl ⌃",
    "{altleft}": "alt ⌥",
    "{altright}": "alt ⌥",
    "{metaleft}": "cmd ⌘",
    "{metaright}": "cmd ⌘",
    "{abc}": "ABC"
}

export function KeyboardTextInput(props) {
    const {label, name, formik, type, ...rest} = props;
    const [layoutName, setLayoutName] = useState('shift')
    const [dialogStatus, setDialogStatus] = useState(false);
    const [inputValue,setInputValue] = useState(formik.values[name])

    const handleShift = () =>{
        layoutName == "default" ? setLayoutName("shift") : setLayoutName("default")
    }

    const handleInput = (button) =>{
        let val  = inputValue === undefined ?"":inputValue ;
        setLayoutName("default")
        if(button === "{backspace}"){
            val = val.substring(0, val.length - 1);
        } else if(button === "{space}"){
            val = val + " ";
        }else{
            val = val+button;
        }
        setInputValue(val);
    }

    const openKeyboard = () =>{
        setInputValue(formik.values[name]);
        setDialogStatus(true);
    }
    const {error,errorMsg} = hasError(formik,name);

    return (
        <div className='mb-3'>
            <div  className={error?'border border-danger text-danger  rounded':'border text-black rounded'} onClick={openKeyboard}>
                <div className='card-body p-2' >
                    <div className={error?"text-danger":'text-muted'}><small>{label}</small></div>
                    <div style={{minHeight:20}}>{formik.values[name]}</div>
                </div>
            </div>
            <div>{error && <small className="ms-2 text-danger">{errorMsg}</small>}</div>
            <Dialog
                open={dialogStatus}
                keepMounted
                maxWidth="lg" 
                fullWidth={true}
                style={{backgroundColor:'rgba(255,255,255,0.5)'}}
            >
                <div className='card'>
                    <div className='card-body'>
                        <div className='text-dark fw-bold'>{label}</div>
                        <h3 style={{minHeight:50}} className={'border text-black p-2 rounded'}> {inputValue}<span className='mycur'>_</span> </h3>
                        <div>{error && <small className="text-danger">{errorMsg}</small>}</div>
                    </div>
                    <div className=''>
                        <Keyboard
                            layoutName={layoutName}
                            layout={type=="number"?numpad:alphaNumaric}
                            display={keyDisplay}
                            onKeyPress={(button)=>{
                                switch(button){
                                    case "{enter}":
                                        formik.setFieldTouched(name,true)
                                        formik.setFieldValue(name,inputValue)
                                        setDialogStatus(false)
                                        break;
                                    case "{shift}":
                                    case "{lock}":
                                        handleShift()
                                        break
                                    default:
                                        handleInput(button)
                                }
                            }}
                            buttonTheme={[
                                {
                                class: "hg-green",
                                buttons: "{enter}"
                                },
                                {
                                class: "hg-red",
                                buttons: "{backspace}"
                                },
                            ]}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}



/*
export function KeyboadAlphaInput(props) {
    const {label, name, formik, ...rest} = props;
    const [layoutName, setLayoutName] = useState('default')
    const [keyboardVisibility, setKeyboardVisibility] = useState(false);

    const handleShift = () =>{
        layoutName == "default" ? setLayoutName("shift") : setLayoutName("default")
    }
    

    useEffect(() => {
        function clickHanlder(e) {
          if (
            !(e.target.nodeName === "INPUT") &&
            !e.target.classList.contains("hg-button") &&
            !e.target.classList.contains("hg-row")
          ) {
            setKeyboardVisibility(false);
          }
        }
    
        window.addEventListener("click", clickHanlder);
        return window.removeEventListener("click", clickHanlder, true);
      }, []);

    return (
        <>
            <Field
                name={name}
            >
                {
                    (props) => {
                        //console.log(props);
                        
                        const { field, form} = props;
                        const {error,errorMsg} = hasError(formik,name);
                        return (
                            <>
                                <div className="form-floating">
                                    <input
                                        onFocus={() => {
                                        setKeyboardVisibility(true);
                                    }}
             
                                     {...rest} className={error?"border-danger text-danger form-control":"form-control"} {...field}  />
                                    <label>{label}</label>
                                </div>
                                {error && <small className="text-danger">{errorMsg}</small>}

                                {keyboardVisibility &&
                                
                                
                                <Keyboard
                                    layoutName={layoutName}
                                    layout={{
                                        default: [
                                            "1 2 3 4 5 6 7 8 9 0 . {backspace}",
                                            "q w e r t y u i o p",
                                            "a s d f g h j k l",
                                            '{shift} z x c v b n m " -',
                                            ".com @ {space} {enter}"
                                        ],
                                        shift: [
                                            "1 2 3 4 5 6 7 8 9 0 , {backspace}",
                                            "Q W E R T Y U I O P",
                                            "A S D F G H J K L",
                                            "{shift} Z X C V B N M ' _",
                                            ".com @ {space} {enter}"
                                        ], 
                                    }}
                                    display={{
                                        "{space}" : " ",
                                        "{numbers}": "123",
                                        "{enter}": "✓",
                                        "{escape}": "esc ⎋",
                                        "{tab}": "tab ⇥",
                                        "{backspace}": "⌫",
                                        "{capslock}": "caps lock ⇪",
                                        "{shift}": "⇧",
                                        "{controlleft}": "ctrl ⌃",
                                        "{controlright}": "ctrl ⌃",
                                        "{altleft}": "alt ⌥",
                                        "{altright}": "alt ⌥",
                                        "{metaleft}": "cmd ⌘",
                                        "{metaright}": "cmd ⌘",
                                        "{abc}": "ABC"
                                    }}
                                    onKeyPress={(button)=>{
                                        console.log("Button pressed", button);
                                        if(button == "{enter}"){
                                            setKeyboardVisibility(false)
                                        }
                                        if (button === "{shift}" || button === "{lock}") handleShift();
                                    }}
                                    buttonTheme={[
                                        {
                                        class: "hg-green",
                                        buttons: "{enter}"
                                        },
                                    ]}
                                    onChange={(newValue) => {
                                            console.log(newValue);
                                            formik.setTouched({...form.touched,[name]: true });
                                            if (newValue) {
                                                if(newValue){
                                                    formik.setFieldValue(name, newValue);
                                                } else {
                                                    formik.setFieldValue(name, newValue);
                                                }
                                                
                                            } else {
                                                formik.setFieldValue(name, "")
                                            }
                                    }}
                                />
                                }
                            </>
                        )
                    }
                }
            </Field>
        </>

    )
}


export function KeyboadNumPadInput(props) {
    const {label, name, formik, ...rest} = props;
    const [layoutName, setLayoutName] = useState('default')
    const [keyboardVisibility, setKeyboardVisibility] = useState(false);


    useEffect(() => {
        function clickHanlder(e) {
          if (
            !(e.target.nodeName === "INPUT") &&
            !e.target.classList.contains("hg-button") &&
            !e.target.classList.contains("hg-row")
          ) {
            setKeyboardVisibility(false);
          }
        }
    
        window.addEventListener("click", clickHanlder);
        return window.removeEventListener("click", clickHanlder, true);
      }, []);

    return (
        <>
            <Field
                name={name}
            >
                {
                    (props) => {
                        //console.log(props);
                        
                        const { field, form} = props;
                        const {error,errorMsg} = hasError(formik,name);
                        return (
                            <>
                                <div className="form-floating">
                                    <input
                                        onFocus={() => {
                                        setKeyboardVisibility(true);
                                    }}
             
                                     {...rest} className={error?"border-danger text-danger form-control":"form-control"} {...field}  />
                                    <label>{label}</label>
                                </div>
                                {error && <small className="text-danger">{errorMsg}</small>}

                                {keyboardVisibility &&
                                
                                
                                <Keyboard
                                    layoutName={layoutName}
                                    layout={{
                                        default: [
                                            "1 2 3",
                                            "4 5 6",
                                            "7 8 9",
                                            "{backspace} 0 {enter}"
                                        ],
                                        
                                    }}
                                    display={{
                                        "{space}" : " ",
                                        "{numbers}": "123",
                                        "{enter}": "✓",
                                        "{escape}": "esc ⎋",
                                        "{tab}": "tab ⇥",
                                        "{backspace}": "⌫",
                                        "{capslock}": "caps lock ⇪",
                                        "{shift}": "⇧",
                                        "{controlleft}": "ctrl ⌃",
                                        "{controlright}": "ctrl ⌃",
                                        "{altleft}": "alt ⌥",
                                        "{altright}": "alt ⌥",
                                        "{metaleft}": "cmd ⌘",
                                        "{metaright}": "cmd ⌘",
                                        "{abc}": "ABC"
                                    }}
                                    onKeyPress={(button)=>{
                                        console.log("Button pressed", button);
                                        if(button == "{enter}"){
                                            setKeyboardVisibility(false)
                                        }
                                        
                                    }}
                                    onRender={()=>{
                                        formik.setFieldValue(name, "")
                                    }}
                                    buttonTheme={[
                                        {
                                        class: "hg-green",
                                        buttons: "{enter}"
                                        },
                                    ]}
                                    onChange={(newValue) => {
                                            console.log(newValue);
                                            formik.setTouched({...form.touched,[name]: true });
                                            if (newValue) {
                                                if(newValue){
                                                    formik.setFieldValue(name, newValue);
                                                } else {
                                                    formik.setFieldValue(name, newValue);
                                                }
                                                
                                            } else {
                                                formik.setFieldValue(name, "")
                                            }
                                    }}
                                />
                                }
                            </>
                        )
                    }
                }
            </Field>
        </>

    )
}

*/