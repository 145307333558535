import React, { useEffect, useState } from 'react'
import { useAlert } from '../../../../context/AlertContext';
import kioskCheckinServices from '../kioskCheckinServices';
import { Dialog, DialogContent } from '@mui/material';
import moment from 'moment';

function QueueSelectComponent({selectedQueue,setSelectedQueue,setActiveStep}) {

    const [queueList,setQueueList] = useState(null);
    const [dialogStatus,setDialogStatus] = useState(false);

    const {AppAlert} = useAlert();

    const getQueueList = () =>{
        kioskCheckinServices.getQueueList().then(res=>{
            setQueueList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getQueueList();
    },[])

    const selectQueue = (queue) =>{
        setSelectedQueue(queue)
        setActiveStep(1);
    }

    const [queueWorkingHours,setQueueWorkingHours] = useState(null);
    const showWorkingHours = (queue) =>{
        setDialogStatus(true);
        setQueueWorkingHours(null);
        kioskCheckinServices.getQueueWorkingHoursList(queue.id).then(res=>{
            setQueueWorkingHours(res.data);
        }).catch(error=>{
        })
    }

    const getWeekDayName = (dayNo) =>{
        let weekDays = ["","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
        return weekDays[dayNo];
    }

    return (
        <>
            {queueList &&
                <div className='list-group'>
                    {queueList.map(queue=>
                        <span key={queue.id}>
                            {queue.isOpenNow == true ?
                                    <button type="button" onClick={()=>selectQueue(queue)} className="list-group-item list-group-item-action">
                                        <div>{queue.title}</div>
                                        <div className='text-success'>Waiting Time {queue.waitingTime} Minutes</div>
                                    </button>
                                :
                                    <button type="button" onClick={()=>showWorkingHours(queue)} className="list-group-item list-group-item-action">
                                        <div>{queue.title}</div>
                                        <div className='text-danger'>Currently Closed <button className='btn btn-danger'>Show Working Hours</button></div>
                                    </button>
                            }                                            
                        </span>
                    )}

                    <Dialog
                        open={dialogStatus}
                        keepMounted
                        onClose={()=>setDialogStatus(false)}
                    >
                        <DialogContent>
                            <div className='row'>
                                {queueWorkingHours && 
                                    <div className='col-md-12'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {queueWorkingHours.map(day=> day.dayStatus == 1 &&
                                                    <tr key={day.dayNo}>
                                                        <td>{ getWeekDayName(day.dayNo)}</td>
                                                        <td>
                                                           <span className='fw-bold'> { moment( moment().format("YYYY-MM-DD")+" "+day.dayStart).format("h:mm A")} </span>
                                                            to 
                                                            <span className='fw-bold'> { moment( moment().format("YYYY-MM-DD")+" "+day.dayEnd).format("h:mm A")} </span>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                <div className='col-md-12'>
                                    <button className='btn btn-outline-primary' onClick={()=>setDialogStatus(false)} >Close</button>
                                </div>
                            </div>

                            
                        </DialogContent>
                    </Dialog>
                </div>
            }

        </>
        
    )
}

export default QueueSelectComponent