import React from 'react'
import { Route, Routes } from 'react-router-dom'

// Kiosk
import KioskDashboarPage from '../app/kiosk/dashboard/pages/KioskDashboarPage'
import KioskCheckinPage from '../app/kiosk/checkin/pages/KioskCheckinPage'
import KioskTokenDetailsPage from '../app/kiosk/checkin/pages/KioskTokenDetailsPage'
import KioskDeviceInfoDetailsPage from '../app/kiosk/infoKiosk/pages/KioskDeviceInfoDetailsPage'
import KioskDeviceInfoCategoryListPage from '../app/kiosk/infoKiosk/pages/KioskDeviceInfoCategoryListPage'
import KioskDeviceInfoListByCategoryPage from '../app/kiosk/infoKiosk/pages/KioskDeviceInfoListByCategoryPage'
import { useAuth } from '../context/AuthContext'
import KioskDeviceFaqPage from '../app/kiosk/faq/pages/KioskDeviceFaqPage'
import KioskDeviceDriectoryPage from '../app/kiosk/directory/pages/KioskDeviceDriectoryPage'
import KioskDeviceFeedbackFormPage from '../app/kiosk/feedback/pages/KioskDeviceFeedbackFormPage'
import KioskLocalConfigPage from '../app/kiosk/localConfig/pages/KioskLocalConfigPage'
import { KioskProvider } from '../context/KioskConfigContext'


function KioskRoutes() {
    const {authUser} = useAuth();
    return (
        <KioskProvider>
            <Routes>
                {/* Kiosk */}
                {authUser.role == 5 && <Route path="/" element={<KioskDashboarPage /> }></Route> }
                <Route path="/kiosk/dashboard" element={<KioskDashboarPage /> }></Route>
                <Route path="/kiosk/checkin" element={<KioskCheckinPage /> }></Route>
                <Route path="/kiosk/token-details/:customerId" element={<KioskTokenDetailsPage /> }></Route>
                <Route path="/kiosk/info/categories" element={<KioskDeviceInfoCategoryListPage/> }></Route>
                <Route path="/kiosk/info/category/:categoryId" element={<KioskDeviceInfoListByCategoryPage/> }></Route>
                <Route path="/kiosk/info/details/:infoId" element={<KioskDeviceInfoDetailsPage /> }></Route>
                <Route path="/kiosk/info/faq" element={<KioskDeviceFaqPage/> }></Route>
                <Route path="/kiosk/info/directory" element={<KioskDeviceDriectoryPage/> }></Route>
                <Route path="/kiosk/feedback/form" element={<KioskDeviceFeedbackFormPage/> }></Route>
                <Route path="/kiosk/local-config" element={<KioskLocalConfigPage/> }></Route>
            </Routes>
        </KioskProvider>
    )
}

export default KioskRoutes