import { ArrowBack, Help, Info, Receipt, Signpost } from '@mui/icons-material'
import React from 'react'
import { useNavigate, useMatches} from 'react-router-dom';


function KioskTopNav() {
    const navigate = useNavigate()
  return (
            <div className='row mt-4'>
                
                <div className='col-md-12'>
                    <button className='btn btn-lg btn-outline-primary' onClick={()=>navigate(-1)}><ArrowBack/> Back </button>
                </div>
                
                
            </div>
  )
}

export default KioskTopNav