import React, { useState,useCallback, useContext } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { SwapHoriz } from '@mui/icons-material';
import counterCheckinServices from '../counterCkeckinServices';



function TransferToken({visitor,refreshList}) {

    const [businessId,setBusinessId] = useState(1);
    const [dialogStatus, setDialogStatus] = useState(false); 

    const [queueList,setQueueList] = useState(null);
    const [loading,setLoadingStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [newTokenDetails, setNewTokenDetails] = useState(null); 

    const getQueueList = useCallback(() =>{
        setLoadingStatus(true);
        counterCheckinServices.getCheckinQueueList().then(res=>{
            setQueueList(res.data);
        }).catch(error=>{
            //AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[businessId]);


    const transferToken = (queueId) =>{
        let postData = {
            queueId:queueId,
            tokenId:visitor.id
        }
        setSavingStatus(true);
        counterCheckinServices.transferToken(postData).then(res=>{
            //console.log("here in success");
            //console.log(res);
            setNewTokenDetails(res.newTokenDetails);
            setDialogStatus(false);
            setNewTokenDialogStatus(true);
            
        }).catch(error=>{
            alert(error.msg);
            //AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })

    }


    const handleClose = () =>{
        setDialogStatus(false);
    }

    const showDialog = () =>{
        setDialogStatus(true);
        getQueueList();
    }

    
    const [newTokenDialogStatus, setNewTokenDialogStatus] = useState(false); 
    const handleNewTokenDialogClose = () =>{
        setNewTokenDialogStatus(false);
        refreshList();
    }

    return (
        <>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Transfer Token</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <h3>Private / Senior Queue</h3>
                        </div>
                        <div className="col-12">
                            {queueList && queueList.map(queue =>
                                <span key={queue.id}>{ queue.public === 0 && <button type="button" className="btn btn-lg btn-primary m-1" onClick={()=>{transferToken(queue.id)}} >{queue.title}</button> }</span>
                            )}
                        </div>

                        <div className="col-12">
                            <h3>Public Queue</h3>
                        </div>
                        <div className="col-12">
                            {queueList && queueList.map(queue =>
                                <span key={queue.id}>{queue.public === 1 && <button type="button" className="btn btn-lg btn-primary m-2" onClick={()=>{transferToken(queue.id)}} >{queue.title}</button> }</span>
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={newTokenDialogStatus}
                keepMounted
                onClose={handleNewTokenDialogClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>New Token</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            {newTokenDetails && 
                                <div className="row mt-2 mb-2">
                                    <div className="col-6">
                                        <h4> Token # : </h4>
                                    </div>
                                    <div className="col-6">
                                        <span className="bg-primary text-white rounded shadow  p-2" style={{fontSize:26}}>{newTokenDetails.prefix} - {newTokenDetails.tokenNo} </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-danger" onClick={handleNewTokenDialogClose} >Close</button>
                </DialogActions>
            </Dialog>
            <button type="button" className="btn btn-dark" onClick={showDialog} ><SwapHoriz/> Transfer Token</button>
        </>
    )
}
export default TransferToken

