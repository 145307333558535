
import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';
import AppLayout from '../../../../layout/components/AppLayout'
import { NavLink } from 'react-router-dom'

const withCMSLayout = OriginalComponent =>(props)  =>{
    const {id} = useParams();
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='list-group rounded-0'>
                            <NavLink className="list-group-item" aria-current="page" to={"/cms/ads"} >Manage Ads</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/cms/news"} >Manage News</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/cms/kiosk-category"} >Manage Information Categories</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/cms/kiosk-info"} >Manage Kiosk Information</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/cms/kiosk-faq"} >Manage Kiosk FAQ</NavLink>
                            <NavLink className="list-group-item" aria-current="page" to={"/cms/kiosk-directory"} >Manage Directory</NavLink>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <OriginalComponent {...props} />
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withCMSLayout