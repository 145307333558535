import React,{useState,useEffect} from 'react'
import KioskHeader from '../../sections/KioskHeader'
import KioskNav from '../../sections/KioskNav'
import kioskFaqServices from '../kioskFaqServices';
import QRCode from 'react-qr-code';
import SearchInputWithMic from '../../../../components/AppInput/SearchInputWithMic';

function KioskDeviceFaqPage() {

    const [faqList,setFaqList] = useState(null);
    const [searchResultList,setSearchResultList] = useState(null);
    const getFaqList = () =>{
        kioskFaqServices.getList().then(res=>{
        setFaqList(res.data)
        setSearchResultList(res.data)
        }).catch(error=>{
        console.log(error);
        })
    }
    useEffect(()=>{
        getFaqList()
    },[])

    const [searchInput,setSearchInput] = useState("");

    const doSearch = ()=>{
        let _searchResultList = faqList.filter(faq => faq.question.toLowerCase().search(searchInput.toLowerCase()) !== -1 )
        setSearchResultList(_searchResultList)
    }
    

  return (
    <div className='container'>
        <KioskHeader/>
        
        {searchResultList &&
            <div className='row'>
            
            <div className='col-md-12'>
                <SearchInputWithMic searchInput={searchInput} setSearchInput={setSearchInput} doSearch={doSearch} />
            </div>

            {searchResultList.map(faq=>
                <div key={faq.id} className='col-md-12 mt-2'>
                <div className='card'>
                    <div className='card-header'>
                        <div><strong>Question. </strong>{faq.question}</div>
                    </div>
                    <div className='card-body'>
                    <strong>Answer. </strong>{faq.answer}
                    </div>
                    {faq.link !== "" &&
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={faq.link}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                </div>      
            )}
            </div>
        }
        <KioskNav/>
    </div>
    
  )
}

export default KioskDeviceFaqPage