import React from 'react'
import AppPieChart from './AppPieChart';

function StaffPieChart({dashboardData}) {

    const dataLabels = [];
    const chartData = [];

    dashboardData.counterReport.forEach(counter => {
        dataLabels.push(counter.firstName+" "+counter.lastName+" / "+counter.workLocation);
        chartData.push(counter.count);
    });
    
    return (
      <AppPieChart dataLabels={dataLabels} chartData={chartData} />
    )
}

export default StaffPieChart
