import React from 'react'
import { Route, Routes } from 'react-router'

import SystemMasterListPage from '../app/master/pages/SystemMasterListPage'



// User 
import UserListPage from '../app/admin/user/pages/UserListPage'
import UserDetailsPage from '../app/admin/user/pages/UserDetailsPage'
import UserRoleListPage from '../app/admin/user/pages/UserRoleListPage'

//Business
import BusinessDetails from '../app/admin/business/pages/BusinessDetails'
import ManagerRoutes from './ManagerRoutes'

//CMS
import ManageAdsPage from '../app/admin/cms/pages/ManageAdsPage'
import ManageNewsPage from '../app/admin/cms/pages/ManageNewsPage'
import ManageKioskInfoPage from '../app/admin/cms/pages/ManageKioskInfoPage'
import KioskInfoDetailsPage from '../app/admin/cms/pages/KioskInfoDetailsPage'
import ManageKioskInfoCategoryPage from '../app/admin/cms/pages/ManageKioskInfoCategoryPage'
import KioskInfoCategoryDetailsPage from '../app/admin/cms/pages/KioskInfoCategoryDetailsPage'
import ManageKioskFaqPage from '../app/admin/cms/pages/ManageKioskFaqPage'
import ManageKioskDirectoryPage from '../app/admin/cms/pages/ManageKioskDirectoryPage'





function AdminRoutes() {
  return (
    <>
      <Routes>
        {/* Business Details */}
        <Route path="/business/details" element={<BusinessDetails />}></Route>
        {/* User */}
        <Route path="/user/list" element={<UserListPage /> }></Route>
        <Route path="/user/details/:id" element={<UserDetailsPage /> }></Route>
        <Route path="/user/role" element={<UserRoleListPage /> }></Route>

        {/* CMS */}
        <Route path="/cms/ads" element={<ManageAdsPage /> }></Route>
        <Route path="/cms/news" element={<ManageNewsPage /> }></Route>
        <Route path="/cms/kiosk-info" element={<ManageKioskInfoPage /> }></Route>
        <Route path="/cms/kiosk-info/details/:id" element={<KioskInfoDetailsPage /> }></Route>
        <Route path="/cms/kiosk-category" element={<ManageKioskInfoCategoryPage /> }></Route>
        <Route path="/cms/kiosk-category/details/:id" element={<KioskInfoCategoryDetailsPage /> }></Route>
        <Route path="/cms/kiosk-faq" element={<ManageKioskFaqPage /> }></Route>
        <Route path="/cms/kiosk-directory" element={<ManageKioskDirectoryPage /> }></Route>
        {/* Masters */}
        <Route path="/master/list" element={ <SystemMasterListPage /> } />
      </Routes>

      <ManagerRoutes/>
    </>
  )
}

export default AdminRoutes