import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import remarkDashboardServices from '../remarkDashboardServices'
import RemarkPieChart from '../components/RemarkPieChart'
import RemarkBarChart from '../components/RemarkBarChart'
import { Link } from 'react-router-dom'





function RemarkDashboardPage() {
    const [filter,setFilter] = useState(null);
    const [dashboardData,setDashboardData] = useState(null);
    const getDashBoardData = ()=>{
        if(filter!==null ){
            remarkDashboardServices.getRemarkDashBoardData(filter).then(res=>{
                setDashboardData(res.data);
            }).catch(error=>{
            })
        }
    }
    useEffect(()=>{
        getDashBoardData();
    },[filter])
    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6'>
                    </div>
                    <div className='col-md-6'>
                        <Link to={"/report/remark-report"} className='btn btn-primary float-end mx-3'>List View</Link>
                        <span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
                    </div>
                </div>
                {dashboardData && 
                <div className='row'>
                    <div className='col-md-9'>
                        <div className='row my-3'>
                            <div className='col-md-12'>
                                <h3>Enquiry Report</h3>
                            </div>
                            <div className='col-md-6' >
                                <RemarkBarChart remarkData={dashboardData.enquiryData} />
                            </div>
                            <div className='col-md-6'>
                                <RemarkPieChart remarkData={dashboardData.enquiryData} />
                            </div>
                            <div className='col-md-12'>
                                <h3>Response Report</h3>
                            </div>
                            <div className='col-md-6'>
                                <RemarkBarChart remarkData={dashboardData.responseData} />
                            </div>
                            <div className='col-md-6'>
                                <RemarkPieChart remarkData={dashboardData.responseData} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-2'>
                                <Link to={"/report/remark-report"} className="card border-primary text-primary shadow btn  p-1">
                                    <p>Total Enquiry</p>
                                    <span ><h1>{dashboardData.totalEnquery}</h1></span>
                                </Link>
                            </div>

                            
                            <div className='col-md-12 mt-3'>
                                <table className='table'>
                                    <thead>
                                        <th>Attendant Name</th>
                                        <th>Enquiry Count</th>
                                    </thead>
                                    <tbody>
                                        {dashboardData.remarkCountByUser.map(r=>
                                            <tr key={r.attendantUserId}>
                                                <td>{r.firstName}</td>
                                                <td>{r.count}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-12'>
                                <h3>Trending Enquery</h3>
                                {dashboardData.enquiryData.map((enquiry,i)=>
                                    i < 5 && <div className='bg-primary text-white rounded p-2 me-2 mb-2'>{enquiry.title}</div>
                                )}
                            </div>
                            
                        </div>
                    </div>
                </div>
                }
                    
            </div>
            
        </AppLayout>
    )
}

export default RemarkDashboardPage