import React, { useState } from 'react'
import Webcam from "react-webcam";
import { Dialog, DialogContent } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';


function CameraImage({uploadCameraImage}) {

    const [facingMode,setFacingMode] = useState("user")
    const swicthCamera = () =>{
        if(facingMode == "user"){
            setFacingMode("environment")
        }
        if(facingMode == "environment"){
            setFacingMode("user")
        }
    }

    const videoConstraints = {
        width: 800,
        height: 800,
        facingMode: facingMode,
    };

    
    const [dialogStatus,setDialogStatus] = useState(false);

    return (
    <>
        <button type="button" className="btn btn-dark btn-lg mt-2" onClick={()=>setDialogStatus(true)}><CameraAlt/> Open Camera</button>
        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullScreen={true}
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent style={{padding:0}}>
            <div className="row">
                <div className="col-md-12 text-center">
                    {dialogStatus == true &&
                        <Webcam
                            audio={false}
                            height={350}
                            screenshotFormat="image/jpeg"
                            width={350}
                            videoConstraints={videoConstraints}
                        >
                            {({ getScreenshot }) => (
                                <><br/>
                                <button
                                    className="btn btn-primary m-2"
                                    onClick={() => {
                                        let imageSrc = getScreenshot();
                                        uploadCameraImage(imageSrc);
                                        setDialogStatus(false);
                                    }}
                                
                                >
                                    Capture photo
                                </button>
                                <button type="button" onClick={swicthCamera} className="btn btn-dark m-2">Switch Camera</button>
                                <button type="button" onClick={()=>setDialogStatus(false)} className="btn btn-outline-primary m-2">Cancel</button>
                                </>
                            )}
                        </Webcam>
                    }
                </div>
            </div>
            </DialogContent>
        </Dialog>

        
    

    </>
  )
}

export default CameraImage
