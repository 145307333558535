import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


function AppSelect({options,value,setValue, label, optionLabel = "label"}) {
    
    const [inputValue,setInputValue] = useState();
    
    
    return (

        
        
        <Autocomplete
            disablePortal
            options={options}
            value={value==""||value===null?options[0]:value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            
            getOptionLabel={(option) => option[optionLabel]}
            inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label={label}  />}
        />
        
        
    )
}

export default AppSelect