import React, { useEffect, useState } from 'react'
import useBusinessInfo from '../../../../hooks/useBusinessInfo'
import { useNavigate } from 'react-router-dom';

function RechargePage() {

  const businessInfo = useBusinessInfo();
  const navigate = useNavigate();

  useEffect(()=>{
    if(businessInfo?.accountStatus){
      //navigate("/");
    }
  },[businessInfo])

  return (
    <div className='container'>
        <div className='row mt-5'>
          <div className='col-md-12 '>
            <div className='text-center'>
              
            </div>
          </div>
        </div>
    </div>
  )
}

export default RechargePage