import React,{useState,useEffect, useRef} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'

import DateSelect from '../../../../components/AppInput/DateSelect'
import adminReportServices from '../adminReportServices';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import userServices from '../../user/userServices';
import { Download } from '@mui/icons-material';


function TokenReportPage() {
  const [filter,setFilter] = useState(null);
  const [reportData,setReportData] = useState(null);
  const getReportData = ()=>{
      if(filter!==null){
          adminReportServices.getReportData(filter).then(res=>{
              setReportData(res.data);
          }).catch(error=>{
          })
      }
  }
  
  useEffect(()=>{
      getReportData();
  },[filter])


  /* Grid Work */

  const gridRef = useRef();
  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:120,
    filter:true,
    sortable: true,
    cellStyle: { 'textTransform': 'uppercase' },
  });

  const rowClassRules = {
    'bg-primary text-white' : params => params.data.status === 1,
    'bg-warning' : params => params.data.status === 2,
    'bg-info': params => params.data.status === 3,
    //'bg-success': params => params.data.status === 4,
    'bg-danger': params => params.data.status === 5,
    'bg-dark text-white': params => params.data.status === 6,  
  };


  const [columnDefs,setColumnDef] = useState();
  const prepairColumnDef = () => {
      let _columnDefs = [
          {field:"id",headerName: "ID"},
          {field:"checkInDate",headerName:"Date"},
          {field:"queueTitle",headerName:"Queue"},
          {field:"tokenNo",headerName:"Token No",valueGetter: params => params.data.queuePrefix+"-"+params.data.tokenNo},
          {field:"serviceTitle",headerName:"Service"},
          {field:"customerFirstName",headerName:"C.Name"},
          {field:"customerFirstName",headerName:"C.Phone",width:150,
            valueGetter: (params) => {
              if(params.data.customerPhoneStart == null ){
                return "XXXX"
              } else { 
                return params.data.customerPhoneStart+"XXXX"+params.data.customerPhoneEnd
              }
            }
          },
          {field:"statusTitle",headerName:"Status"},
          {field:"attendantUserId",headerName:"Counter Person",valueGetter: params => getUserName("users",params.data.attendantUserId)},
          {field:"attendantUserId",headerName:"Counter #",valueGetter: params => getWorkLocation("users",params.data.attendantUserId)},
      ];
      setColumnDef(_columnDefs);
  }

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv("data.csv");
  };


  


  /* Prepair User Master start*/
  const [masters,setMasters] = useState(null);
  const getUserDD = () => {
      userServices.getUserDDList().then(res=>{
          setMasters({"users":res.data})
      })
  }

  const getUserName = (type, id) => {
      let label = masters[type].find(m => m.value === id)
      if(label!== undefined){
          return label.firstName+" "+label.lastName;
      } else {
          return "NA";
      }
      
  }

  const getWorkLocation = (type, id) => {
      let label = masters[type].find(m => m.value === id)
      if(label!== undefined){
          return label.workLocation;
      } else {
          return "NA";
      }
  }

  useEffect(()=>{
      getUserDD();
  },[]);

  /* Prepair User Master end*/


  useEffect(()=>{
    if(masters) {
      prepairColumnDef()
    }
  },[masters])



  return (
    <AppLayout>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6'>
                    <h3>Token Report</h3>
                  </div>
                  <div className='col-md-6'>
                      <span className='float-end'>
                        <DateSelect filter={filter} setFilter={setFilter} />
                        {reportData && <button type="button" onClick={onBtnExport} className="btn btn-primary ms-3 float-end"><Download/>  Export</button> }
                      </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-3'>
                    
                    <div className="ag-theme-alpine" style={{height:600}}>
                        <AgGridReact
                          ref={gridRef}
                          rowData={reportData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          rowClassRules={rowClassRules}
                          animateRows={true}
                          onGridReady={getReportData}
                        >
                        </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
    </AppLayout>
  )
}

export default TokenReportPage