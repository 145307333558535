import React, { useState,useEffect,useRef } from 'react'
import withCMSLayout from '../sections/withCMSLayout'
import { useAlert } from '../../../../context/AlertContext'



import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';
import kioskInfoCategoryServices from '../kioskInfoCategoryServices';
import KioskInfoCategoryForm from '../components/KioskInfoCategoryForm';
import { Switch } from '@mui/material';
import withModuleSetting from '../../../../section/withModuleSetting';


const CellLink = (params) =>{
  return (
      <Link to={`/cms/kiosk-category/details/${params.data.id}`}>{params.data.title}</Link>
  )
}

const CellSwitch = (params) =>{
  return (
    <Switch 
      color="success"
      checked={params.data.status === 1}
      onChange={(e)=>params.handleStatusChange(e,params.data.id)}
    />
  )
}

function ManageKioskInfoCategoryPage() {

  const {AppAlert} = useAlert();
  const gridRef = useRef();
  const [userList,setUserList] = useState(null);

  const [defaultColDef] = useState({
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
    resizable: true,
    width:120,
    filter:true,
    sortable: true,
    cellStyle: { 'textTransform': 'uppercase' },
  });


  const [columnDefs,setColumnDef] = useState();
  const prepairColumnDef = () => {
      let _columnDefs = [
          {field:"id",headerName: "ID"},
          {headerName:"Title", width:"200", valueGetter: params => params.data.title,cellRenderer: CellLink},
          {headerName:"Status", width:"200", cellRenderer: CellSwitch, cellRendererParams: {
            handleStatusChange:handleStatusChange
          }},
      ];
      setColumnDef(_columnDefs);
  }

  const getKioskInfoCategoryList = () =>{
    kioskInfoCategoryServices.getList().then(res=>{
        setUserList(res.data);
    }).catch(error=>{
        AppAlert(error.msg,"error")
    })
  }

  useEffect(()=>{
    prepairColumnDef();
  },[])



  const handleStatusChange = (e,id) =>{
    let payload = {
      id:id,
      status:e.target.checked ? 1 : 0,
    }
    console.log(payload);
    kioskInfoCategoryServices.save(payload).then(res=>{
      getKioskInfoCategoryList();
    })
  }
  
  return (
    <div className='row'>
        <div className='col-md-9'>
          <h3>Kiosk Info Categories</h3>
        </div>
        <div className='col-md-3'>
          <span className='float-end'><KioskInfoCategoryForm refreshList={getKioskInfoCategoryList} /></span>
        </div>
        <div className='col-md-12 mt-3'>
            <div className="ag-theme-alpine" style={{height: 400}}>
                <AgGridReact
                  ref={gridRef}
                  rowData={userList}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  onGridReady={getKioskInfoCategoryList}
                >
                </AgGridReact>
            </div>
        </div>
    </div>
  )
}

export default withCMSLayout( withModuleSetting( ManageKioskInfoCategoryPage,"kioskInfoModuleEnabled"))