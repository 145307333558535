import React,{useState,useEffect} from 'react'

import { Link } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';
import kioskInfoServices from '../kioskInfoServices';

function CategoryListSideNav() {
    const [categoryList,setCategoryList] = useState(null);
    const getCategoryList = () =>{
        kioskInfoServices.getCategoryList().then(res=>{
        setCategoryList(res.data);
        }).catch(error=>{

        })
    }
    useEffect(()=>{
        getCategoryList();
    },[])

    return (
        <div className='row p-3'>
            {categoryList?.map(category=>
                <div key={category.id} className='col-md-12 rounded py-2 mb-2  shadow'>
                  <Link
                    key={category.id} 
                    
                    aria-current="page" 
                    to={"/kiosk/info/category/"+category.id} 
                    style={{textDecoration:"none"}}
                    >
                      <div className="d-flex  justify-content-between text-dark">
                        <h5 className='align-self-center'>{category.title}</h5>
                        <ArrowRight sx={{fontSize:40}}/>
                      </div>
                  </Link>
                </div>
              )}
        </div>
    )
}

export default CategoryListSideNav